import React from 'react';

const Loader = () => {
    return (
        <div className='overflow-hidden text-center mx-auto'>
            <img src="/assets/loaderAnimation.gif" alt="" className='img-fluid' />
        </div>
    );
};

export default Loader;