import React from "react";
import {
    useNavigate
} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './Referral.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab';
import { useContextData } from "../../../core/context";
import Referral from "./Referral";

const ReferralWrap = () => {
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    return (
        <section >
            <div className='py-5'>
                <div>
                    <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Referral Details</strong></h4>
                </div>
                    <div class="card-body pb-2 pt-1">
                        <Tabs defaultActiveKey="active" id="uncontrolled-tab-example" className="mb-3 mt-1 custom-active-tab" >
                            <Tab eventKey="active" title="Referral Details" >
                                <Referral
                                    type="user"
                                    myProfile={myProfile}
                                />
                            </Tab>
                            {
                                myProfile && myProfile.userType == "promoted" &&
                                <Tab eventKey="referredfriend" title="Promotion Referral Details">
                                    <Referral
                                        type="promoter"
                                        myProfile={myProfile}
                                    />
                                </Tab>
                            }
                        </Tabs>
                    </div>
            </div>
        </section >
    );
};

export default ReferralWrap;