const {
    REACT_APP_API_URL = "https://api.uniticexchange.com/api/",
    REACT_APP_FRONTEND_URL = "https://uniticexchange.com/",
    REACT_APP_BACKEND_URL = "https://api.uniticexchange.com/",
    REACT_APP_SOCKET_URL = "https://api.uniticexchange.com/",

    // REACT_APP_API_URL = "http://localhost:3004/api/",
    // REACT_APP_FRONTEND_URL = "http://localhost:3000/",
    // REACT_APP_BACKEND_URL = "http://localhost:3004/",
    // REACT_APP_SOCKET_URL = "http://localhost:3004/",

    REACT_APP_FRONEND_URL_PRINT = "Unitic"
} = process.env;

const KEY = {
    V1_API_URL: REACT_APP_API_URL + "v1/",
    V2_API_URL: REACT_APP_API_URL + "v2/",
    BACKEND_URL: REACT_APP_BACKEND_URL,
    SOCKET_URL: REACT_APP_SOCKET_URL,
    FRONEND_URL: REACT_APP_FRONTEND_URL,
    FRONEND_URL_PRINT: REACT_APP_FRONEND_URL_PRINT,

    SITENAME: "Unitic Exchange",
    TFA: {
        downLoadLink: {
            googleApp: "https://apps.apple.com/us/app/google-authenticator/id388497605",
            playstoreApp: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US&pli=1"
        }
    },
    timer: {
        resendOtp: 60
    }
};

export default KEY;