import React, { Suspense } from 'react';
import '../../../assets/style.css';
import LoaderUnitic from '../../../Component/Loader/LoaderUnitic';
const TVChartContainer = React.lazy(() => import("./TVChartContainer"));

const TradingView = (props) => {
  return (
    <Suspense fallback={<LoaderUnitic />}>
      <TVChartContainer {...props} />
    </Suspense>

  );
}
export default TradingView;