import React, { useEffect, useState } from "react";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config/";
import { dateFormat } from '../../core/helper/date-format';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import $ from "jquery";
import Loader from "../../Component/Loader/Loader";
const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
  fontSize: "22px",
  fontWeight: "500",
  fontStyle: "normal",
  padding: "5px",
  paddingBottom: "16px",
  width: "auto !important",
  color: "white",
  textAlign: 'center',
  backgroundColor: 'transparent'
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "27px",
  padding: "5px",
  width: "auto !important",
  color: "#C8C8C8",
  textAlign: 'center',
  backgroundColor: 'transparent'
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderBottom: 'none',
  fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
  fontSize: "32px",
  fontWeight: "600",
  padding: "5px",
  fontStyle: "normal",
  lineHeight: "38px",
  width: "auto !important",
  color: "white",
  textAlign: 'center',
  backgroundColor: 'transparent'
}));
export default function TransactionhistoryFiat(props) {
  const [loading, setLoading] = useState(false);
  const [transactionList, settransactionList] = useState([]);
  const [filter, setFilter] = useState({
    tab: 'transfer',
    type: 'Wallet Transfer'
  });

  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      // const getStatusText = $(this).text();
      // $(this)
      //   .closest(".status_dropdown")
      //   .find(".status__btn")
      //   .text(getStatusText);
      const generateStatusClass = `${$(this).attr("data-class")}-status`;
      $(this)
        .closest(".status_dropdown")
        .attr("data-color", `${generateStatusClass}`);
    });
  }, []);

  useEffect(() => {
    getHistory();
  }, [filter]);

  async function filterChange(data) {
    const {
      target = "",
      value = ""
    } = data;
    let filterCopy = Object.assign({}, filter);
    if (target && value) {
      if (target == 'type') {
        filterCopy.type = value;
        setFilter(filterCopy);
      }
    }
  }

  async function getHistory() {
    setLoading(true);
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistoryWithFilter`,
        method: 'POST',
        data: { filter }
      }
      const response = (await makeRequest(params));
      if (response.status) {
        settransactionList(response.data)
      }
    } catch (err) { }
    setLoading(false);
  }

  return (<>
    <div className="container pb-5">
      <div className="row align-items-center">
        <div className="col-lg-12">
          {loading ? <Loader /> :
            <TableContainer className="mt-4">
              <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                <StyledTableHead>
                  <TableRow>
                    <StyledTableHeadRow>Date</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Coin</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Amount</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">From</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">To</StyledTableHeadRow>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {(transactionList && transactionList.length > 0) ? transactionList.map((item, i) => {
                    const txnId = item.txnId;
                    const txnIdArr = txnId ? txnId.split(' To ') : [];
                    const from = txnIdArr && txnIdArr[0] ? txnIdArr[0] : '';
                    const to = txnIdArr && txnIdArr[1] ? txnIdArr[1] : '';
                    return <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {dateFormat(item.createdDate)}
                      </StyledTableCell>
                      <StyledTableCell align="right">{item.type}</StyledTableCell>
                      <StyledTableCell align="right">{item.currencyDet && item.currencyDet.currencySymbol}</StyledTableCell>
                      <StyledTableCell align="right">{from}</StyledTableCell>
                      <StyledTableCell align="right">{to}</StyledTableCell>
                    </TableRow>


                  }) : <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell><img
                      className="no-record-image"
                      src="/assets/no-re.png"
                      alt="no-record"
                    />
                      <br />
                      <span className="text-center">No Records Found</span></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>

                  </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      </div>
    </div>
  </>
  );
}