import React from "react";
import { AiOutlineSend } from "react-icons/ai"
import { dateFormat, showEmail } from '../core/helper/date-format';
export default function ChatBox(props) {
  const {
    chattingHistory,
    p2pOrdersList,
    myProfile,
    myorderList,
    chattingMsg,
    handleChatChange,
    handleKeyPress,
    handleFileUpload,
    submitMessage,
    enableChat,
    setreportformOpen,
    selectFiles,
    setchatFile,
    setchangefile,
    changefile
  } = props;
  return (
    <div className="col-lg-4 card-bg-css">
      <div class="card">
        <div class="card-body p-0 card-bg-css">
          <div class="card ">
            <div class="card-body ">
              <div className="d-flex ">
                <div className="my-auto">
                  <span className="circles">
                    {
                      (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].userId) == myProfile?._id ?
                        ((p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].ownerName != "") ?
                          (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].ownerName?.charAt(0))
                          : (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].ownerEmail?.charAt(0)))
                        : (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].username?.charAt(0))
                    }
                  </span>
                </div>
                <div className="ms-2">
                  <span>
                    {(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].userId) == myProfile?._id ?
                      ((p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].ownerName != "") ?
                        (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].ownerName)
                        :
                        showEmail(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].ownerEmail))
                      :
                      (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].username)
                    }
                  </span>
                  <span className="d-flex">
                    <b>30d Trades</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>30d Completion Rate</b>
                  </span>
                  <span className="d-flex">
                    <b>{(myorderList?.monthtradeCounts)}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <b>{(myorderList?.completionRate != null) ? (myorderList?.completionRate?.toFixed(2)) : 0} % </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="chart-box">
            <div className="row">
              {chattingHistory.length > 0 ?
                chattingHistory.map((data) => {
                  return (
                    <>
                      <div className="col-12">
                        {data.message && <p className={data.userId == myProfile?._id ? "chart-resived float-end" : "chart-send"}>{data.message}</p>}
                        {data?.chattingImage && <img src={data?.chattingImage} className="w-50 h-50" />}
                      </div>
                    </>
                  )
                })
                :
                <>
                  {(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status) == 3 ?
                    <div className="col-12">
                      <div className="d-flex justify-content-center">
                        <p className="chart-resived float-center">{dateFormat(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].createdDate)}</p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <p className="chart-resived float-start">Successfully placed an order, please pay within the time limit</p>
                      </div>
                    </div>
                    :
                    (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status) == 2 ?
                      <div className="col-12">
                        <div className="d-flex justify-content-center">
                          <p className="chart-resived float-center">{dateFormat(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].createdDate)}</p>
                        </div>
                        <div className="d-flex justify-content-center">
                          <p className="chart-resived float-start">The order has been cancelled. Please contact customer support if you have any questions.</p>
                        </div>
                      </div>
                      :
                      (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status) == 1 ?
                        <div className="col-12">
                          <div className="d-flex justify-content-center">
                            <p className="chart-resived text-center">{dateFormat(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].createdDate)}</p>
                          </div>
                          <div className="d-flex justify-content-center">
                            <p className="chart-resived float-start">The order has been completed.</p>
                          </div>
                        </div>
                        :
                        <></>
                  }

                </>
              }
            </div>
          </div>

          <div class="input-group">
            <input
              type="text"
              class="form-control input-bg-css input-resize"
              aria-label="Dollar amount (with dot and two decimal places)"
              name="chatting"
              id="chatting"
              autoComplete='off'
              value={chattingMsg}
              onChange={handleChatChange}
              onKeyPress={handleKeyPress}
            />
            {/* {changefile && <img src={changefile} className="payment-qrcode-optional-image" alt="preview" /> } */}
            <span class="input-group-text input-bg-css" onClick={() =>
              selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
                const url = URL.createObjectURL(file);
                // console.log("url:",url)
                setchangefile(url);
                setchatFile(file);
              })}
            > + </span>
            <span class="input-group-text input-bg-css" onClick={() => submitMessage('')}><AiOutlineSend disabled={true} /></span>
          </div>
        </div>
      </div>
    </div>
  )
}