// import { MdDone, MdAccessTime, MdDangerous } from "react-icons/md";
import { VscVerified, VscUnverified, VscWarning, VscWatch } from "react-icons/vsc";

export default function KycStatusComp(props) {
    const {
        status = ""
    } = props;
    // console.log('KycStatusComp-----', props)
    return (
        status === 0
            ?
            <small className="text-warning"><VscWatch /> Waiting for approval </small>
            :
            status === 1
                ?
                <small style={{ color: 'rgb(16, 237, 182)' }}><VscVerified /> Verified</small>
                :
                status === 2
                    ?
                    <small className="text-danger"><VscWarning /> Rejected</small>
                    :
                    status === 3
                        ?
                        <small className="text-danger"><VscUnverified /> Not Verified</small>
                        :
                        <></>
    )
};