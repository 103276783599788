import React from 'react';
import './TradeHistory.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { dateFormat, showPhone, showPairName } from '../../../core/helper/date-format';
import { Link } from 'react-router-dom';
import Loader from '../../../Component/Loader/Loader';
import { useContextData } from '../../../core/context';

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "500",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },
    // lineHeight: "27px"

}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'

    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));

const OnSpot = (props) => {
    const { coindcxDecimal } = useContextData();
    return (
        <div className='mb-3'>
            {props?.loading ? <Loader /> :
                <TableContainer>
                    <Table sx={{ minWidth: window.innerWidth < 700 ? 1025 : 100, justifyContent: 'center' }} aria-label="simple table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableHeadRow>Date</StyledTableHeadRow>
                                <StyledTableHeadRow>Pair</StyledTableHeadRow>
                                <StyledTableHeadRow>Side</StyledTableHeadRow>
                                <StyledTableHeadRow>Price</StyledTableHeadRow>
                                <StyledTableHeadRow>Filled</StyledTableHeadRow>
                                <StyledTableHeadRow>Total</StyledTableHeadRow>
                                <StyledTableHeadRow>Fees</StyledTableHeadRow>
                                <StyledTableHeadRow>Role</StyledTableHeadRow>
                                <StyledTableHeadRow>Status</StyledTableHeadRow>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                                props.traHisData && props.traHisData.length > 0 &&
                                props.traHisData.map((elem, i) => {
                                    const {
                                        _id: tradeId = "",
                                        MappingOrders = {},
                                        orderId = "",
                                        type: side = "",
                                    } = elem;
                                    const {
                                        pairName = "",
                                        pair = {},
                                        Profit = [],
                                        dateTime = "",
                                        orderType = "",
                                        tradePrice = 0,
                                        filledAmount = 0,
                                        role = "",
                                        status = "Filled",
                                    } = MappingOrders;
                                    const clrClassName = side == "buy" ? "color-green" : "color-red";

                                    const { amountDecimal = 2, priceDecimal = 2 } = pair;

                                    const { currency: profitCurrency = {}, totalFees = 0 } = Profit[0] ? Profit[0] : {};

                                    const {
                                        currencySymbol: profit_currencySymbol = "",
                                        siteDecimal: profit_siteDecimal = 2,
                                    } = profitCurrency;

                                    let userFeeReduced = (Profit && Profit[0]) ? Profit[0].userFeeReduced : "";
                                    const feesDecimal =
                                        userFeeReduced !== "tradeFeeVoucher" &&
                                            userFeeReduced !== "fanToken"
                                            ? profit_siteDecimal
                                            : side == "buy"
                                                ? amountDecimal
                                                : priceDecimal;

                                    if (userFeeReduced == "tradeFeeVoucher") {
                                        userFeeReduced = "Voucher";
                                    } else if (userFeeReduced == "fanToken") {
                                        userFeeReduced = "BKC";
                                    } else if (userFeeReduced == "respective") {
                                        userFeeReduced = "Respective";
                                    }
                                    const decimal = coindcxDecimal[pairName.replace("_", "")]
                                    const baseDecimal = decimal?.base_currency_precision ? decimal?.base_currency_precision : 2
                                    const targetDecimal = decimal?.target_currency_precision ? decimal?.target_currency_precision : 3
                                    return (
                                        <TableRow key={i}>
                                            <StyledTableCell component="th" scope="row">{dateFormat(dateTime)}</StyledTableCell>
                                            <StyledTableCell>{pairName ? (
                                                <Link className='text-white' to={"/spot/" + pairName}>
                                                    {pairName.replace("_", "/")}
                                                </Link>
                                            ) : (
                                                ""
                                            )}</StyledTableCell>
                                            <StyledTableCell className={"capitalizeText " + clrClassName}>{side}</StyledTableCell>
                                            <StyledTableCell>{props.decimalValue(tradePrice, baseDecimal)}</StyledTableCell>
                                            <StyledTableCell>{filledAmount.toFixed(amountDecimal)}</StyledTableCell>
                                            <StyledTableCell>{props.decimalValue((filledAmount * tradePrice), priceDecimal)}</StyledTableCell>
                                            <StyledTableCell> {props.decimalValue(totalFees, feesDecimal)} {profit_currencySymbol}</StyledTableCell>
                                            <StyledTableCell className='capitalizeText'>{role}</StyledTableCell>
                                            <StyledTableCell sx={{ color: '#10EDB6', cursor: "pointer" }} className='capitalizeText' data-bs-toggle="modal"
                                                data-bs-target="#exampleModal" onClick={() => {
                                                    // console.log("click");
                                                    props.getOrderDetail_call(tradeId);
                                                }}>{status}</StyledTableCell>
                                        </TableRow>
                                    );
                                })
                            }

                            {props.traHisData && props.traHisData.length === 0 && (
                                <TableRow colSpan={8}>
                                    <StyledTableCell >   No Active Orders Found!</StyledTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    );
};

export default OnSpot;