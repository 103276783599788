import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { toast, toastDismiss } from "../core/lib/toastAlert";
import Config from "../core/config";
import { makeRequest } from "../core/services/v1/request";
import { useContextData } from "../core/context/index";
import { pageAllowCheck } from "../core/helper/common";

import {
  MenuItem,
  Select,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import PhonePopUp from "../PagesV2/Dashboard/Authenticator/PhonePopUp";

export default function BankPayment(props) {
  const navigate = useNavigate();
  const [openPhn, setOpenPhn] = React.useState(false);
  const { myProfile, setUserProfile } = useContextData();
  const [updatedStatus, setupdatedStatus] = useState(false);
  const [addbankformOpen, setaddbankformOpen] = useState(false);
  const [updatebankfromOpen, setupdatebankfromOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [enableAddStatus, setenableAddStatus] = useState(false);
  const [hideAddStatus, setHideAddStatus] = useState(false);
  const [bankpaymentsList, setbankpaymentsList] = useState([]);

  const validationSchema = yup.object({
    accountname: yup
      .string("Please enter the ifsc code")
      .required('Account holder name is required'),
    // accountType: yup
    // .string('Please enter the account type'),
    //   .required('Account type is required'),
    bankAccount: yup
      .number("Please enter the bank account number")
      .required("Account number is required"),
    // bankName: yup
    //   .string()
    //   .matches(/[A-Z]/, "Please enter alphabets only."),
    confirmbankAccount: yup
      .number("Please enter the confirm bank account number")
      .required("Confirm Account number is required"),
    ifscCode: yup
      .string("Please enter the ifsc code")
      .matches(/^[A-Za-z0-9]*$/, "Please enter alphanumeric only")
      .required("IFSC code is required"),
    bankName: yup
      .string("Please enter the Bank name")
      .required("Bank Name is required"),
  });
  useEffect(() => {
    getPayment();
  }, []);
  const initialValues = () => {
    return {
      accountname: myProfile?.bankdetails?.["Account Name"],
      // accountName: myProfile?.bankdetails?.['Beneficiary Name'],
      // accountType: myProfile?.bankdetails?.['Account Type'],
      bankAccount: myProfile?.bankdetails?.["Account Number"],
      confirmbankAccount: myProfile?.bankdetails?.["Confirm Account Number"],
      // bankName: myProfile?.bankdetails?.['Bank Name'],
      ifscCode: myProfile?.bankdetails?.["IFSC Code"],
    };
  };
  async function getPayment() {
    try {
      const params = {
        url: `${Config.V2_API_URL}customer/getBankPayments`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response && response.status && response.data) {
        if (response.data.length > 0) {
          let newEnableAddStatus = true;
          let count = 0;

          response.data.map((data, i) => {
            if (data.status == 0 || data.status == 1 || data.status == 5) {
              count++;
            }
            if (data.status == 0) {
              newEnableAddStatus = false;
            } else if (newEnableAddStatus !== false) {
              newEnableAddStatus = true;
            }
          });
          if (count >= 2) {
            setHideAddStatus(() => true);
          }
          setenableAddStatus(newEnableAddStatus);
          setbankpaymentsList(response.data);
        } else {
          setenableAddStatus(true);
        }
      } else {
        setenableAddStatus(true);
      }
    } catch (err) { }
  }
  const onSubmit = async (values) => {
    // console.log("values--", values);
    setisLoading(true);
    setupdatedStatus(true);
    const data = {
      accountname: values.accountname,
      // accountType: values.accountType,
      accountNumber: values.bankAccount,
      confirmaccountNumber: values.confirmbankAccount,
      // bankName: values.bankName,
      ifscCode: values.ifscCode,
    };

    const bankDetail = [
      {
        name: 'Beneficiary Name',
        type: 'accountname'
      },
      {
        name: 'Confirm Account Number',
        type: 'confirmaccountNumber'
      },
      {
        name: "Account Number",
        type: "accountNumber",
      },
      {
        name: "IFSC Code",
        type: "ifscCode",
      },
      // {
      //   name: 'Account Type',
      //   type: 'accountType'
      // }
    ];
    let bankdetailss = typeof data == "object" ? data : {};

    let objValue = {};
    bankDetail.forEach((detail) => {
      if (
        typeof bankdetailss[detail.type] == "string" ||
        typeof bankdetailss[detail.type] == "number"
      ) {
        objValue[detail.name] = bankdetailss[detail.type];
        // console.log(objValue, "Objvalue");
      }
    });

    const params = {
      url: `${Config.V1_API_URL}user/updateMyBank`,
      method: "POST",
      body: { bankdetails: objValue },
    };
    // console.log(params);
    const response = await makeRequest(params);
    setisLoading(false);
    let type = "error";
    if (response.status) {
      type = "success";
      setUserProfile();
      getPayment();
      setupdatedStatus(false);
      setaddbankformOpen(false);
      navigate = "/my/payment";
    }
    toast({ type, message: response.message });
    navigate = "/my/payment";
  };

  async function enableDisableStatus(data) {
    try {
      const params = {
        url: `${Config.V1_API_URL}p2p/enableDisablP2PPayment`,
        method: "POST",
        body: data,
      };
      const result = await makeRequest(params);
      let type = "error";
      if (result.status) {
        type = "success";
        setTimeout(() => {
          getPayment();
          setUserProfile();
        }, 300);
      }
      toast({ type, message: result.message });
    } catch (err) {
      console.log("err:", err);
    }
  }

  async function selectpaymentType(methods) {
    if (methods.name == 'UPI') {
      toast({ type: "error", message: "This facility is currently un-available." });
      return false;
    }
    if (myProfile) {
      if (methods.name == 'Bank') {
        const kycUserType = myProfile.country == "IND" ? myProfile.country : "International";
        // if (kycUserType == "International") {
        //   toast({ type: "error", message: "This facility is currently unavailable for your country." });
        //   return false;
        // }
      }
      const pageAllowCheckResp = pageAllowCheck(myProfile, "final");
      if (pageAllowCheckResp.type == "error") {
        toastDismiss();
        toast({ type: pageAllowCheckResp.type, message: pageAllowCheckResp.message });
        // navigate(pageAllowCheckResp.navigate);
        setOpenPhn(() => true);
      }
      else {
        // setaddbankformOpen(true);
        navigate("/my/add-payment-method");
      }
    }
  }

  return (
    <div className="bg-default min-vh-100 text-white bg-particular">
      <div className=" px-0 mx-0">
        <div className="row">

          <div className=" py-5 ">
            <div id="scrollspy-section-1-para" className="row px-4 ">
              <div id="section-paymentmethods-scroll" className="col py-3">
                <div className="row p2p-user-center-scrollspy-tops-heading ">
                  <p className="password-text-66">Payment Methods</p>
                </div>

                <div className="row justify-content-between">
                  <div className="col-lg-6">
                    <p className="scrollspy-tops-heading-paragraph">
                      When you withdraw your fiat, the added payment method will
                      be shown to the buyer during the transaction. To accept
                      cash transfer, please make sure the information is
                      correct.
                    </p>
                  </div>
                  <div className="col-lg-6 text-lg-end">
                    <div className="dropdown">
                      <button
                        className="btn-next my-2 btn-unset fw-bold btn-disabled-color"
                        type="button"
                        style={{ display: hideAddStatus ? 'none' : '' }}
                        onClick={() => {
                          if (enableAddStatus &&
                            myProfile &&
                            myProfile.bankSuspend == "active") {
                            selectpaymentType({ name: "Bank" });
                          }

                        }}
                        disabled={!enableAddStatus ||
                          !myProfile ||
                          myProfile.bankSuspend != "active"}
                        aria-expanded="false"
                      >
                        &nbsp;
                        {bankpaymentsList.length > 0
                          ? "Add Bank"
                          : "Add Bank"}
                        &nbsp;
                      </button>

                    </div>
                  </div>
                </div>

                {bankpaymentsList && bankpaymentsList.length > 0 ? (
                  bankpaymentsList.map((item, i) => {
                    return (
                      <>
                        {item.status == 0 && (
                          <>
                            <div className="row border mt-3">
                              <div className="row align-items-center py-2 m-0 px-2 scrollspy-payments-row-bg-styling bg-container-color bg-container-color">
                                <div className="col d-flex">
                                  <p className="border-line-styling mb-0 mt-1"></p>
                                  <p className="mb-0 p2p-user-center-scrollspy-tops-heading color-blue">
                                    Approval Pending...!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {(item.status == 1 ||
                          item.status == 2 ||
                          item.status == 5) && (
                            <>
                              {(item.paymenttype == "IMPS" ||
                                item.paymenttype == "Bank") && (
                                  <div className="row border mt-3">
                                    <div className="row align-items-center py-2 m-0 px-2 scrollspy-payments-row-bg-styling bg-container-color g-4">
                                      <div className="col-lg-6 d-flex">
                                        <p className="border-line-styling mb-0 mt-1"></p>
                                        <p className="mb-0 p2p-user-center-scrollspy-tops-heading">
                                          {item.paymenttype}
                                        </p>
                                      </div>
                                      <div className="col-lg-6 text-lg-end">
                                        <span
                                          className={
                                            item.status == 0
                                              ? "scrollspy-tops-heading-paragraph color-yellow"
                                              : item.status == 1
                                                ? "color-green"
                                                : item.status == 2
                                                  ? "color-darkpink"
                                                  : "color-darkpink"
                                          }
                                        >
                                          {item.status == 0
                                            ? "Waiting for Approval"
                                            : item.status == 1
                                              ? "Verified"
                                              : item.status == 2
                                                ? "Rejected"
                                                : item.status == 5
                                                  ? "Disabled"
                                                  : ""}
                                        </span>
                                        &nbsp;&nbsp;
                                        {item.status == 1 && (
                                          <button
                                            className="btn btn-Dmode fw-bold  font-mobile btn-next  btn-unset"
                                            onClick={() =>
                                              enableDisableStatus(item)
                                            }
                                          >
                                            {" "}
                                            Disabled
                                          </button>
                                        )}
                                        {item.status == 5 && (
                                          <button
                                            className="btn btn-Dmode fw-bold  font-mobile btn-next  btn-unset"
                                            onClick={() =>
                                              enableDisableStatus(item)
                                            }
                                          >
                                            {" "}
                                            Enabled
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="row pt-3 px-4 mb-0">
                                      <div className="col-lg-4  ">
                                        <span className="text-light scrollspy-tops-heading-paragraph">
                                          Name
                                        </span>
                                        <p className="mt-2 scrollspy-tops-heading-paragraph">
                                          {item.holderName}
                                        </p>
                                      </div>
                                      <div className="col-lg-4  ">
                                        <span className="text-light scrollspy-tops-heading-paragraph">
                                          Account No
                                        </span>
                                        <p className="mt-2 scrollspy-tops-heading-paragraph">
                                          {item.accountNo}
                                        </p>
                                      </div>
                                      <div className="col-lg-4  ">
                                        <span className="text-light scrollspy-tops-heading-paragraph">
                                          IFSC Code
                                        </span>
                                        <p className="mt-2 scrollspy-tops-heading-paragraph">
                                          {item.ifscCode}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row pt-3 px-4 mb-0">
                                    </div>
                                  </div>
                                )}
                            </>
                          )}
                      </>
                    );
                  })
                ) : (
                  <div className="text-center color-white f-14 mt-2  mb-2">
                    You have not added any payment methods
                  </div>
                )}

                {myProfile?.bankSuspend == "suspend" &&
                  myProfile?.bankSuspendReason != "" && (
                    <div className="row">
                      <div className="col-lg-12  mt-5 mt-5 mt-5">
                        <button className="btn btn-Dmode  font-mobile ">
                          {myProfile?.bankSuspendReason}
                        </button>
                      </div>
                    </div>
                  )}
                {myProfile?.bankSuspend == "suspend" &&
                  myProfile?.bankSuspendReason == "" && (
                    <div className="row">
                      <div className="col-lg-12 mt-5 mt-5 mt-5">
                        <span className="color-red">
                          You cant upload the Bank Details from User panel. To
                          upload Document again, please Contact Support
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PhonePopUp openPhn={openPhn} setOpenPhn={setOpenPhn} />
      <Modal
        show={updatebankfromOpen}
        onHide={() => setupdatebankfromOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!(myProfile && myProfile.bankdetails) ? "Add" : " Update"} a bank
            account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {myProfile && (
            <Formik
              initialValues={initialValues()}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = formikProps;
                return (
                  <>
                    <div className="mb-3">
                      <label className="form-label">Account No</label>
                      <input
                        type="text"
                        className="form-control"
                        value={values.bankAccount}
                        id="bankAccount"
                        name="bankAccount"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.bankAccount && Boolean(errors.bankAccount)
                        }
                        helperText={touched.bankAccount && errors.bankAccount}
                      />
                      {errors.bankAccount ? (
                        <small className="invalid-bankAccount error">
                          {errors.bankAccount}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Bank Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={values.bankName}
                        id="bankName"
                        name="bankName"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.bankName && Boolean(errors.bankName)}
                        helperText={touched.bankName && errors.bankName}
                      />
                      {errors.bankName ? (
                        <small className="invalid-bankName error">
                          {errors.bankName}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">IFSC code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={values.ifscCode}
                        id="ifscCode"
                        name="ifscCode"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ifscCode && Boolean(errors.ifscCode)}
                        helperText={touched.ifscCode && errors.ifscCode}
                      />
                      {errors.ifscCode ? (
                        <small className="invalid-ifscCode error">
                          {errors.ifscCode}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Account Type</label>
                      <Select
                        fullWidth
                        id="accountType"
                        name="accountType"
                        className="color-white f-17"
                        label="Select account type"
                        value={values.accountType}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem
                          value={
                            values.accountType == "Savings"
                              ? values.accountType
                              : "Savings"
                          }
                        >
                          {values.accountType == "Savings"
                            ? values.accountType
                            : "Savings"}
                        </MenuItem>
                        <MenuItem
                          value={
                            values.accountType == "Current"
                              ? values.accountType
                              : "Current"
                          }
                        >
                          {values.accountType == "Current"
                            ? values.accountType
                            : "Current"}
                        </MenuItem>
                      </Select>

                      {errors.accountType ? (
                        <small className="invalid-accountType error">
                          {errors.accountType}
                        </small>
                      ) : null}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setaddbankformOpen(false)}
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={isLoading}
                        onClick={() => handleSubmit(values)}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                );
              }}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </div >
  );
}