import React, { useState, useEffect } from 'react'
import './GoogleAuthenticator.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Badge from 'react-bootstrap/Badge';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OTPInput from 'react-otp-input';
import { toast } from "../../../core/lib/toastAlert";
import QRCode from 'qrcode';
import { useContextData } from '../../../core/context/index'
import Config from "../../../core/config/";
import { makeRequest } from "../../../core/services/v1/request";
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from '../../../core/helper/cookie';
import NavbarOne from '../../../Pages/separate/NavbarOne';


const GoogleAuthenticator = (props) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { myProfile, setUserProfile, siteSettings } = useContextData();
    const [isLoading, setisLoading] = useState(false);
    const [TFAQRCode, setTFAQRCode] = useState("");

    useEffect(() => {
        getTFADetails();
    }, [myProfile, siteSettings])

    async function getTFADetails() {
        if (myProfile) {
            let displayName = "";

            if (myProfile.username) {
                displayName = myProfile.username;
            }
            if (myProfile.email) {
                displayName = displayName ? `${displayName} (${myProfile.email})` : myProfile.email;
            } else if (myProfile.phoneno) {
                displayName = displayName ? `${displayName} (${myProfile.phoneno})` : myProfile.phoneno;
            }
            displayName = `${displayName} - ${siteSettings?.siteName}`;

            if (myProfile.email && myProfile.secretKey) {
                const otpAuthUrl = `otpauth://totp/${encodeURIComponent('Unitic-' + myProfile.email)}?secret=${myProfile.secretKey}&issuer=${encodeURIComponent(siteSettings?.siteName)}`;

                try {
                    const qrCodeUrl = await QRCode.toDataURL(otpAuthUrl);
                    setTFAQRCode(qrCodeUrl);
                } catch (err) {
                    console.error('Error generating QR code:', err);
                }
            } else {
                console.error("Missing email or secretKey in myProfile");
            }
        }
    }

    const handleSubmit2FA = async () => {
        try {
            setisLoading(true)
            const data = {
                secret: myProfile?.secretKey,
                code: otp
            }
            const params = {
                url: `${Config.V1_API_URL}user/updateTFA`,
                method: 'POST',
                body: data
            }
            const response = (await makeRequest(params));
            
            let type = 'error';
            if (response.status) {
                type = 'success';
                if (response.type == 1 || response.type == 0) {
                    deleteCookie('userToken');
                    setUserProfile();
                    navigate('/login');
                }
                setOtp('');
                setUserProfile();
            }
            setisLoading(false)
            toast({ type, message: response.message });

        } catch (error) {
            console.log(error);
            toast.error("Something is wrong");
            setisLoading(false)
        }
    }
    function copyText(data = {}) {
        var input = document.createElement("input");
        document.body.appendChild(input);
        input.value = data.text;
        input.select();
        document.execCommand("Copy");
        input.remove();
        let message = "";
        if (data.type == "referCode") {
            message = "Referral code copied successfully!";
        }
        else if (data.type == "referId") {
            message = "Referral link copied successfully!";
        }
        else if (data.type == "secretKey") {
            message = "2FA secret key copied successfully!";
        }
        toast({ type: 'success', message });
    }
    return (
        <section className='bg-default min-vh-100 text-white bg-particular pb-5'>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className='container pb-5 pt-4'>
                <div className='mb-3'>
                    <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Security</strong></h4>
                    <p className='pb-2 pt-2 fs-4' style={{ color: "#10EDB6", fontWeight: 600 }}>Google Authenticator</p>
                </div>

                <div className='row gy-5'>
                    <div className='col-12 mx-auto google-box'>
                        <h4>
                            <Badge bg="danger" style={{ borderRadius: 0, marginLeft: "-12px", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', fontWeight: 700 }} className='shadow-lg'>Step 1</Badge>
                        </h4>
                        <div>
                            <h5 className='text-center pt-3' style={{ fontWeight: 600 }}>Download  and install the Authenticator App</h5>

                            <div className='d-flex justify-content-center align-items-center py-4' style={{ gap: "15px" }}>
                                <div onClick={() => window.open(Config.TFA.downLoadLink.playstoreApp, 'new_window')} style={{ cursor: 'pointer' }}>
                                    <img src='./assets/googlestore.png' alt='' className='img-fluid' />
                                </div>
                                <div onClick={() => window.open(Config.TFA.downLoadLink.googleApp, 'new_window')} style={{ cursor: 'pointer' }}>
                                    <img src='./assets/appstore.png' alt='' className='img-fluid' />
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className='col-12 mx-auto google-box'>
                        <h4>
                            <Badge bg="danger" style={{ borderRadius: 0, marginLeft: "-12px", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', fontWeight: 700 }} className='shadow-lg'>Step 2</Badge>

                        </h4>

                        <div style={{ marginTop: "-40px" }}>
                            <h5 className='text-center pt-5 pt-md-3 ps-3 ps-md-0' style={{ fontWeight: 600 }}>By Clicking  On + Button on Authenticator App</h5>
                            {myProfile?.tfaenablekey == '' ?
                                <div className='d-flex justify-content-center align-items-center py-4 mx-auto text-center' style={{ gap: "20px" }}>

                                    <div className='text-center'>
                                        {TFAQRCode &&
                                            <img src={TFAQRCode} alt='' className='img-fluid text-white bg-light p-1 ' width={100} />
                                        }
                                        <p className='handleTextScan pt-2'>Scan this  QR Code on <br /> Authenticator App</p>
                                    </div>
                                    <div className='text-center'>
                                        <p className='or-text'>OR</p>
                                    </div>
                                    <div className='text-center'>
                                        <button className='btn-google px-3 py-2 shadow' onClick={() => copyText({ text: myProfile?.secretKey, type: "secretKey" })}>{myProfile?.secretKey} <ContentCopyIcon fontSize='sm' /></button>
                                        <p className='handleTextScan pt-2'>Copy & Paste the Code on Authenticator App</p>
                                    </div>

                                </div>

                                : <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: "#21CA50" }} className=' fw-bold'>Your 2FA is enabled right now.</p>}

                        </div>

                    </div>
                    <div className='col-12 mx-auto google-box pb-4'>
                        <h4>
                            <Badge bg="danger" style={{ borderRadius: 0, marginLeft: "-12px", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', fontWeight: 700 }} className='shadow-lg'>Step 3</Badge>
                        </h4>
                        <div className='mx-auto text-center'>
                            <h5 className='text-center pt-3' style={{ fontWeight: 600 }}>Enter 2FA Code from Authenticator App Below</h5>
                            <div className='mx-auto text-center otp-google-box'>

                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputType="number"
                                    placeholder="Enter 2FA code"
                                    name="code"
                                    id="code"
                                    autoComplete='off'
                                    shouldAutoFocus={false}
                                    containerStyle={{ width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                                    inputStyle={{ borderRadius: "40px", border: "none", width: "100%", height: window.innerWidth < 500 ? "45px" : "52px", outline: "none", fontWeight: "600", fontSize: "22px", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', textAlign: "center", background: "#7B7B7B", color: "white" }}
                                    renderSeparator={<span className='mx-2'></span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                                <button className='btn-profile shadow mt-4' disabled={isLoading} onClick={() => handleSubmit2FA()}>{myProfile?.tfaenablekey != '' ? 'Disable' : 'Enable'}</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>

    );
};

export default GoogleAuthenticator;