import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import NavbarOne from "./separate/NavbarOne";
import P2PHeader from "./separate/P2PHeader";
import ChatBox from "./ChatBox";
import { RiFileCopyFill } from "react-icons/ri";
import { makeRequest } from "../core/services/v1/request";
import { toast } from "../core/lib/toastAlert";
import Config from "../core/config/";
import { useContextData } from '../core/context';
import socketIOClient from "socket.io-client";
import { dateFormat } from '../core/helper/date-format';
import { getCookie } from '../core/helper/cookie';
import {
  useParams,
  useNavigate,
} from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  FormControl,
  RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import { useFileUpload } from "use-file-upload";
import { TbUpload } from 'react-icons/tb';
import DefaultUpload from "../assets/images/cloud+upload+file.png";
import { GoChevronLeft } from "react-icons/go";

const validationSchema = yup.object({
  phone: yup
    .number('Enter the phone no')
    .required('Phone no is required'),
  myfile1: yup
    .mixed().required('Upload proof is required'),
});
const helpcentervalidationSchema = yup.object({
  description: yup
    .string('Please enter the description')
    .required('Description is required'),
});
const otpvalidationSchema = yup.object({
  OTPCode: yup
    .string('Enter the 2FA')
    .required('2FA Code is required'),
});


export default function AppealHistory(props) {
  const defaultSrc = DefaultUpload;

  const { orderId } = useParams();
  const navigate = useNavigate();
  const { myProfile, setUserProfile } = useContextData();
  const [p2pOrdersList, setp2pOrdersList] = useState([]);
  const [p2pPaymentList, setp2pPaymentList] = useState([]);
  const [appealHistory, setappealHistory] = useState([]);
  const [chattingHistory, setchattingHistory] = useState([]);
  const [endtime, setEndtime] = useState(new Date());
  const [verifyStep, setverifyStep] = useState(0);
  const [confirmformOpen, setconfirmformOpen] = useState(false);
  const [acceptStatus, setacceptStatus] = useState(false);
  const [appealformOpen, setappealformOpen] = useState(false);
  const [cancelformOpen, setcancelformOpen] = useState(false);
  const [paymentformOpen, setpaymentformOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoading1, setisLoading1] = useState(false);
  const [enableChat, setenableChat] = useState(true);
  const [defaultChatOpen, setdefaultChatOpen] = useState(true);
  const [paymentChecked, setpaymentChecked] = useState(false);
  const [reportformOpen, setreportformOpen] = useState(false);
  const [feedbackStatus, setfeedbackStatus] = useState(false);
  const [buyerFeedbackStatus, setbuyerFeedback] = useState(false);
  const [sellerFeedbaackStatus, setsellerFeedback] = useState(false);
  const [myfeedbackStatus, setmyfeedbackStatus] = useState(false);
  const [sellerFeedbaackData, setsellerFeedbackData] = useState({});
  const [buyerFeedbaackData, setbuyerFeedbackData] = useState({});
  const [socketConnection, setSocketConnection] = useState(null);
  const [attachment, setattachment] = useState("");
  const [chatFile, setchatFile] = useState("");
  const [changefile, setchangefile] = useState("");
  const [chattingMsg, setChatting] = useState("");
  const [feedBackType, setfeedBackType] = useState("");
  const [feedBack, setfeedBack] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(null);
  const [myorderList, setmyorderList] = useState([]);
  const [selectedPaymentData, setselectedPaymentData] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [feedbackDetails, setfeedBackDetails] = useState({});
  const [files, selectFiles] = useFileUpload();
  const [appealstatus, setappealstatus] = useState(0);
  const [appealisLogin, setappealisLogin] = useState(false);
  const [helpcenterformOpen, sethelpcenterformOpen] = useState(false);

  // const [currentPage, setcurrentPage] = useState(asPath.replaceAll("/", ""));
  const [appealReasonData, setappealReasonData] = useState({
    buyerReason: [
      { "id": 1, "name": "I have made a payment but the seller has not released the crypto." },
      { "id": 2, "name": "I paid extra money to the seller." },
      { "id": 3, "name": "I suspected the seller is scamming." },
      { "id": 4, "name": "Others" },
    ],
    sellReason: [
      { "id": 1, "name": "I did not receive payment from the buyer." },
      { "id": 2, "name": "I received payment from the buyer but the amount is incorrect." },
      { "id": 3, "name": "I received payment but the payment info does not match the buyer's name." },
      { "id": 4, "name": "I suspected the buyer is scamming." },
      { "id": 5, "name": "Others" },
    ]
  })

  useEffect(() => {
    let socket = socketIOClient(Config.SOCKET_URL, { transports: ['websocket'] });
    let socketUnsubscribe;
    getp2pOrders();
    if (socket) {
      socket.on('connect', function () {
        setSocketConnection(socket);
        socketUnsubscribe = socket;
      });
      socket.on('connect_error', (err) => {
        console.log('socket connect_error', err)
      })
      socket.on('disconnect', function () {
        // console.log('socket disconnected')
      });
    }
    return () => {
      if (socketUnsubscribe) {
        socketUnsubscribe.disconnect();
      }
    };
  }, [myProfile]);
  useEffect(() => {
    const LoggedIn = getCookie('userToken');
    setisLoggedIn(LoggedIn);
    if (LoggedIn) {
      getp2pOrders();
      getmyFeedback();
      getAppealHistory();
    } else {
      navigate("/login");
    }
  }, [myProfile, orderId]);
  const scrollToBottom = () => {
    const reftp = document.getElementById("scroller");
    reftp.scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    if (socketConnection != null) {
      socketConnection.on('chattingResponse', function (data) {
        if (data.orderNo == orderId) {
          let chatHistory = chattingHistory;
          chatHistory.push(data.chattingHistory);
          setchattingHistory(chatHistory => [...chatHistory, data.chattingHistory]);
          setTimeout(() => {
            scrollToBottom();
          }, 100);
        }
      });
      socketConnection.on('orderResponse', function (data) {
        setUserProfile();
        getp2pOrders();
        setp2pOrdersList(data);
      });
      socketConnection.on('p2pappealResponse', function (data) {
        getAppealHistory();
        setappealHistory(data)
      });
    }
  }, [socketConnection]);

  async function getp2pOrders() {
    try {
      const data = { orderNo: orderId }
      const params = {
        url: `${Config.V1_API_URL}p2p/getp2puserOrders`,
        method: 'POST',
        body: data
      }
      setisLoading(true);
      const response = (await makeRequest(params));
      setisLoading(false);
      if (response.status && response.data) {
        let ownerId = "";
        response.data.map((item) => {
          getTimerUpdation(item.orderDetails);
          getChattingDetails(item?.orderDetails);
          setverifyStep(item.orderDetails[0].verifyStep);
          if (item.paymentDetails.length > 0) {
            setp2pPaymentList(item.paymentDetails);
          } else {
            setp2pPaymentList([]);
          }
          if (item.orderDetails.length > 0) {
            setp2pOrdersList(item.orderDetails);
            item.orderDetails.map((data) => {
              if (data.feedbackDet.length > 0) {
                data.feedbackDet.map((row) => {
                  if (row.fromUserId != myProfile?._id) {
                    setbuyerFeedbackData(row);
                    setbuyerFeedback(true);
                  } else if (row.fromUserId == myProfile?._id) {
                    setsellerFeedbackData(row);
                    setsellerFeedback(true);
                  }
                })
              } else {
                setbuyerFeedback(false);
                setsellerFeedback(false);
              }
              if (data?.userId == myProfile._id) {
                ownerId = data?.ownerId;
              } else {
                ownerId = data?.userId;
              }
              getmyOrderDetails(ownerId);
            })
          } else {
            setp2pOrdersList([]);
          }
          item.orderDetails.length > 0 && item.orderDetails[0].ownerPaymentDet &&
            item.orderDetails[0].ownerPaymentDet.methods.length > 0 &&
            item.orderDetails[0].ownerPaymentDet.methods.map((row, i) => {
              if (response.data[0].orderDetails[0].verifyStep == 1) {
                if (i == 0 && row.status != 0) {
                  setselectedPaymentData(row);
                  setpaymentChecked(true);
                }
              } else {
                if (response.data[0].orderDetails[0].paymentId == row._id && row.status != 0) {
                  setselectedPaymentData(row);
                  setpaymentChecked(true);
                }
              }
            })
        });
      }
    } catch (err) { }
  }
  const handleCancelChange = (event) => {
    // console.log("cancel:",event.target.value)
    setCancelReason(event.target.value);
  }
  async function getmyOrderDetails(userId) {
    try {
      let data = { advertiserNo: userId }
      const params = {
        url: `${Config.V1_API_URL}p2p/getmyOrderDetails`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      if (response.status) {
        setmyorderList(response?.data)
      }
    } catch (err) { }
  }
  async function getChattingDetails(chatData) {
    chatData.length > 0 && chatData.map((item) => {
      if (item.chattingHistory.length > 0) {
        setchattingHistory(item.chattingHistory);
      } else {
        setchattingHistory([]);
      }
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    })
  }
  async function getTimerUpdation(txnDetails) {
    let currentDate = new Date().getTime();
    txnDetails.length > 0 && txnDetails.map((item) => {
      currentDate = new Date(item.orderEndDate).getTime();
    });
    if (currentDate !== endtime) {
      setEndtime(currentDate);
    }
  }
  async function getAppealHistory() {
    try {
      const data = { orderNo: orderId }
      const params = {
        url: `${Config.V1_API_URL}p2p/getappealHistory`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      if (response.status) {
        setappealHistory(response.data);
        response.data.length > 0 && response.data.map((item) => {
          setappealstatus(item.status);
          if (item.userId == myProfile._id) {
            setappealisLogin(true);
          }
        })
        getTimerUpdation(response.data);
      }
    } catch (err) { }
  }
  async function getmyFeedback() {
    try {
      const data = { orderNo: orderId }
      const params = {
        url: `${Config.V1_API_URL}p2p/getmyFeedBack`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      if (response.status) {
        setfeedBackDetails(response?.data);
        let text = "";
        response?.data.length > 0 && response?.data.map((item) => {
          if (item?.fromUserId == myProfile?._id) {
            if (item.feedBackStatus == 1) {
              text = "positive";
            } else {
              text = "negative";
            }
            setfeedBackType(text);
            setfeedBack(item.description);
            setmyfeedbackStatus(true);
          }
        })
      }
    } catch (err) { }
  }
  async function confirmformClose() {
    setconfirmformOpen(false);
    setacceptStatus(false);
  }
  async function confirmPayment() {
    try {
      setconfirmformOpen(true);
      let paymentId = "";
      if ((p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].orderType == "buy")) {
        paymentId = p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentId;
      } else {
        paymentId = selectedPaymentData._id
      }
      const data = { orderNo: orderId, userId: myProfile?._id, paymentId: paymentId }
      const params = {
        url: `${Config.V1_API_URL}p2p/submitOrder`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = 'success';
        getp2pOrders();
        setconfirmformOpen(false);
        socketConnection.emit('createp2pOrder', response?.data);
        response.data.length > 0 && response.data.map((item) => {
          setverifyStep(item.verifyStep);
        });
      }
      toast({ type, message: response.message });
    } catch (err) { console.log("err:", err) }
  }
  const formikhelpcenter = useFormik({
    initialValues: {
      description: ''
    },
    validationSchema: helpcentervalidationSchema,
    onSubmit: async (values) => {
      const data = {
        orderNo: orderId,
        buyerId: myProfile?._id,
        supportMessage: values.description,
      }
      const params = {
        url: `${Config.V1_API_URL}p2p/helpcenterAppeal`,
        method: 'POST',
        body: data
      }
      const result = (await makeRequest(params));
      let type = 'error';
      if (result.status) {
        type = 'success';
      }
      formikhelpcenter.values.description = "";
      sethelpcenterformOpen(false);
      setisLoading1(false);
      toast({ type, message: result.message });
    },
  });

  const formik = useFormik({
    initialValues: {
      description: '',
      phone: '',
      myfile1: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setisLoading1(true);
      if (values.myfile1 == "") {
        const data = {
          orderNo: orderId,
          userId: myProfile?._id,
          reasonAppeal: values.reasonAppeal,
          description: values.description,
          phone: values.phone,
          attachment: ""
        }
        const params = {
          url: `${Config.V1_API_URL}p2p/createAppeal`,
          method: 'POST',
          body: data
        }
        const result = (await makeRequest(params));
        let type = 'error';
        if (result.status) {
          type = 'success';
          socketConnection.emit('createp2pAppeal', result?.data);
        }
        getp2pOrders();
        getAppealHistory();
        setappealformOpen(false);
        setisLoading1(false);
        toast({ type, message: result.message });
      } else {
        setisLoading1(true);
        const formData = new FormData();
        let sizeFile = 1;
        let fileToUpload = values.myfile1;
        let fileName = 'attachment';
        let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
        formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
        const params = {
          url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type="attachment"`,
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }

        const response = (await makeRequest(params));
        setisLoading1(false);
        if (response.status) {
          const data = {
            orderNo: orderId,
            userId: myProfile?._id,
            reasonAppeal: values.reasonAppeal,
            description: values.description,
            phone: values.phone,
            attachment: response.message[0].location
          }
          const params = {
            url: `${Config.V1_API_URL}p2p/createAppeal`,
            method: 'POST',
            body: data
          }
          const result = (await makeRequest(params));
          let type = 'error';
          if (result.status) {
            type = 'success';
            socketConnection.emit('createp2pAppeal', result?.data);
          }
          getp2pOrders();
          getAppealHistory();
          setisLoading1(false);
          setappealformOpen(false);
          toast({ type, message: result.message });
        }
      }
    },
  });
  async function selectPayment(data) {
    setselectedPaymentData(data);
  }
  const formik1 = useFormik({
    initialValues: {
      OTPCode: '',
    },
    validationSchema: otpvalidationSchema,
    onSubmit: async (values) => {
      const data = { orderNo: orderId, userId: myProfile?._id, OTPCode: values.OTPCode }
      const params = {
        url: `${Config.V1_API_URL}p2p/submitOrder`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = 'success';
        setpaymentformOpen(false);
        getp2pOrders();
        socketConnection.emit('createp2pOrder', response?.data);
      }
      toast({ type, message: response.message });
    }
  });
  async function Transfer() {
    setconfirmformOpen(true);
  }
  async function appealForm() {
    setappealformOpen(true)
  }
  async function handleClose() {
    setpaymentformOpen(false);
    setcancelformOpen(false);
    setconfirmformOpen(false);
  }
  const handleStatusChange = (event) => {
    if (acceptStatus == false) {
      setacceptStatus(true);
    } else {
      setacceptStatus(false);
    }
  }
  const handlefeedChange = (event) => {
    setfeedBack(event.target.value);
  }
  async function submitMessage() {
    try {
      let uploadFile = chatFile;
      if (chattingMsg == "" && uploadFile == "") {
        return false;
      }
      if (uploadFile == "") {
        setenableChat(true);
        const data = {
          orderNo: orderId,
          userId: myProfile?._id,
          chattingMsg: chattingMsg,
          attachment: "",
        }
        const params = {
          url: `${Config.V1_API_URL}p2p/submitChatMessage`,
          method: 'POST',
          body: data
        }
        const result = (await makeRequest(params));
        getp2pOrders();
        setChatting("");
      } else {
        const formData = new FormData();
        let sizeFile = 1;
        let fileToUpload = uploadFile;
        let fileName = 'attachment';
        let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
        formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
        const params = {
          url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type="attachment"`,
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        setisLoading(true);
        const response = (await makeRequest(params));
        setisLoading(false);
        if (response.status) {
          const data = {
            orderNo: orderId,
            userId: myProfile?._id,
            chattingMsg: chattingMsg,
            attachment: response.message[0].location
          }
          const reqData = {
            url: `${Config.V1_API_URL}p2p/submitChatMessage`,
            method: 'POST',
            body: data
          }
          const result = (await makeRequest(reqData));
          getp2pOrders();
          setChatting("");
          setattachment("");
        }
      }
    } catch (err) {
      // console.log('coming-here2', err)
    }
  }

  function handleChatChange(event) {
    const value = event.target.value;
    if (value != "") {
      setChatting(event.target.value);
      setenableChat(false);
    } else {
      setenableChat(true);
      setChatting("")
    }
  }
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      submitMessage('');
    }
  }
  async function feedbackChange() {
    if (feedbackStatus == true) {
      setfeedbackStatus(false);
    } else {
      setfeedbackStatus(true);
    }
  }
  async function FeedBack(feedBackType) {
    try {
      setisLoading(true);
      let userId = (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].userId);
      let ownerId = (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].ownerId);
      let toUserId = "";
      if (feedbackDetails?.toUserId == undefined) {
        if (ownerId == myProfile?._id) {
          toUserId = userId;
        } else {
          toUserId = ownerId;
        }
      } else {
        if ((feedbackDetails?.toUserId) == (myProfile && myProfile?._id)) {
          toUserId = feedbackDetails?.fromUserId;
        } else {
          toUserId = feedbackDetails?.toUserId;
        }
      }
      const data = {
        orderNo: orderId,
        toUserId: toUserId,
        type: feedBackType,
        description: feedBack
      }
      const params = {
        url: `${Config.V1_API_URL}p2p/createFeedback`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = 'success'
      }
      toast({ type, message: response.message });
      setisLoading(false);
      setfeedbackStatus(false);
      getmyFeedback();
      getp2pOrders();
    } catch (err) { }
  }
  async function CancelAppeal() {
    try {
      const params = {
        url: `${Config.V1_API_URL}p2p/cancelAppeal`,
        method: 'POST',
        body: { orderNo: orderId }
      }
      const result = (await makeRequest(params));
      let type = 'error';
      if (result.status) {
        type = 'success';
      }
      getp2pOrders();
      getAppealHistory();
      toast({ type, message: result.message });
    } catch (err) { }
  }
  async function handleLoadClose() {
    setisLoading(false);
    setdefaultChatOpen(true);
  }
  async function cancelOrder() {
    try {
      const data = {
        orderNo: orderId,
        userId: myProfile?._id,
        reason: cancelReason,
        type: "cancelorder",
      }
      const params = {
        url: `${Config.V1_API_URL}p2p/cancelOrder`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = 'success';
      }
      socketConnection.emit('createp2pOrder', response?.data);
      setcancelformOpen(false);
      toast({ type, message: response.message });
    } catch (err) { }
  }
  async function copyToCode(textToCopy) {
    try {
      var input = document.createElement('textarea');
      document.body.appendChild(input);
      input.value = textToCopy;
      input.select();
      document.execCommand("Copy");
      input.remove();
      toast({ type: "success", message: "Order copied successfully!" });
    } catch (err) { }
  }
  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div class="deposit-page-top-banner">
        <P2PHeader />
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <GoChevronLeft className="deposit-back-button-icon" onClick={() => navigate("/order-details/" + (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].orderNo))} />
                <span className="deposit-text-1">Appeal History</span>
                <div className="row align-items-center ">
                  <div className="col-lg-6 mt-4">
                    {(appealHistory.length > 0 && appealstatus == 2) && (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status == 3) &&
                      <span>
                        <b>{(appealHistory && appealHistory[0] && appealHistory[0].userName != "") ?
                          (appealHistory && appealHistory[0] && appealHistory[0].userName)
                          :
                          (appealHistory && appealHistory[0] && appealHistory[0].userEmail)
                        }</b>
                        {" "}has cancelled the appeal
                      </span>
                    }
                    {(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status) == 1 &&
                      <span className="order-released"><br />
                        Order released{" "}
                      </span>
                    }
                  </div>
                  <div className="col-lg-6 text-lg-end">
                    <span>
                      <b>Order number : </b>{(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].orderNo)} <RiFileCopyFill onClick={() => copyToCode(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].orderNo)} /><br />
                      <b>Time Created : </b>{dateFormat(p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].createdDate)}
                    </span>
                  </div>
                  {appealHistory && appealHistory.length > 0 && appealHistory[0].userId == myProfile?._id && appealstatus == 1 && (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status) != 1 &&
                    <div className="col-lg-6 mt-4">
                      <p>Pending response from respondent</p>
                      <p>1.if both parties have received an agreement,you can cancel the appeal and proceed to complete the trade.</p>
                      <p>2.if complainant did not respond in time,CS will get involved and arbitrate.</p>
                      <p>3.To provide more information,please <a href="#" onClick={() => setappealformOpen(true)} className="text-decoration-none text-primary">Provide more info</a> .Info provided by both users and CS can be found in "Appeal progress".</p>
                      {appealisLogin == true &&
                        <div className='text-end'>
                          <Button className='text-btn-ui cap' type="button" onClick={() => CancelAppeal()}>Cancel Appeal</Button>
                        </div>
                      }
                    </div>
                  }
                  {appealHistory && appealHistory.length > 0 && appealHistory[0].userId != myProfile?._id && appealstatus == 1 && (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status) != 1 &&
                    <div className="col-lg-6 mt-4">
                      <p>1.CS is handling the case,please make sure you are contactable via the registered email and phone </p>
                      <p>2.To provide more information,please <a href="#" onClick={() => setappealformOpen(true)} className="text-decoration-none text-primary">Provide more info.</a>Info provided by both users and CS can be found in "Appeal progress".</p>
                    </div>
                  }
                  {(myProfile?._id == (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].sellerUserId)) &&
                    (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status != 2) &&
                    (p2pOrdersList && p2pOrdersList[0] && p2pOrdersList[0].status != 1) &&
                    appealstatus == 1 &&
                    <div className="col-lg-6 mt-4">
                      <p>The buyer has filed an appeal claiming the crypto is not released.If you confirmed the money is recieved in your payment account.please click[Payment Received] directly.After the confirmation the appeal will end automatically and the order will be complete</p>
                      <div className='text-end'>
                        <Button className='text-btn-ui cap' type="button" onClick={() => setpaymentformOpen(true)}>Payment Received</Button>
                      </div>
                    </div>
                  }
                  <div className="text-end">
                    <p><a href="javascript:void(0)" onClick={() => sethelpcenterformOpen(true)} className="text-decoration-none text-primary">Appeal Help center</a></p>
                  </div>
                  {appealHistory.length > 0 && appealHistory.slice().reverse().map((data) => {
                    return (
                      data.appealHistory.length > 0 && data.appealHistory.slice().reverse().map((item) => {
                        return (
                          <div className="col-lg-12 mt-4 border-top pt-3  pb-3">
                            <div className="card p-2 shadow">
                              <div className='d-flex'>
                                <div className='p-2 color-white f-12 pr-21'>{dateFormat(item?.date)}</div>
                              </div>
                              <span className="d-block my-2">
                                {(data?.userId != myProfile?._id) ? 'Complainant provides additional info' : 'Respondent provides additional info'}
                              </span>
                              {data.reason != "" &&
                                <>
                                  <span className="d-block my-2">
                                    <b>Reason : </b>
                                  </span>
                                  <span className="d-block my-2">
                                    <p>{data.reason}</p>
                                  </span>
                                </>
                              }
                              {item.description != "" &&
                                <>
                                  <span className="d-block my-2">
                                    <b>Description : </b>
                                  </span>
                                  <span className="d-block my-2">
                                    <p>{item.description}</p>
                                  </span>
                                </>
                              }
                              {item && item.attachment != "" &&
                                <img src={item.attachment} alt="" style={{ width: "70px", height: "70px" }} className="deposit-crupto-explain-image" />
                              }
                            </div>
                          </div>

                        )
                      })
                    )
                  })
                  }
                </div>
              </div>
              <ChatBox
                chattingHistory={chattingHistory}
                p2pOrdersList={p2pOrdersList}
                myProfile={myProfile}
                myorderList={myorderList}
                reportformOpen={reportformOpen}
                chattingMsg={chattingMsg}
                handleChatChange={handleChatChange}
                handleKeyPress={handleKeyPress}
                submitMessage={submitMessage}
                enableChat={enableChat}
                isLoading={isLoading}
                defaultChatOpen={defaultChatOpen}
                setdefaultChatOpen={setdefaultChatOpen}
                setisLoading={setisLoading}
                setenableChat={setenableChat}
                setChatting={setChatting}
                setreportformOpen={setreportformOpen}
                setmyorderList={setmyorderList}
                setp2pOrdersList={setp2pOrdersList}
                setchattingHistory={setchattingHistory}
                selectFiles={selectFiles}
                files={files}
                setchatFile={setchatFile}
                setchangefile={setchangefile}
                changefile={changefile}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Transter notify seller */}
      <Modal show={confirmformOpen} onHide={() => setconfirmformOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='color-white'>
            Please confirm that you have successfully transferred the money to seller througth the following payment method before clicking on the "Transferred ,notify seller" button</p>
          <div class="modal-body">
            {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0]?.orderType == "buy") ?
              <>
                {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0]?.paymentDet &&
                  p2pPaymentList[0]?.paymentDet?.methods &&
                  (p2pPaymentList[0]?.paymentDet?.methods?.paymenttype == "Bank" || p2pPaymentList[0]?.paymentDet?.methods?.paymenttype == "IMPS")) &&
                  <div className='bg-gray p-2'>
                    <div className='d-flex my-3 justify-content-between'>
                      {/* <div>{item.paymenttype}</div> */}
                      {/* <div><FaEdit></FaEdit></div> */}
                    </div>
                    <p><b>Account holder name :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.holderName)}</p>
                    <p><b>Account number :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.accountNo)}</p>
                    <p><b>IFSC code:</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.ifscCode)}</p>
                    <p><b>Account type :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.accountType)}</p>
                    <p><b>Bank name :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.bankName)}</p>
                    <p><b>Account opening branch :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.branch)}</p>
                  </div>
                }
                {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0]?.paymentDet &&
                  p2pPaymentList[0]?.paymentDet?.method && p2pPaymentList[0]?.paymentDet?.methods?.paymenttype == 'UPI') &&
                  <div className='bg-gray mt-2 p-2'>
                    <div className='d-flex my-3 justify-content-between'>
                      {/* <div>{item.paymenttype}</div> */}
                      {/* <div><FaEdit></FaEdit></div> */}
                    </div>
                    <p><b>Name :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.holderName)}</p>
                    <p><b>UPI ID :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.upiId)}</p>
                  </div>
                }
                {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0]?.paymentDet &&
                  p2pPaymentList[0]?.paymentDet?.method && p2pPaymentList[0]?.paymentDet?.methods?.paymenttype == 'Paytm') &&
                  <div className='bg-gray mt-2 p-2'>
                    <div className='d-flex my-3 justify-content-between'>
                      {/* <div>{item.paymenttype}</div> */}
                      {/* <div><FaEdit></FaEdit></div> */}
                    </div>
                    <p><b>Name :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.holderName)}</p>
                    <p><b>Account :</b> {(p2pPaymentList && p2pPaymentList[0] && p2pPaymentList[0].paymentDet && p2pPaymentList[0].paymentDet.methods && p2pPaymentList[0].paymentDet.methods.accountNo)}</p>
                  </div>
                }
              </>
              :
              <>
                {((selectedPaymentData && selectedPaymentData.paymenttype == "Bank") || (selectedPaymentData && selectedPaymentData.paymenttype == "IMPS")) &&
                  <div className='bg-gray p-2'>
                    <div className='d-flex my-3 justify-content-between'>
                      <h4>{selectedPaymentData && selectedPaymentData.paymenttype}</h4>
                    </div>
                    <p><b>Account holder name :</b> {(selectedPaymentData && selectedPaymentData.holderName)}</p>
                    <p><b>Account number :</b> {(selectedPaymentData && selectedPaymentData.accountNo)}</p>
                    <p><b>IFSC code:</b> {(selectedPaymentData && selectedPaymentData.ifscCode)}</p>
                    <p><b>Account type :</b> {(selectedPaymentData && selectedPaymentData.accountType)}</p>
                    <p><b>Bank name :</b> {(selectedPaymentData && selectedPaymentData.bankName)}</p>
                    <p><b>Account opening branch :</b> {(selectedPaymentData && selectedPaymentData.branch)}</p>
                  </div>
                }
                {(selectedPaymentData && selectedPaymentData.paymenttype == 'UPI') &&
                  <div className='bg-gray mt-2 p-2'>
                    <div className='d-flex my-3 justify-content-between'>
                      <h4>{selectedPaymentData && selectedPaymentData.paymenttype}</h4>
                    </div>
                    <p><b>Name :</b> {(selectedPaymentData && selectedPaymentData.holderName)}</p>
                    <p><b>UPI ID :</b> {(selectedPaymentData && selectedPaymentData.upiId)}</p>
                  </div>
                }
                {(selectedPaymentData && selectedPaymentData.paymenttype == 'Paytm') &&
                  <div className='bg-gray mt-2 p-2'>
                    <div className='d-flex my-3 justify-content-between'>
                      <h4>{selectedPaymentData && selectedPaymentData.paymenttype}</h4>
                    </div>
                    <p><b>Name :</b> {(selectedPaymentData && selectedPaymentData.holderName)}</p>
                    <p><b>Account :</b> {(selectedPaymentData && selectedPaymentData.accountNo)}</p>
                  </div>
                }
              </>
            }
            <div className="col-lg-12 mt-4">
              <span className="deposit-text-1">Tips</span>
              <ul>
                <li>
                  When you sell your cryptocurrency, the added payment method will be shown to the buyer during the transaction. To accept cash transfer, please make sure the information is correct
                </li>
              </ul>
            </div>
            <div className="col-lg-12 mt-4">
              <input class="form-check-input"
                type="checkbox"
                id="checkedKyc"
                name="checkedKyc"
                // checked={passData.checkedKyc}
                onChange={handleStatusChange}
              />
              <label class="form-check-label" for="flexCheckDefault">
                I have made payment from my real-name verified payment account consistent with my registered name on Cryptobiz.
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => confirmformClose()}>Cancel</button>
            <button class="add-payment-method-confirm-button px-3" type="button" disabled={acceptStatus == true ? false : true} onClick={() => confirmPayment()}>Confirm Payment</button>
            {/* <button type="button" class="btn btn-next">Refresh</button> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* payment received */}
      <Modal show={paymentformOpen} onHide={() => setpaymentformOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik1.handleSubmit}>
            <div className="row justify-content-center align-items-center ">
              <div className="row mt-4">
                <span className="phonenumber-change-text-2">
                  Enter 2FA Verification Code
                </span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    autoComplete='off'
                    label="Enter 2FA Code"
                    id="OTPCode"
                    name='OTPCode'
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    error={formik1.touched.OTPCode && Boolean(formik1.errors.OTPCode)}
                    helperText={formik1.touched.OTPCode && formik1.errors.OTPCode} />
                </div>
                {formik1.errors.OTPCode ? <span className="phonenumber-change-text-3 text-muted error"> {formik1.errors.OTPCode}</span> : null}
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>
                <div class="d-grid">
                  <button class="add-payment-method-confirm-button px-3" type="submit" >Confirm</button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* Cancel order */}
      <Modal show={cancelformOpen} onHide={() => setcancelformOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-body">
            <div className='bg-gray p-2'>
              <div className='d-flex my-3 justify-content-between'>
                <div className="col-lg-12 mt-4">
                  <span className="deposit-text-1">Tips</span>
                  <p>1. If you have already paid the seller, please do not cancel the order.</p>
                  <p>2. Your account will be SUSPENDED for the day if you exceed<span className="innertext"> 3 accountable cancellations </span>in a day.</p>
                </div>
              </div>
            </div>
            <p>Who do you want to cancel the order?</p>
            <div class="form-check">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={handleCancelChange}
                  value={cancelReason}
                >
                  <FormControlLabel className='color-white' value="I do not want to trade anyone" control={<Radio />} label='I do not want to trade anyone' />
                  <FormControlLabel className='color-white' value="I do not meet the requirements of the advertisers trading terms and condition" control={<Radio />} label='I do not meet the requirements of the advertisers trading terms and condition' />
                  <FormControlLabel className='color-white' value="Seller is asking for extra fee" control={<Radio />} label='Seller is asking for extra fee' />
                  <FormControlLabel className='color-white' value="Problem with sellers payment method result in unsuccessful payments" control={<Radio />} label='Problem with sellers payment method result in unsuccessful payments' />
                  <FormControlLabel className='color-white' value="Other reasons" control={<Radio />} label='Other reasons' />
                </RadioGroup>
              </FormControl>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setcancelformOpen(false)}>Cancel</button>
            <button class="add-payment-method-confirm-button px-3 " type="button" disabled={cancelReason != "" ? false : true} onClick={() => cancelOrder()}>Confirm</button>
            {/* <button type="button" class="btn btn-next">Refresh</button> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* appeal reaason form*/}
      <Modal show={appealformOpen} onHide={() => setappealformOpen(false)}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Reason for Appeal(Mandatory)</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className=" justify-content-center align-items-center ">
              <div className=" mt-4">
                <span className="phonenumber-change-text-2">
                  description
                </span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    autoComplete='off'
                    label="description"
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className=" mt-4">
                <span className="phonenumber-change-text-2">
                  phone(Mandatory)
                </span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    autoComplete='off'
                    label="phone"
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.phone ? <small className="invalid-terms error">{formik.errors.phone}</small> : null}
              </div>
              <span className="phonenumber-change-text-2">
                Upload Proof(Mandatory)
              </span>
              <div>

                <img className='payment-qrcode-optional-image' src={files?.source || defaultSrc} alt="preview" /><br />
                <button className='payment-qrcode-optional-button mt-3' name="myfile1" type="button"
                  onClick={() =>
                    selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
                      // console.log("Files Selected", { name, size, source, file });
                      setattachment(file);
                      formik.values.myfile1 = file;
                    })
                  }
                >
                  <TbUpload />Upload
                </button>
              </div>
              {formik.errors.myfile1 ? <small className="invalid-UPIID error">{formik.errors.myfile1}</small> : null}
            </div>
            <div className='row mt-5'>
              <div className='col'>
                <div class="d-grid">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setappealformOpen(false)}>Cancel</button>
                  <button class="add-payment-method-confirm-button px-3" type="submit" disabled={isLoading1}>Appeal</button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={helpcenterformOpen} onHide={() => sethelpcenterformOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Help center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formikhelpcenter.handleSubmit}>
            <div className=" justify-content-center align-items-center ">
              <div className=" mt-4">
                <span className="phonenumber-change-text-2">
                  description
                </span>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    autoComplete='off'
                    label="description"
                    id="description"
                    name="description"
                    value={formikhelpcenter.values.description}
                    onChange={formikhelpcenter.handleChange}
                    onBlur={formikhelpcenter.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>
                <div class="d-grid">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => sethelpcenterformOpen(false)}>Cancel</button>
                  <button class="add-payment-method-confirm-button px-3 mt-3" type="submit" disabled={isLoading1}>Submit</button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
