import React, { useState, useEffect } from "react";
import {
    useNavigate
} from "react-router-dom";

import Config from "../../../core/config/";
import { toast } from "../../../core/lib/toastAlert";
import { makeRequest } from "../../../core/services/v1/request";
import './Referral.css';
import { Col, Container, Row } from 'react-bootstrap';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReferralHistory from './ReferralHistory';
import ReferralFriend from './ReferralFriend';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const Referral = (props) => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [referPercentage, setReferPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [referralData, setreferralData] = useState({});
  const [referId, setreferId] = useState('');
  const [referCode, setReferCode] = useState('');

  useEffect(() => {
    if (props.type) {
      getReferral();
    }
    if (props.myProfile && props.myProfile.referCommission) {
      setReferPercentage(props.myProfile.referCommission);
    }
  }, [props]);

  async function getReferral() {
    const params = {
      url: `${Config.V1_API_URL}user/getReferralData?userType=${props.type}`,
      method: 'GET'
    };
    const response = await makeRequest(params);
    if (response.status) {
      // if (!response?.data || response?.data?.profile?.kycstatus != 1) {
      //   if (response?.data && response?.data?.profile?.kycstatus != 1) {
      //     let type = 'error';
      //     toast({ type, message: 'Please verify KYC to refer users!' });
      //   }
      //   navigate('/my/identification');
      // }
      if (response.data && response.data.profile?._id) {
        const profileResp = response.data.profile;
        if (props.type === "user") {
          setreferId(Config.FRONEND_URL + 'register?refer=' + profileResp._id);
          setReferCode(profileResp._id);
          setReferPercentage(
            (response.data && response.data.referPercentage) ? response.data.referPercentage : 0
          );
        }
        else if (props.type === "promoter") {
          setreferId(Config.FRONEND_URL + 'register?refer=' + profileResp.referCode);
          setReferCode(profileResp.referCode);
          setReferPercentage(
            (props.myProfile && props.myProfile.referCommission) ? props.myProfile.referCommission : 0
          );
        }
        setreferralData(response.data);
      }
    }
  }

  function copyText(data = {}) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    let message = "";
    if (data.type == "referCode") {
      message = "Referral code copied successfully!";
    }
    else if (data.type == "referId") {
      message = "Referral link copied successfully!";
    }
    toast({ type: 'success', message });
  }

    return (
        <section >
            <div>

                <Container className='my-3'>
                    <Row className='gy-3 justify-content-center' >
                        <Col xs={12} md={12} lg={12} className='box-referral-status px-3 px-md-4'>
                            <div>
                                <h4>Referral Code</h4>
                            </div>
                            <div>
                                <p><small> {referCode} <CopyToClipboard text={`${referCode}`} onCopy={() => copyText({ text: referCode, type: "referCode" })}>
                                    <ContentCopyIcon fontSize='small' sx={{ cursor: 'pointer' }} />
                                </CopyToClipboard></small></p>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={12} className='box-referral-status px-3 px-md-4'>
                            <div>
                                <h4>Referral Link</h4>
                            </div>
                            <div className='text-end overflow-hidden'>
                                <p className='text-break'><small>{referId}  <CopyToClipboard text={`${{ referId }}`} onCopy={() => copyText({ text: referId, type: "referId" })}>
                                    <ContentCopyIcon fontSize='small' sx={{ cursor: 'pointer' }} />
                                </CopyToClipboard></small></p>

                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className='d-flex justify-content-between align-items-center py-2'>
                    <div> <p className='' style={{ color: '#C8C8C8' }}>Refer your Friends and earn {referPercentage}% of the trading fee</p></div>
                    <div className='d-flex justify-content-center align-items-center' style={{ gap: '15px' }}>
                    <div style={{ cursor: 'pointer' }}><img src="/assets/whatsapp.png" alt="" className='img-fluid' onClick={() => window.open('https://wa.me/?text=' + referId)} /></div>
                    <div style={{ cursor: 'pointer' }}><img src="/assets/facebook.png" alt="" className='img-fluid' onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=' + referId)} /></div>
                    <div style={{ cursor: 'pointer' }}><img src="/assets/telegram.png" alt="" className='img-fluid' onClick={() => window.open('https://telegram.me/share/url?url=' + referId + '&text=')} /></div>
                    <div style={{ cursor: 'pointer' }}><img src="/assets/twitter.png" alt="" className='img-fluid' onClick={() => window.open('https://twitter.com/intent/tweet?url=' + referId + '&text=')} /></div>
                    </div>
                </div>


                <div className='my-3'>
                    <Box sx={{ width: '100%' }}>
                        <Box >
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        background: 'linear-gradient(90.03deg, #10EDB6 0.02%, #4C62F7 100%);',
                                        borderRadius: "17px"
                                    },
                                    '& .MuiTab-root': {
                                        color: 'white',
                                        textTransform: "capitalize",
                                        fontSize: "20px",
                                        fontWeight: "400",
                                        lineHeight: "38px",
                                        fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                        fontStyle: "normal",
                                        padding: "auto"
                                    },
                                    '& .MuiTab-root.Mui-selected': {
                                        color: 'white',
                                        // fontWeight: "800"
                                    }

                                }}
                            >
                                <Tab label="Referral history" {...a11yProps(0)} />
                                <Tab label="Referral friends" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <ReferralHistory referralData={referralData} loading={loading} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ReferralFriend referralData={referralData} loading={loading} />
                        </TabPanel>
                    </Box>
                </div>

            </div>
        </section >
    );
};

export default Referral;