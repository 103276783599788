import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/style.css";
import NavbarOne from "./separate/NavbarOne";
import Footer from "./separate/Footer";
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Error404Content from './Error404Content';
import { Helmet } from "react-helmet-async";
import PageTitleDes from "../core/support/PageTitleDes";

export default function Withdraw(props) {

  const { dynCMS } = useParams()
  const navigate = useNavigate();

  const [cmsIdentifier, setCmsIdentifier] = useState("");
  const [cmsData, setCmsData] = useState([]);

  const [contentLoad, setContentLoad] = useState(false);

  useEffect(() => {
    async function getCmsData() {
      try {
        let identifyVal = props.identify;

        let identifyType = "";
        if (identifyVal == "dyn") {
          identifyVal = "/" + dynCMS;
          identifyType = "dyn"
        }
        else if (identifyVal == "about") {
          identifyVal = "/" + "about";
          identifyType = "dyn"
        }
        else if (identifyVal == "terms") {
          identifyVal = "/" + "terms";
          identifyType = "dyn"
        }
        else if (identifyVal == "otc") {
          identifyVal = "/" + "otc_desk";
          identifyType = "dyn"
        }

        setCmsIdentifier(identifyVal);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        const value = { identify: identifyVal, type: identifyType }
        const params = {
          url: `${Config.V2_API_URL}cms/getCMS`,
          method: 'POST',
          body: value
        }
        const response = (await makeRequest(params));
        setContentLoad(true);
        if (response.status && response.getcmsDetails) {
          setCmsData(response.getcmsDetails);
        }
      } catch (err) {
        setContentLoad(true);
      }
    }
    if (props.identify != cmsIdentifier) {
      getCmsData();
    }
  }, [props, dynCMS]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [])
  async function backButton() {
    try {
      const length = window.history.length;
      if (length > 0) {
        window.history.back();
      } else {
        navigate("/");
      }
    } catch (err) { }
  }
  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <Helmet>
        {(dynCMS === 'about' || window.location.pathname === '/about') ? <link rel="canonical" href="https://www.uniticexchange.com/about" /> : (dynCMS === 'fees' || window.location.pathname === '/fees') ? <link rel="canonical" href="https://www.uniticexchange.com/fees" /> : null}
      </Helmet>

      {(dynCMS === 'otc' || window.location.pathname === '/otc_desk') ? <PageTitleDes title={`OTC Trading Desk for USDT in India | Buy & Sell USDT Tether at Unitic Exchange.`} description={`Trade large volumes effortlessly with our OTC Desk. Place orders from minimum of 10,000 USDT easily, ensuring secure and efficient transactions for high-value trades at Unitic Exchange.`} /> : null}

      {
        (contentLoad === true && cmsData && cmsData.length === 0) ?

          <Error404Content />
          :
          <div className="bg-default text-white bg-particular" style={{ minHeight: "90vh" }}>
            <div className="container pt-4">
              {cmsData && cmsData[0] && cmsData[0].title ?
                <div>
                  <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> {cmsData[0].title}</strong></h4>
                </div>

                : null}
              <div className='pt-4 overflow-hidden pb-5'>
                {cmsData && cmsData[0] && cmsData[0].description && <div dangerouslySetInnerHTML={{ __html: cmsData && cmsData[0] && cmsData[0].description }} ></div>}
              </div>
            </div>
          </div>
      }


      <Footer />
    </div>
  );
}
