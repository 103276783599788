import React, { useState, useEffect, useRef } from 'react';
import './Registration.css';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../Otp/Otp.css';
import OtpInput from 'react-otp-input';

import {
    useLocation,
    useNavigate,
} from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import { Country } from "country-state-city";

import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

import { makeRequest } from "../../../core/services/v1/request";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config/";
import { showEmail } from "../../../core/helper/date-format";

import { useContextData } from "../../../core/context/index";
import termsandconditions from "../../../assets/Terms-Conditions-Privacy-Policy.pdf";
// import validator from 'validator';
import phone from "phone";

const validationSchema = yup.object({
    // firstname: yup
    //     .string("Enter your Firstname")
    //     .required("Firstname is required"),
    // lastname: yup.string("Enter your lastname").required("lastname is required"),
    name: yup.string("Enter your name").required("name is required"),
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    password: yup
        .string("Enter your password")
        .required("Password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    confirmpassword: yup
        .string("Enter your Confirm password")
        .required("Confirm password is required")
        .oneOf([yup.ref("password"), null], "Passwords don't match."),
    terms: yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});

const validationSchemaPhNum = yup.object({
    // phoneno: yup
    //   .required('Phone number is required'),
    firstname: yup
        .string("Enter your Firstname")
        .required("Firstname is required"),
    lastname: yup.string("Enter your lastname").required("lastname is required"),
    password: yup
        .string("Enter your password")
        .required("Password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    confirmpassword: yup
        .string("Enter your Confirm password")
        .required("Confirm password is required")
        .oneOf([yup.ref("password"), null], "Passwords don't match."),
    terms: yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#ffffff !important',
    },
    input: {
        color: '#ffffff !important',
    },
    outlinedInput: {
        '& fieldset': {
            borderColor: '#ffffff !important',
            '&:hover': {
                borderColor: '#ffffff !important',
            },
            '&.Mui-focused': {
                borderColor: '#ffffff !important',
            },
        },
    },
}));


const Registration = () => {
    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    const [value, setValue] = React.useState(0);
    const [otp, setOtp] = useState('');

    const emailOtp = (e) => {
        
        setOtp(e);
        formik.handleChange('otp')(e);
        
    }
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const navigate = useNavigate();
    const { search } = useLocation();
    let inputRef_em = useRef();
    let inputRef_emcp = useRef();

    const email = new URLSearchParams(search).get("email");
    const refer = new URLSearchParams(search).get("refer");

    const [showReferral, setshowReferral] = useState(false);
    const [showRegister, setshowRegister] = useState(true);
    const [isLoading, setisLoading] = useState(false);
    const [phoneno, setphoneno] = useState("");
    const [phoneno_err, setphonenoerr] = useState("");
    const [tabname, setTabname] = useState("email");
    const [timer_resendOtp, setTimer_resendOtp] = useState(0);

    useEffect(() => {
        timer_resendOtp > 0 &&
            setTimeout(() => setTimer_resendOtp(timer_resendOtp - 1), 1000);
    }, [timer_resendOtp]);

    const HaveReferralCode = () => {
        setshowReferral(!showReferral);
    };

    useEffect(() => {
        if (refer) {
            setshowReferral(!showReferral);
        }
    }, [refer]);

    const tabNameChange = async (val = "email") => {
        if (tabname != val) {
            setshowRegister(true);
            setTabname(val);
        }
    };

    const registerSubmit = async (values) => {
        const payload = Object.assign(values, {
            confirmPassword: values.password,
        });
        const params = {
            url: `${Config.V1_API_URL}user/register`,
            method: "POST",
            data: payload,
        };
        setisLoading(true);
        const response = await makeRequest(params);
        setisLoading(false);
        let type = "error";
        if (response.status) {
            type = "success";
            setshowRegister(false);
            if (response.type) {
                navigate("/login");
            }
            setTimer_resendOtp(Config.timer.resendOtp);
        }
        toast({ type, message: response.message });
    };

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: email ?? "",
            phoneno: "",
            password: "",
            confirmPassword: "",
            country: "",
            name: "",
            otp: "",
            referralId: refer ?? "",
            terms: false,
        },
        validationSchema: validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: async (values) => {
            values.phoneno = '+' + phoneno;
            let phn = '+' + phoneno;
            if (phn == "" || typeof phn == undefined) {
                setphonenoerr("Enter your phone number");
                return false;
            }
            const phoneDetail = phone(phn, { country: "" });

            // if(validator.isMobilePhone(phoneno) === false){
            //   setphonenoerr("Invalid phone number, Please enter correct phone number");
            //   return false;
            // }
            if (phoneDetail.isValid === false) {
                setphonenoerr(
                    "Invalid phone number, Please enter correct phone number"
                );
                return false;
            }
            setphonenoerr("");

            values.phonecode = phoneDetail.countryCode ? phoneDetail.countryCode : "";
            values.country = phoneDetail.countryIso3
                ? phoneDetail.countryIso3
                : phoneDetail.countryIso2;
            registerSubmit(values);
        },
    });


    useEffect(() => {
        // if ((phoneno == "") || (typeof phoneno == "undefined") || (typeof phoneno == undefined)) {
        //   // setphonenoerr("Enter your phone number");
        // } else if(validator.isMobilePhone(phoneno) === false){
        //   setphonenoerr("Invalid phone number, Please enter correct phone number");
        // }
        // else {
        //   setphonenoerr("");
        // }
    }, [phoneno]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };


    return (
        <section className='bg-register'>
            <div className='container'>
                <div className="d-flex justify-content-center align-items-center py-3  flex-column flex-lg-row">
                    <div className="register-intro w-100">
                        <h1>The Most <span>Reliable</span><br /><Typography component='h3' className='register-intro-h1'>Cryptocurrency Exchange</Typography></h1>
                        <Typography className="pt-3 pb-2 register-intro-p" component='h2'>Get Started with the easiest and most
                            secure platform to buy, sell. trade, and earn
                            cryptocurrencies</Typography>
                    </div>

                    {
                        showRegister ?
                            <div className='w-100 mx-auto bg-register-card '>
                                <div className='make-register-box mx-auto pb-1'>
                                    <h4 className='pt-2'><strong>Register</strong></h4>

                                    <div className='mx-auto text-center mb-4 text-light'>
                                        <ThemeProvider theme={theme}>
                                            <FormControl sx={{ width: '100%', }} variant="standard" fullWidth>
                                                <InputLabel htmlFor="standard-adornment-password" sx={{
                                                    color: 'white',
                                                    fontSize: (formik.values.name?.length > 0) ? "1.5rem" : "1.2rem",

                                                }}>User Name</InputLabel>
                                                <Input
                                                    id="standard-adornment-password"
                                                    type={'text'}
                                                    fullWidth
                                                    name="name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.name &&
                                                        Boolean(formik.errors.name)
                                                    }
                                                    helperText={
                                                        formik.touched.name && formik.errors.name
                                                    }
                                                    sx={{
                                                        color: '#dadada',

                                                        '& .MuiInputBase-input': {
                                                            // fontSize: "1.2rem",
                                                            marginTop: "10px"
                                                        },
                                                    }}
                                                />
                                                {formik.errors.name ? (
                                                    <small className="text-red text-start pt-2">
                                                        {formik.errors.name} <br />
                                                    </small>
                                                ) : null}
                                            </FormControl>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                label="Email-ID"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.email && Boolean(formik.errors.email)
                                                }
                                                helperText={formik.touched.email && formik.errors.email}
                                                sx={{
                                                    color: 'white', marginTop: 3, marginBottom: 3,
                                                    '& .MuiInputLabel-root': {
                                                        fontSize: "1.5rem",
                                                        color: 'white',
                                                    },
                                                    // '& .MuiInputBase-input': {
                                                    //     fontSize: "1.2rem",
                                                    // },

                                                    '& .MuiInputBase-root': {
                                                        marginTop: '25px',
                                                        color: '#dadada',
                                                    }
                                                }}

                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle sx={{ color: 'white' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                fullWidth
                                            />
                                            <PhoneInput
                                                containerClass="container"
                                                containerStyle={{ width: "100%", borderBottom: "1px solid white", borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: "0", padding: "5px 0px", }}
                                                inputStyle={{ background: "transparent", color: "white", border: "none", outline: "none" }}
                                                buttonStyle={{ height: "35px", marginTop: "5px" }}
                                                dropdownStyle={{ textAlign: "left" }}
                                                searchStyle={{ color: "white" }}
                                                required
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                international
                                                country={'in'}
                                                className='form-control handleModalBody'
                                                countryCallingCodeEditable={true}
                                                value={formik.values.phoneno}
                                                onChange={setphoneno}
                                                placeholder="Enter phone number"
                                                autoComplete="off"
                                                onBlur={formik.handleBlur}

                                            />
                                            {phoneno_err ? (
                                                <small className="text-red text-start pt-2">
                                                    {phoneno_err} <br />
                                                </small>
                                            ) : null}

                                            <FormControl sx={{ width: '100%', marginTop: 2 }} variant="standard" fullWidth>
                                                <InputLabel htmlFor="standard-adornment-password" sx={{
                                                    color: 'white',
                                                    fontSize: (formik.values.password?.length > 0) ? "1.5rem" : "1.2rem",

                                                }}>Password</InputLabel>
                                                <Input
                                                    id="standard-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    fullWidth
                                                    name="password"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.password &&
                                                        Boolean(formik.errors.password)
                                                    }
                                                    helperText={
                                                        formik.touched.password && formik.errors.password
                                                    }
                                                    ref={inputRef_em}
                                                    sx={{
                                                        color: '#dadada',

                                                        '& .MuiInputBase-input': {
                                                            // fontSize: "1.2rem",
                                                            marginTop: "10px"
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: 'white' }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                {formik.errors.password ? (
                                                    <small className="text-red text-start pt-2">
                                                        {formik.errors.password} <br />
                                                    </small>
                                                ) : null}
                                            </FormControl>
                                            <FormControl sx={{ width: '100%', marginTop: 2 }} variant="standard" fullWidth>
                                                <InputLabel htmlFor="standard-adornment-password" sx={{
                                                    color: 'white',
                                                    fontSize: (formik.values.confirmpassword?.length > 0) ? "1.5rem" : "1.2rem",

                                                }}>Confirm Password</InputLabel>
                                                <Input
                                                    id="standard-adornment-password"
                                                    type={showPassword2 ? 'text' : 'password'}
                                                    fullWidth
                                                    name="confirmpassword"
                                                    value={formik.values.confirmpassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.confirmpassword &&
                                                        Boolean(formik.errors.confirmpassword)
                                                    }
                                                    helperText={
                                                        formik.touched.confirmpassword &&
                                                        formik.errors.confirmpassword
                                                    }
                                                    ref={inputRef_emcp}
                                                    sx={{
                                                        color: '#dadada',

                                                        '& .MuiInputBase-input': {
                                                            // fontSize: "1.2rem",
                                                            marginTop: "10px"
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword2}
                                                                onMouseDown={handleMouseDownPassword2}
                                                            >
                                                                {showPassword2 ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: 'white' }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                {formik.errors.confirmpassword ? (
                                                    <small className="text-red text-start pt-2">
                                                        {formik.errors.confirmpassword} <br />
                                                    </small>
                                                ) : null}
                                            </FormControl>
                                            <FormControl sx={{ width: '100%', marginTop: 2 }} variant="standard" fullWidth>
                                                <InputLabel htmlFor="standard-adornment-password" sx={{
                                                    color: 'white',
                                                    fontSize: (formik.values.referralId?.length > 0) ? "1.5rem" : "1.2rem",

                                                }}>Referral ID</InputLabel>
                                                <Input
                                                    id="referralId"
                                                    type="text"
                                                    fullWidth
                                                    name="referralId"
                                                    label="Referral Code"
                                                    value={formik.values.referralId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.referralId &&
                                                        Boolean(formik.errors.referralId)
                                                    }
                                                    helperText={
                                                        formik.touched.referralId && formik.errors.referralId
                                                    }
                                                    sx={{
                                                        color: '#dadada',

                                                        '& .MuiInputBase-input': {
                                                            // fontSize: "1.2rem",
                                                            marginTop: "10px"
                                                        },
                                                    }}
                                                />
                                                {formik.errors.referralId ? (
                                                    <small className="text-red text-start pt-2">
                                                        {formik.errors.referralId} <br />
                                                    </small>
                                                ) : null}
                                            </FormControl>
                                            <div style={{ paddingTop: "5px", paddingBottom: "20px", }} className='text-start'>


                                                <label
                                                    className="form-check-label mt-3 password-text-33 pb-2"
                                                    for="flexCheckDefault"

                                                >
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        id="terms"
                                                        name="terms"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.touched.terms && Boolean(formik.errors.terms)
                                                        }
                                                        helperText={
                                                            formik.touched.terms && formik.errors.terms
                                                        }
                                                    />{" "}
                                                    I have read and Agree to {Config.SITENAME}'s{" "}
                                                    <a
                                                        className="text-col"
                                                        href={termsandconditions}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    // download
                                                    >
                                                        Terms of Services
                                                    </a>{" "}
                                                    and{" "}
                                                    <a
                                                        className="text-col "
                                                        href={termsandconditions}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    // download
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                </label>
                                                <br />
                                                {formik.errors.terms ? (
                                                    <small className="text-red text-start pt-2">
                                                        {formik.errors.terms}
                                                    </small>
                                                ) : null}
                                            </div>
                                        </ThemeProvider>
                                        <div className='mx-auto text-center'>
                                            <button className="btn-register w-50" disabled={isLoading} type='button' onClick={() => { formik.handleSubmit(); }}>Register</button>
                                        </div>

                                    </div>
                                </div></div> :
                            <div className='w-100 mx-auto bg-otp-card'>
                                <div className='make-otp-box mx-auto pb-1'>
                                    <h4 className='pt-2'><strong>Enter OTP Code</strong></h4>

                                    <div className='mx-auto text-center my-4 text-light'>
                                        <div className='mx-auto text-center mt-3 mb-4'>
                                            <p className='text-start mb-3' style={{ color: "#C8C8C8", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>OTP Was Send to {showEmail(formik.values.email)}</p>
                                            <OtpInput
                                                id="otp"
                                                name="otp"
                                                value={otp}
                                                onChange={(e) => emailOtp(e)}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.otp && Boolean(formik.errors.otp)
                                                }
                                                helperText={formik.touched.otp && formik.errors.otp}
                                                numInputs={6}
                                                inputType="number"
                                                shouldAutoFocus={true}
                                                containerStyle={{ width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                                                inputStyle={{ borderRadius: "40px", border: "none", width: "100%", height: window.innerWidth < 500 ? "45px" : "52px", outline: "none", fontWeight: "600", fontSize: "22px", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', textAlign: "center", background: "#7B7B7B", color: "white" }}
                                                renderSeparator={<span className='mx-2'></span>}
                                                renderInput={(props) => <input {...props} />}
                                            />


                                            <p className='text-end text-danger pt-3' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', cursor: 'pointer' }}
                                                disabled={
                                                    isLoading || timer_resendOtp > 0 ? true : false
                                                }
                                                onClick={() =>
                                                    registerSubmit(formik.values, "resendOTP")
                                                }
                                            >Resend OTP {timer_resendOtp > 0 ? (
                                                <span className="timeLeft">
                                                    ({timer_resendOtp})
                                                </span>
                                            ) : (
                                                ""
                                            )}</p>
                                            {formik.errors.otp ? (
                                                <small className="invalid-otp error">
                                                    {formik.errors.otp} <br />
                                                </small>
                                            ) : null}



                                        </div>

                                        <div className='mx-auto text-center'>
                                            <button className="btn-register w-50" type='button' onClick={() => formik.handleSubmit()} disabled={isLoading}>Submit</button>
                                        </div>

                                    </div>
                                </div>
                                <div className='bg-otp-card-2'></div>
                            </div>

                    }
                </div>
            </div>
        </section >
    );
};

export default Registration;