import React from 'react'
import { Typography, Grid, Box } from '@mui/material'
const data = [
  {
    img: '/Ellipse-1.png',
    text: 'Trade with confidence on our high-performance exchange, which offers top-notch securityfeatures and 24/7 monitoring to ensure the integrity of your trades.',
  },
  {
    img: '/Ellipse-2.png',
    text: 'Our exchange offers a suite of advanced trading tools and features to help you maximize your profits, including customizable charting, technical analysis indicators, and more',
  },
  {
    img: '/Ellipse-3.png',
    text: 'Protect your cryptocurrencies with the strongest wallet security available, including multi-factor authentication, cold storage, and advanced encryption protocols..',
  },
  {
    img: '/Ellipse-4.png',
    text: 'Get instant access to our platform and enjoy round-the-clock support from our team of experts, who are always available to help you with your trading needs.',
  },
]

const FeaturesOfUnitic = () => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        paddingTop:'36px'
      }}
      className='mb-5'
    >
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontWeight: 'bold',
          fontSize: {xs:24,sm:28, md:30, xl:32},
          my: 2,
        }}
      >
        Features Of Unitic{' '}
      </Typography>
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontSize: 14,
          marginBottom: '30px',
        }}
      >
        Check Pro Features of Unitic Exchange
        <br />
        Choose your exchange wisely
      </Typography>

      {/* Grid */}
      <Grid container spacing={2}>
        {data.map((i, index) => {
          return (
            <Grid
              data-aos={index === 0 || index === 2 ? 'fade-up' : 'fade-down'}
              data-aos-duration='1500'
              item
              xs={12}
              lg={6}
            >
              <Box
                sx={{
                  bgcolor: ' #F9F9F9',
                  borderRadius: 3,
                  p: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: 5,
                  ' &:hover': {
                    bgcolor: '#E0FFE9',
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='180'
                    height='180'
                    viewBox='0 0 180 180'
                    fill='none'
                  >
                    <circle
                      cx='90'
                      cy='90'
                      r='90'
                      fill='url(#paint0_linear_4542_3427)'
                      fill-opacity='0.2'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_4542_3427'
                        x1='90'
                        y1='0'
                        x2='90'
                        y2='180'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#21CA50' />
                        <stop offset='1' stop-color='#B4EBB5' />
                      </linearGradient>
                    </defs>
                  </svg>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <img height={140} src={i.img} alt='' />
                  </Box>
                </Box>

                <Typography
                  sx={{
                    maxWidth: '300px',
                    textAlign: 'justify',
                  }}
                >
                  {i.text}
                </Typography>
              </Box>{' '}
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default FeaturesOfUnitic
