import React, { useEffect } from "react";
import "../assets/style.css";
import NavbarOne from "./separate/NavbarOne";
import Footer from "./separate/Footer";
import { useNavigate} from "react-router-dom";
import PageTitleDes from "../core/support/PageTitleDes";
import MarketPage from "../PagesV2/MarketPage/MarketPage";
import { Helmet } from 'react-helmet-async';

export default function Withdraw(props) {

    const navigate = useNavigate();

    async function backButton() {
        try {
            const length = window.history.length;
            if (length > 0) {
                window.history.back();
            } else {
                navigate("/");
            }
        } catch (err) { }
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }, [])
    return (
        <div>
            <div className="min-vh-100 bg-default  text-white bg-particular">
                <PageTitleDes title={`Buy Bitcoin & Cryptocoin in India at the best price on UniticExchange`} description={`Buy BTC to INR, UTI Coin & Other Cryptocoin in India at the price on uniticexchange`} />
                <Helmet>
                    <link rel="canonical" href="https://uniticexchange.com/markets" />
                    <meta name="description" content='btc price' />
                    <meta name="description" content='ethereum price' />
                    <meta name="description" content='market capitalization in cryptocurrency' />
                    <meta name="description" content='dogecoin price' />
                    <meta name="description" content='btc price prediction' />
                    <meta name="description" content='FIU Registered Crypto Exchange' />
                    <meta name="description" content='Indian best crypto exchange' />
                    <meta name="description" content='Indian crypto exchange' />
                    <meta name="description" content='Best crypto exchange in India for beginners' />
                    <meta name="description" content='Top Indian cryptocurrency exchanges' />
                </Helmet>
                <NavbarOne
                    setTheme={props.setTheme}
                    theme={props.theme}
                />
                <MarketPage />
            </div>
            <Footer />
        </div>
    );
}
