import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';

function createData(time, fundingInterval, fundingRate, markPrice) {
    return { time, fundingInterval, fundingRate, markPrice };
}
const dataRow = [
    { label: 'Symbol', value: 'BTCUSDT Perpetual', id: 1 },
    { label: 'Interval', value: '8h', id: 2 },
    { label: 'Time to Next Funding', value: '06:19:35', id: 3 },
    { label: 'Funding Rate', value: '0.0100%', id: 4 },
    { label: 'Interest Rate', value: '0.0100%', id: 5 },
    { label: '0.0100%', value: '0.0100%', id: 6 }
]
const rows = [
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
    createData('2024 -01-22 05:30', '8h', '0.010000%', '4140.03930230349'),
];
const commonStyle = {
    backgroundColor: 'transparent', color: '#FFF', fontFamily: 'Roboto', fontSize: '0.75rem', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', paddingBottom: '10px', boxShadow: 'none'
}
const styleHead = {
    background: '#282525', color: '#FFF', fontFamily: 'Roboto', fontSize: '0.75rem', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', border: 0, paddingBottom: '10px'
}
const StyledTableCellHead = styled(TableCell)(({ theme }) => (styleHead));
const StyledTableCellBody = styled(TableCell)(({ theme }) => (commonStyle));

const FundingHistory = () => {
    return (
        <div>
            <div className='row mb-3'>
                <div className='col-12 col-lg-4'>
                    <div className='pb-3 pt-2 d-flex justify-content-between align-items-center' style={{ gap: '10px' }}><h5 className='genText mb-0' style={{ fontSize: '1rem' }}>Rare Time  Funding Rate</h5><p className='genText mb-0' style={{ fontSize: '0.75rem' }}>{"View More >"}</p></div>

                    {dataRow?.map(data => <div className='d-flex justify-content-between align-items-center handleFundingHistoryLeft pb-2' key={data?.id}>
                        <p>{data?.label}</p><p>{data?.value}</p>
                    </div>)}
                </div>
                <div className='col-12 col-lg-8'>
                    <div className='pb-3 pt-3' ><h5 className='genText mb-0 ps-5' style={{ fontSize: '1rem' }}>Funding Rate: 0.010000%</h5></div>
                    <div className=' pt-2 ps-5'>
                       <div><p className='genText text-start ps-5 pb-3' style={{fontSize: '0.75rem', color: '#C8C8C8'}}>0.010000%</p>
                        <img alt='' src='/assets/dotdot.png' className='img-fluid'/></div>
                    </div>

                </div>
            </div>

            <div>
                <p className='genText' style={{ fontSize: '1rem' }}>Funding Rate History</p>
                <TableContainer component={Paper} sx={{
                    maxHeight: 275, '&::-webkit-scrollbar': {
                        display: 'none'  /* WebKit (Chrome, Safari, etc.) */
                    },
                    width: '100%', overflow: 'auto',
                    '&.MuiTableContainer-root': commonStyle
                }}>
                    <Table size="small" stickyHeader aria-label="a dense table" sx={{ minWidth: 600 }}>
                        <TableHead>
                            <TableRow sx={{ '& td, & th': { border: 0 } }}>
                                <StyledTableCellHead align="left">Time</StyledTableCellHead>
                                <StyledTableCellHead align="center">Funding Interval</StyledTableCellHead>
                                <StyledTableCellHead align="center">Funding Rate</StyledTableCellHead>
                                <StyledTableCellHead align="center">Mark Price</StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '& td, & th': { border: 0 } }}
                                >
                                    <StyledTableCellBody align="left">  {row?.time}</StyledTableCellBody>
                                    <StyledTableCellBody align="center">{row?.fundingInterval}</StyledTableCellBody>
                                    <StyledTableCellBody align="center">{row?.fundingRate}</StyledTableCellBody>
                                    <StyledTableCellBody align="center">{row?.markPrice}</StyledTableCellBody>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default FundingHistory;