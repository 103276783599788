import React, { useState } from 'react';
import '../../../../assets/style.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const dataRow = [
    { label: 'Min. trade Amount', value: '0.00 BTC', id: 1 },
    { label: 'Min. price Movement', value: '0.10 USDT', id: 2 },
    { label: 'Price precision', value: '49.78%', id: 3 },
    { label: 'Limit Buy Order Price Cap Ratio', value: '5%', id: 4 },
    { label: 'Limit Sell Order Price Floor Ratio', value: '5%', id: 5 },
    { label: 'Max. Market Order Amount', value: '120 BTC', id: 6 },
    { label: 'Max. Limit Order Amount', value: '1000 BTC', id: 7 },
    { label: 'Max. Number of Open Orders', value: '200', id: 8 },
    { label: 'Min. Notional Value', value: 'Min. Notional Value', id: 9 },
    { label: 'Price Protection Threshols', value: '5%', id: 10 },
    { label: 'Liquid Clearance Sale', value: '1.25%', id: 11 },
    { label: 'Market Order Price Cap/Floor Ratio', value: '5%', id: 12 },
]
const TradingRules = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(() => newValue);
    };
    return (
        <div>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <div className='py-3' style={{ gap: '10px' }}><h5 className='genText mb-0' style={{ fontSize: '1.18rem' }}>BTCUSDT Perpetual</h5></div>
                    {dataRow?.map(data => <div className='d-flex justify-content-between align-items-center handleCoinInfoLeft pb-2' key={data?.id}>
                        <p>{data?.label}</p><p>{data?.value}</p>
                    </div>)}
                    <p className='genTex pt-3' style={{ fontSize: '0.875rem', color: '#c7c7c7' }}>Data is provided by cmc and is reference Only. It is Presented on this basic and docnot serve as any representation or guarantee</p>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='py-3'><h5 className='genText mb-0' style={{ fontSize: '1.18rem' }}>Links</h5></div>
                    <div className='handleTradingRulesRight'>
                        <p className='text-justify'>Bitcoin (BTC) is a peer-to-peer cryptocurrency that aims to function as a means of exchange that is independent of any central authority. BTC can be transferred electronically in a secure, verifiable, and immutable way.
                            Launched in 2009, BTC is the first virtual currency to solve the double-spending issue by timestamping transactions before broadcasting them to all of the nodes in the Bitcoin network. The Bitcoin Protocol offered a solution to the Byzantine Generals' Problem with a blockchain network structure, a notion first created by Stuart Haber and W. Scott Stornetta in 1991.
                            Bitcoin’s whitepaper was published pseudonymously in 2008 by an individual, or a group, with the pseudonym “Satoshi Nakamoto”, whose underlying identity has still not been verified.
                            The Bitcoin protocol uses an SHA-256d-based Proof-of-Work (PoW) algorithm to reach network consensus. Its network has a target block time of 10 minutes and a maximum supply of 21 million tokens, with a decaying token emission rate. To prevent fluctuation of the block time, the network's block difficulty is re-adjusted through an algorithm based on the past 2016 block times.
                            With a block size limit capped at 1 megabyte, the Bitcoin Protocol has supported both the Lightning Network, a second-layer infrastructure for payment channels, and Segregated Witness, a soft-fork to increase the number of transactions on a block, as solutions to network scalability.</p>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default TradingRules;