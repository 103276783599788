import React from 'react';
import './Referral.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { dateFormat, showEmail, showPhone } from '../../../core/helper/date-format';
import Loader from '../../../Component/Loader/Loader';

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "500",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));

const ReferralHistory = ({ referralData, loading }) => {
    return (
        <div className='my-2'>
            {loading ? <Loader /> :
                <TableContainer>
                    <Table sx={{ minWidth: window.innerWidth < 700 ? 700 : 100, justifyContent: 'center' }} aria-label="simple table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableHeadRow>Date</StyledTableHeadRow>
                                <StyledTableHeadRow>Email</StyledTableHeadRow>
                                <StyledTableHeadRow>phone</StyledTableHeadRow>
                                <StyledTableHeadRow>Commission</StyledTableHeadRow>
                                <StyledTableHeadRow>Remark</StyledTableHeadRow>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {referralData && referralData.commissionHistory && referralData.commissionHistory.length > 0 && referralData.commissionHistory.map((commission) => {
                                return (
                                    <TableRow key={commission.dateTime}>
                                        <StyledTableCell component="th" scope="row">{dateFormat(commission.dateTime)}</StyledTableCell>
                                        <StyledTableCell>{showEmail(commission.refUser.email)}</StyledTableCell>
                                        <StyledTableCell>{showPhone(commission.refUser.phoneno)}</StyledTableCell>
                                        <StyledTableCell>{commission.commissionAmount} {commission.currencyName}</StyledTableCell>
                                        <StyledTableCell>{commission.description}</StyledTableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <div className='text-center'>
                {referralData && referralData.commissionHistory && referralData.commissionHistory.length == 0 && !loading && <p className='color-white f-13 m-top-20'>No Records Found!</p>}
            </div>
        </div>
    );
};

export default ReferralHistory;