import React from "react";
import NavbarOne from "./separate/NavbarOne";
import Login from "../PagesV2/UserAuthentication/Login/Login";
import { Helmet } from "react-helmet-async";

export default function SignIn(props) {
  return (
    <div>

      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <Helmet>
      <link rel="canonical" href="https://uniticexchange.com/login" />
                </Helmet>
      <div className="bg-login d-flex justify-content-center align-items-center" style={{ minHeight: '91vh' }}>
        <Login props={props} />
      </div>

    </div>
  );
}
