import React, { useRef } from "react";
import "react-phone-number-input/style.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReactPasswordToggleIcon from "react-password-toggle-icon";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { makeRequest } from "../../../core/services/v1/request";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config/";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ThemeProvider, createTheme } from '@mui/material';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './popup.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const validationSchema = yup.object({
  oldPassword: yup
    .string('Enter your password')
    .required('Old Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  newPassword: yup
    .string('Enter your password')
    .required('New Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmNewPassword: yup
    .string('Please enter the Confirm Password')
    .required('Confirm Password is required'),
});
const ChangePasswordPopUp = ({ openPassword, setOpenPassword }) => {

  const theme = createTheme({
    palette: {
      mode: 'dark',
      // primary: {
      //     main: '#ffffff !important', // Change this to your desired border color
      // },
      // default: {
      //     main: '#ffffff !important', // Change this to your desired default border color
      // },
      // text: {
      //     primary: '#ffffff !important', // Set the primary text color to white
      // },
    },
  });
  const handleClickOpen = () => {
    setOpenPassword(true);
  };

  const handleClose = () => {
    setOpenPassword(false);
  };
  let inputRef1 = useRef();
  let inputRef2 = useRef();
  let inputRef3 = useRef();
  const showIcon = () => <AiOutlineEyeInvisible aria-hidden="true" />;
  const hideIcon = () => <AiOutlineEye aria-hidden="true" />;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      }
      let type = 'error';
      if (values.newPassword == values.confirmNewPassword) {
        if (values.newPassword == values.oldPassword) {
          toast({ type, message: "New password cannot be the same as old password." });
        } else {
          const params = {
            url: `${Config.V1_API_URL}user/changePassword`,
            method: 'POST',
            data: payload
          }
          const response = (await makeRequest(params));
          let type = 'error';
          if (response.status) {
            type = "success";
            // navigate("/my/profile");
            handleClose();
          }
          toast({ type, message: response.message });
        }
      } else {
        toast({ type, message: "New Password and Confirm New Password doesn't Match" });
      }
    },
  });
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={openPassword}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          fullWidth={true}
          maxWidth={'xs'}
          aria-describedby="alert-dialog-slide-description"
          className='mx-auto'
        ><form onSubmit={formik.handleSubmit}>
            <DialogTitle>{"Change Password"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <p className='label-text mb-2'>Old Password</p>
                <InputGroup className="mb-1 rounded" style={{ backgroundColor: '#7B7B7B' }} size='sm'>
                  <Form.Control
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className='form-popup me-2'
                    ref={inputRef1}
                    name="oldPassword"
                    id="oldPassword"
                    type="password"
                    autoComplete='off'
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                  />
                  <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }} ><ReactPasswordToggleIcon
                    inputRef={inputRef1}
                    showIcon={showIcon}
                    hideIcon={hideIcon}
                  /></InputGroup.Text>
                </InputGroup>
                {formik.errors.oldPassword ? <p className='text-danger mb-2'><small>{formik.errors.oldPassword}</small> </p> : null}

                <p className='label-text mb-2'>New Password</p>
                <InputGroup className="mb-1 rounded" style={{ backgroundColor: '#7B7B7B' }} size='sm'>
                  <Form.Control
                    placeholder=""
                    ref={inputRef2}
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    autoComplete='off'
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className='form-popup me-2'
                  />
                  <InputGroup.Text id="basic-addon2" className='text-light ' style={{ background: "#7B7B7B", border: 'none' }}><ReactPasswordToggleIcon
                    inputRef={inputRef2}
                    showIcon={showIcon}
                    hideIcon={hideIcon}
                  /></InputGroup.Text>
                </InputGroup>
                {formik.errors.newPassword ? <p className='text-danger mb-2'><small>{formik.errors.newPassword}</small> </p> : null}
                <p className='label-text mb-2'>Confirm New password</p>
                <InputGroup className="mb-1 rounded" style={{ backgroundColor: '#7B7B7B' }} size='sm'>
                  <Form.Control
                    placeholder=""
                    ref={inputRef3}
                    type="password"
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    autoComplete='off'
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                    helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className='form-popup me-2'
                  />
                  <InputGroup.Text id="basic-addon2" className='text-light' style={{ background: "#7B7B7B", border: 'none' }}><ReactPasswordToggleIcon
                    inputRef={inputRef3}
                    showIcon={showIcon}
                    hideIcon={hideIcon}
                  /></InputGroup.Text>
                </InputGroup>
                {formik.errors.confirmNewPassword ? <p className='text-danger mb-2'><small> {formik.errors.confirmNewPassword}</small> </p> : null}
              </DialogContentText>
            </DialogContent>
            <DialogActions className='mx-auto'>
              <button className='btn-profile shadow px-5 fs-6 py-2 mb-3' style={{ width: 'auto', height: "auto", justifyContent: 'center' }} type="submit">Confirm</button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </div >
  );
};

export default ChangePasswordPopUp;