import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import { HiArrowNarrowRight } from "react-icons/hi";

import NavbarOne from "./separate/NavbarOne";
import Footer from "./separate/Footer";
import TransactionhistoryCrypto from "./separate/transaction-history-crypto";
import TransactionhistoryFiat from "./separate/transaction-history-fiat";
import TransactionhistoryTransfer from "./separate/transaction-history-transfer";
import '../assets/style.css';

export default function Transactionhistory(props) {

  let { tabName } = useParams();

  const [listTab, setListTab] = useState("crypto");

  useEffect(() => {
    if (tabName) {
      setListTab(tabName.toLowerCase());
    }
  }, [tabName]);

  return (
    <div >
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner bg-default  text-white bg-particular min-vh-100" style={{ minHeight: '70vh' }}>
        <div className="pt-5 pb-3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6">
                <GoChevronLeft className="deposit-back-button-icon" />
                <span className="deposit-text-1">Transactions History</span>
              </div>
              <div className="col-6 text-end">
                <span className="deposit-text-1">Deposit Fiat <HiArrowNarrowRight /></span>
              </div>
            </div>
          </div>
        </div>

        <div className="transaction-history-second-section py-3 ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="transaction-history-tabs-section">
                  <ul class="nav nav-pills " id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation" onClick={() => { setListTab("crypto"); }}>
                      <button
                        className={"nav-link " + (listTab === 'crypto' ? 'active' : '')}
                        id="pills-crypto-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-crypto"
                        type="button"
                        role="tab"
                        aria-controls="pills-crypto"
                        aria-selected="true"
                      >
                        Crypto
                      </button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => { setListTab("fiat"); }}>
                      <button
                        className={"nav-link " + (listTab === 'fiat' ? 'active' : '')}
                        id="pills-fiat-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-fiat"
                        type="button"
                        role="tab"
                        aria-controls="pills-fiat"
                        aria-selected="false"
                      >
                        Fiat
                      </button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => { setListTab("transfer"); }}>
                      <button
                        className={"nav-link " + (listTab === 'transfer' ? 'active' : '')}
                        id="pills-transfer-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-transfer"
                        type="button"
                        role="tab"
                        aria-controls="pills-transfer"
                        aria-selected="false"
                      >
                        Transfer
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          listTab && <>
            {listTab == "crypto" &&
              <TransactionhistoryCrypto
                tabName="tabName"
              />}

            {listTab == "fiat" &&
              <TransactionhistoryFiat
                tabName="tabName"
              />}
            {listTab == "transfer" &&
              <TransactionhistoryTransfer
                tabName="tabName"
              />}
          </>
        }

      </div>
      <Footer />
    </div>
  );
}
