import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from 'react-icons/ai';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../../core/helper/cookie";
import { toast } from "../../../core/lib/toastAlert";
import { showPairName } from '../../../core/helper/date-format';
import '../../../assets/style.css';
import { useRef } from "react";
let initialFavs = getCookie("userPairs");

const useDisplayProperty = (elementId, active2, setActive2) => {
  useEffect(() => {
    let savedDisplay = null;

    const checkDisplayProperty = () => {
      const element = document.getElementById(elementId);

      const computedStyle = window.getComputedStyle(element);
      const currentDisplay = computedStyle.getPropertyValue('display');

      if (currentDisplay !== savedDisplay) {
        savedDisplay = currentDisplay;
        // console.log(`Display property changed to: ${currentDisplay}`);
        if (currentDisplay === "block") {
          setActive2(() => true);
        }
        else if (currentDisplay === "none") {
          setActive2(() => false);
        }
      }


      requestAnimationFrame(checkDisplayProperty);
    };

    requestAnimationFrame(checkDisplayProperty);

    return () => {
      savedDisplay = null;
    };
  }, [elementId]);
};

export default function PairListAndSearch(props) {
  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);
    props.setLoader(()=> true)
  };

  useEffect(() => {
    $(".spot-page-pair-select-dropdown .dropdown-menu").click(function (e) {
      e.stopPropagation();
    })
  }, []);
  // useDisplayProperty('dropdown-spot', isActive2, setActive2);


  const navigate = useNavigate();
  const {
    decimalValue,
    pairDetails = {},
    marketList: markets = [],
    coindcxDecimalPairState,
    coindcxDecimal
  } = props;


  const [favPairsList, setFavPairsList] = useState([]);
  const [favPairs, setFavPairs] = useState(getCookie("userPairs"));
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedTabCurrency, setSelectedTabCurrency] = useState("");
  const [listMarkets, setListMarkets] = useState([]);
  // const [toCurrencySymbolFinal, settoCurrencySymbolFinal] = useState("");
  const [searchVal, setsearchVal] = useState("");
  const [selectedMarkets, setSelectedMarkets] = useState((props.marketList && props.marketList.length > 0) ? props.marketList[0].pairs : []);

  useEffect(() => {
    if (initialFavs != favPairs) {
      if (selectedCurrency == '') {
        setPairs('');
      }
    }
  }, [favPairs]);

  function checkFavourite(pairName) {
    const markedPairs = getCookie("userPairs");
    if (markedPairs != null && markedPairs != '') {
      let favPairs = markedPairs.split(',');
      if (favPairs.indexOf(pairName) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  function setFavourite(pairName) {
    const markedPairs = getCookie("userPairs");
    let favPairsNew = [];
    if (markedPairs != null && markedPairs != '') {
      favPairsNew = markedPairs.split(',');
    }
    if (favPairsNew.indexOf(pairName) >= 0) {
      favPairsNew.splice(favPairsNew.indexOf(pairName), 1);
      toast({ type: 'success', message: 'Favourite Pair Removed Successfully!' });
    } else {
      favPairsNew.push(pairName);
      toast({ type: 'success', message: 'Favourite Pair Added Successfully!' });
    }
    setNewFavList(favPairsNew);
    setFavPairs(favPairsNew.join(','));
    setCookie('userPairs', favPairsNew.join(','))
  }

  function setCurrency(currency) {
    // console.log("setCurrency:", currency, selectedCurrency)
    if (currency != selectedCurrency) {
      setSelectedCurrency(currency);
      setPairs(currency);
    }
  }

  function setPairs(currency) {
    if (currency != '') {
      markets.map((item) => {
        if (item.currency == currency) {
          setSelectedMarkets(item.pairs);
        }
      });
    } else {
      const markedPairs = getCookie("userPairs");
      if (markedPairs != null && markedPairs != '') {
        let favouritePairs = [];
        let favPairs = markedPairs.split(',');
        markets.map((item) => {
          item.pairs.map((item1) => {
            if (favPairs.indexOf(item1.pair) >= 0) {
              favouritePairs.push(item1);
            }
          });
        });
        setSelectedMarkets(favouritePairs);
      } else {
        setSelectedMarkets([]);
      }
    }
  }

  useEffect(() => {

    if (props.toCurrencySymbol != selectedCurrency && selectedCurrency == "") {
      // console.log(props.toCurrencySymbol, "toCurrencySymbol");
      setSelectedCurrency(props.toCurrencySymbol);
    }
  }, [props]);

  function setNewFavList(favPairsUser = []) {
    if (selectedCurrency == "fav") {
      let newMarketList = []
      for (let i = 0; i < markets.length; i++) {
        const marketsPairGrp = markets[i].pairs;
        // console.log({ marketsPairGrp });
        for (let j = 0; j < marketsPairGrp.length; j++) {
          const marketsRow = marketsPairGrp[j];
          const favPairChk = favPairsUser.indexOf(marketsRow.pair);
          if (favPairChk > -1) {
            newMarketList.push(marketsRow);
          }
        }
      }
      setListMarkets(newMarketList);
    }
  }

  useEffect(() => {
    // console.log(selectedCurrency, "toCurrencySymbol");
    if (selectedCurrency) {
      if (selectedCurrency == "fav") {
        let favPairsUser = favPairs ? favPairs.split(',') : [];
        setNewFavList(favPairsUser);
      }
      else {
        const idx = markets.findIndex(elem => elem.currency == selectedCurrency);

        if (idx > -1) {
          setListMarkets(markets[idx].pairs);
        }
        else {
          setListMarkets([]);
        }
      }
    }
  }, [selectedCurrency]);

  const {
    fromCurrency = {},
    toCurrency = {}
  } = pairDetails;

  const {
    siteDecimal: siteDecimal_from = 2
  } = fromCurrency;

  const {
    siteDecimal: siteDecimal_to = 2
  } = toCurrency;
  // console.log(listMarkets);
  return (
    <div>
      <div>
        <div className={`sroll-effects-stylings-css trading-page-top-section-1 align-items-center ps-2`}>
          <div className=" trading-page-section-1">
            <div className="spot-page-pair-select-dropdown">
              <div className="dropdown">
                <div className="dropdown1">
                  <button className="dropbtn1" onClick={ToggleClass} >
                    {pairDetails && pairDetails.pair && showPairName(pairDetails.pair)}
                    {isActive === true
                      ? <FiChevronUp className="ms-2" />
                      : <FiChevronDown className="ms-2" />}
                  </button>
                  <div className={isActive === false ? "dropdown-content1" : "dropdown-content1 dropdown-content1-onlick"} id="dropdown-spot">
                    <span>
                      <div className="row paired-trade-dropdown-tabs px-3 pt-3 g-4">
                        <div className="col-7 paired-trade-dropdown-tabs-1 justify-content-between">
                          <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            {markets && markets.length > 0 && markets.map((market, idx) => {
                              if (market.currency === 'UTI') return null
                              return (
                                <li
                                  key={idx}
                                  // className="nav-item"
                                  role="presentation"
                                  onClick={() => setCurrency(market.currency)}
                                  className={"nav-item " + (market.currency === selectedCurrency && "color-yellow")}
                                >
                                  <button
                                    className={"nav-link " + (selectedCurrency == market.currency && " active")}
                                    id={"pills-" + market.currency + "-tab"}
                                    data-bs-toggle="pill"
                                    data-bs-target={"#pills-" + market.currency}
                                    type="button"
                                    role="tab"
                                    aria-controls={"pills-" + market.currency}
                                    aria-selected="false"
                                  >
                                    {market.currency}
                                  </button>
                                </li>
                              )
                            }
                            )
                            }
                          </ul>
                        </div>
                        <div className="col-6 col-sm-4 paired-trade-dropdown-tabs-1 ms-2">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control p-0"
                              placeholder="S e a r c h . . ."
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={searchVal}
                              onChange={
                                (e) => {
                                  // console.log(e.target.value)
                                  setsearchVal(e.target.value);
                                }
                              }
                            />
                            <span className="input-group-text p-0" id="basic-addon1"><AiOutlineSearch color="white" /></span>
                          </div>
                        </div>
                      </div>
                      <div className="paired-trade-dropdown-table mt-3">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th scope="col">Pair</th>
                              <th scope="col">Last Price</th>
                              <th scope="col">Change</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              listMarkets && listMarkets.length > 0 && listMarkets.map((market, i) => {
                                const searchValue = (searchVal && searchVal != "") ? searchVal.toUpperCase() : "";
                                if (market && market.pair) {
                                  const pairNameShow = showPairName(market.pair);
                                  const coindcxDecimalSingle = coindcxDecimal[(market.pair).split('_').join('')];
                                  // console.log(market.pair)
                                  if ((searchValue !== "" && pairNameShow.indexOf(searchValue) > -1) || searchValue === "") {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <span className="curPointer" onClick={() => { navigate('/spot/' + market.pair); ToggleClass(); }}>
                                            {pairNameShow}{" "}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="curPointer" onClick={() => { navigate('/spot/' + market.pair); ToggleClass(); }}>
                                            {decimalValue(market.price, coindcxDecimalSingle?.base_currency_precision || market.decimalValue)}
                                          </span>
                                        </td>
                                        <td>
                                          <span className={"curPointer " + (market.change >= 0 ? 'color-green' : 'color-darkpink')} onClick={() => { navigate('/spot/' + market.pair); ToggleClass(); }}>
                                            {(market.change > 0 ? '+' : '') + decimalValue(market.change, 2)}%
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  }
                                }
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" trading-page-section-1">
            <p className='trade-text-4'>

              {pairDetails && pairDetails.price ? props.decimalValue(pairDetails.price, (pairDetails?.pair == "SHIB_INR" || pairDetails?.pair == "BDX_USDT") ? 5 : coindcxDecimalPairState?.base_currency_precision || pairDetails.priceDecimal) : 0}
            </p>
          </div>
          <div className="trading-page-section-1">
            <p className='trade-text-6'>High</p>
            <p className='trade-text-7'>
              {pairDetails && pairDetails.pair != undefined ? props.decimalValue(pairDetails.high, (pairDetails?.pair == "SHIB_INR" || pairDetails?.pair == "BDX_USDT") ? 5 : coindcxDecimalPairState?.base_currency_precision || pairDetails.priceDecimal) : "-"}
            </p>
          </div>
          <div className="trading-page-section-1">
            <p className='trade-text-6'>Low</p>
            <p className='trade-text-7'>
              {pairDetails && pairDetails.pair != undefined ? props.decimalValue(pairDetails.low, (pairDetails?.pair == "SHIB_INR" || pairDetails?.pair == "BDX_USDT") ? 5 : coindcxDecimalPairState?.base_currency_precision || pairDetails.priceDecimal) : "-"}
            </p>
          </div>
          <div className=" trading-page-section-1">
            <p className='trade-text-6'>24h Volume({pairDetails.fromCurrency && pairDetails.fromCurrency.currencySymbol})</p>
            <p className='trade-text-7'>
              {pairDetails && pairDetails.volume_fromCur != undefined ? props.decimalValue(pairDetails.volume_fromCur, siteDecimal_from) : "-"}
            </p>
          </div>
          <div className=" trading-page-section-1">
            <p className='trade-text-6'>24h Volume({pairDetails.toCurrency && pairDetails.toCurrency.currencySymbol})</p>
            <p className='trade-text-7'>
              {pairDetails && pairDetails.volume != undefined ? props.decimalValue(pairDetails.volume, siteDecimal_to) : "-"}
            </p>
          </div>
          <div className=" trading-page-section-1 border-0">
            <p className='trade-text-6'>24h Change</p>
            <p className='trade-text-7 fc-r'>
              {pairDetails && pairDetails.pair != undefined ? <><span className={pairDetails.change >= 0 ? 'color-green' : 'color-darkpink'}>{(pairDetails.change >= 0 ? '+' : '') + props.decimalValue(pairDetails.change, 2)}%</span></> : "-"}
              {/* {pairDetails && pairDetails.pair != undefined ? <>{props.decimalValue(pairDetails.changeValue, 2)} <span className={pairDetails.change >= 0 ? 'color-green' : 'color-darkpink'}>{(pairDetails.change >= 0 ? '+' : '') + props.decimalValue(pairDetails.change, 2)}%</span></> : "-"} */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}