import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MarketTable from '../../Component/MarketTable/MarketTable';
import {
  getHomeMarkets
} from '../../core/services/all.api';
import { useState } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const FindYourNextTrading = () => {
  const [value, setValue] = React.useState(0);
  const [marketValues, setMarketValues] = useState({
    "BTC": [],
    "INR": [],
    "USDT": [],
  });
  const [marketCurrency, setMarketCurrency] = useState("INR");

  const handleChange = (event, newValue) => {
    setValue(() => newValue);
    setMarketCurrency(() => event.target?.firstChild?.data);
  };

  useEffect(() => {

    const getHomeMarketsCall = async () => {
      const payload = { marketCurrency };
      const resp = await getHomeMarkets({ payload });
      if (resp) {
        const {
          data = {},
          status = false
        } = resp;
        if (status) {
          let marketValuesCopy = Object.assign({}, marketValues);
          marketValuesCopy[payload.marketCurrency] = data;
          setMarketValues(() => marketValuesCopy);
        }
      }
    }
    getHomeMarketsCall();

  }, [marketCurrency]);

  return (
    <section className='overflow-hidden min-vh-50' >
      <div className=''>
        <Typography
          data-aos='fade-right'
          data-aos-duration='1500'
          sx={{
            fontWeight: 'bold',
            fontSize: {xs:24,sm:28, md:30, xl:32},
            my: 2,
          }}
          component='h2'
        >
          Find Your Next Trading
        </Typography>
        <div className='mb-0 mt-3'>
          <Box sx={{}}>
            <Box className='rounded-pill text-center mx-auto'
              sx={{
                bgcolor: '#38BA56',
                padding: { xs: '4px 5px', md: '5px 10px' },
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: { xs: '30px', md: '50px' },
                width: 'fit-content',
                height:{xs:'50px', md:'auto'}
              }} 
              data-aos="fade-left" data-aos-duration='1500'
              >
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                sx={{
                  '& .MuiTabs-indicator': {
                    display: 'none'
                  },
                  '& .MuiTab-root': {
                    color: '#fff',
                    minWidth: { xs: 2, md: '90px' },
                    borderRadius: 17,
                    minHeight:{xs:0,md:'50px'}
                  },
                  '& .MuiTab-root.Mui-selected': {
                    backgroundColor: '#fff',
                    color: '#38BA56',
                    fontWeight: 'bold',
                    minHeight:{xs:0,md:'50px'}
                  }

                }}
              >
                <Tab label="INR" {...a11yProps(0)} />
                <Tab label="USDT" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <MarketTable marketValues={marketValues} marketCurrency={marketCurrency} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MarketTable marketValues={marketValues} marketCurrency={marketCurrency} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </section>
  );
};

export default FindYourNextTrading;