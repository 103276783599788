(function () {
    const originalConsole = {
        log: console.log,
        debug: console.debug,
        info: console.info,
        warn: console.warn,
        error: console.error,
        clear: console.clear,
      };
    if (process.env.NODE_ENV !== 'development') {
        console.log = (...args) => { };
        console.debug = (...args) => { };
        console.info = (...args) => { };
        console.warn = (...args) => { };
        console.error = (...args) => { };
    }

    const intervalId = setInterval(() => {
        if (process.env.NODE_ENV !== 'development') {
            originalConsole.clear();
            originalConsole.log('\n'.repeat('100'));
        }
    }, 500);

    window.addEventListener('beforeunload', () => {
        clearInterval(intervalId);
    });
})();
