import React from "react";
import NavbarOne from "./separate/NavbarOne";

import { useContextData } from '../core/context/index';
import PasswordChange from "../assets/images/change password.png";
export default function ResetPassword(props) {
  const { siteSettings } = useContextData();

  return (
    <div className="bg-default min-vh-100 text-white bg-particular">
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="container  mt-5">
        <div className="row height-container">
          <div className="col-lg-6 my-auto">
            <h4>Change Password</h4>
            <img
              src={PasswordChange}
              className="img-f-Pass"
              alt="PasswordChange"
            />
          </div>
          <div className="col-lg-6 my-auto ">
            <center>
              <form>
                <label className="mt-3 password-text-33">Enter Password</label>
                <input
                  class="form-control input-dark mt-1 "
                  type="password"
                  name="password"
                />
                <label className="mt-3 password-text-33">Renter Password</label>
                <input
                  class="form-control mt-1 input-dark"
                  type="password"
                  name="password"
                />
                <button
                  type="button"
                  class="btn text-white btn-next w-100 mt-4"
                >
                  Generate OTP
                </button>
              </form>
            </center>
          </div>
          <div className="col-12">
            <nav class="navbar mt-1 ">
              <div class="container-fluid justify-content-center">
                <div className="col-lg-6 text-lg-end text-center">
                  <b className="password-text-33 fw-bold">{siteSettings && siteSettings.copyRights}</b>
                </div>
                <div className="col-lg-6 text-lg-start text-center">
                  <span className="ms-lg-5 password-text-33 fw-bold">Cookie Preferences</span>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
