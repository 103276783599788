import React, { useState, useEffect } from "react";
import {  useNavigate} from "react-router-dom";

import { useContextData } from "../../../core/context/index";
import {
    showNumber
} from "../../../core/helper/date-format";
import { getProfitList_API } from "../../../core/services/all.api";
import Config from "../../../core/config/";
import { makeRequest } from "../../../core/services/v1/request";
import Pagination from "react-responsive-pagination";
import "../../../pagination.css";
import { getOrderDetail_API } from "../../../core/services/all.api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './TradeHistory.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OnSpot from './OnSpot';
import TradeHistoryDetail from "../../../Pages/exchange-trade/separate/TradeHistoryDetail";

function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TradeHistory = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [tradeHistory, setTradeHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [traHisDetails, setTraHisDetails] = useState({});

    const [isloadingData, setIsloadingData] = useState({});
    const [currentPage_SpostHistory, setCurrentPage_SpostHistory] = useState(1);
    const [totalHistory, settotalHistory] = useState(0);
    const [recordsPerPage] = useState(25);

    const loadingChange = (data = {}) => {
        const dataCopy = Object.assign({}, isloadingData);
        dataCopy[data.key] = data.value;
        setIsloadingData(dataCopy);
    };

    const getProfitList_Call = async () => {
        setLoading(true);
        const apiData = {
            limit: currentPage_SpostHistory,
            offset: recordsPerPage * (currentPage_SpostHistory - 1),
        };
        loadingChange({ key: "getProfitList_API", value: true });
        const resp = await getProfitList_API(apiData);
        loadingChange({ key: "getProfitList_API", value: false });
        if (resp.status === true) {
            setTradeHistory(resp.list);
            settotalHistory(resp.count);
        } else {
            setTradeHistory([]);
        }
        setLoading(false);
    };

    useEffect(() => {
        getProfitList_Call();
    }, []);

    async function handlePageChange(currentPage_tradeHis) {
        setLoading(true);
        setCurrentPage_SpostHistory(currentPage_tradeHis);
        try {
            const data = {
                limit: recordsPerPage,
                offset: recordsPerPage * (currentPage_tradeHis - 1),
            };
            const params = {
                url: `${Config.V2_API_URL}trade/profit/list`,
                method: "POST",
                body: data,
            };
            const response = await makeRequest(params);
            if (response.status && response.list) {
                settotalHistory(response.count);
                setTradeHistory(response.list);
            }
        } catch (err) { }

        setLoading(false);
    }

    const getOrderDetail_call = async (orderId) => {
        setTraHisDetails({});
        const data = {
            payload: {
                orderId: orderId,
            },
        };
        const resp = await getOrderDetail_API(data);
        if (resp.status === true && resp.response) {
            setTraHisDetails(resp.response);
        }
    };

    function decimalValue(value, decimal) {
        return showNumber(parseFloat(value).toFixed(decimal));
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <section>
            <div className=' py-5'>
                <div>
                    <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Trade history</strong></h4>
                    {/* <p className='pt-2' style={{ color: '#C8C8C8' }}>Refer your Friends and earn 50% of the trading fee</p> */}
                </div>

                <div className='my-3'>
                    <Box sx={{ width: '100%' }}>
                        <Box >
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        background: 'linear-gradient(90.03deg, #10EDB6 0.02%, #4C62F7 100%);',
                                        borderRadius: "17px"
                                    },
                                    '& .MuiTab-root': {
                                        color: 'white',
                                        textTransform: "capitalize",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        lineHeight: "38px",
                                        fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                        fontStyle: "normal",
                                        padding: "auto"
                                    },
                                    '& .MuiTab-root.Mui-selected': {
                                        color: 'white',
                                        // fontWeight: "800"
                                    }

                                }}
                            >
                                <Tab label="On Spot" {...a11yProps(0)} />
                                {/* <Tab label="Instant Buy and sell" {...a11yProps(1)} /> */}
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <OnSpot traHisData={tradeHistory}
                                getOrderDetail_call={getOrderDetail_call}
                                decimalValue={decimalValue}
                                loading={loading}
                            />
                            <Pagination
                                className="spot_history_pagination"
                                total={Math.ceil(totalHistory / recordsPerPage)}
                                current={currentPage_SpostHistory}
                                onPageChange={(page) => handlePageChange(page)}
                            />
                        </TabPanel>
                        {/* <TabPanel value={value} index={1}>
                            <BuyAndSell />
                        </TabPanel> */}
                    </Box>
                </div>
                <TradeHistoryDetail
                    traHisDetails={traHisDetails}
                    decimalValue={decimalValue}
                />
            </div>
        </section>
    );
};

export default TradeHistory;