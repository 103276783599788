import React from "react";
import NavbarOne from './separate/NavbarOne';
import Footer from './separate/Footer';
import HomeV3 from "../PagesV2/HomeV3/HomeV3";

export default function Home(props) {
    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
            />
            <HomeV3 />
            <Footer />
        </div>
    )
}
