import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";

export default function AccordionUsage({ item,percentageFunc }) {
  return (
    <div>
      <Accordion
        sx={{
          "&.MuiAccordion-root": {
            backgroundColor: "transparent",
            color: "white",
          },
        }}
      >
        <AccordionSummary
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            "& .MuiAccordionSummary-content": {
              margin: 0,
              fontSize: "12px",
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0,
              fontSize: "12px",
            },
            "&.MuiAccordionSummary-root": {
              minHeight: 0,
              padding: 1,
              paddingX: 1.5,
              paddingTop: '1px',
              paddingBottom: "2px",
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="mb-0 text-center d-flex justify-content-between align-items-center">
              <p className="mb-0">Target 1</p>{" "}
              <Box
                sx={{
                  display: item?.t1?.t1Fill ? "flex" : "none",
                  alignItems: "center",
                  bgcolor: "#21CA50",
                  borderRadius: "50%",
                  padding: "1px",
                  ml: "2px",
                  border: "1px solid #000",
                  marginTop: "-1px",
                }}
              >
                <DoneIcon
                  sx={{
                    fontSize: "6px",
                    color: "#000",
                  }}
                />
              </Box>
            </div>
            <div className="mb-0 text-center d-flex justify-content-between align-items-center">
              <p className="mb-0">Target 2</p>{" "}
              <Box
                sx={{
                  display: item?.t2?.t2Fill ? "flex" : "none",
                  alignItems: "center",
                  bgcolor: "#21CA50",
                  borderRadius: "50%",
                  padding: "1px",
                  ml: "2px",
                  border: "1px solid #000",
                  marginTop: "-1px",
                }}
              >
                <DoneIcon
                  sx={{
                    fontSize: "6px",
                    color: "#000",
                  }}
                />
              </Box>
            </div>
            <div className="mb-0 text-center d-flex justify-content-between align-items-center">
              <p className="mb-0">Target 3</p>{" "}
              <Box
                sx={{
                  display: item?.t3?.t3Fill ? "flex" : "none",
                  alignItems: "center",
                  bgcolor: "#21CA50",
                  borderRadius: "50%",
                  padding: "1px",
                  ml: "2px",
                  border: "1px solid #000",
                  marginTop: "-1px",
                }}
              >
                <DoneIcon
                  sx={{
                    fontSize: "6px",
                    color: "#000",
                  }}
                />
              </Box>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "&.MuiAccordionDetails-root": {
              fontSize: "10px",
              padding: 1,
              pt: 0,
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-center w-100 text-center">
            <p className="mb-0 ps-1">
              {item?.t1?.t1P} <br />
              ({percentageFunc(item?.t1?.t1P, item?.ePrice,item?.leverage)}%)
            </p>
            <p className="mb-0 ps-1">
              {item?.t2?.t2P} <br />({percentageFunc(item?.t2?.t2P, item?.ePrice,item?.leverage)}%)
            </p>
            <p className="mb-0 ps-1">
              {item?.t3?.t3P} <br />({percentageFunc(item?.t3?.t3P, item?.ePrice,item?.leverage)}%)
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
