import React, { useState} from "react";
import "react-phone-number-input/style.css";
import * as yup from 'yup';
import { useFormik } from 'formik';

import { useContextData } from '../../../core/context/index'
import { makeRequest } from "../../../core/services/v1/request";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config/";
import { showEmail, showPhone } from '../../../core/helper/date-format';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ThemeProvider, createTheme } from '@mui/material';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './popup.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailPopUp = ({ openEmail, setOpenEmail }) => {
  const { myProfile } = useContextData();

  const handleClose = () => {
    setOpenEmail(false);
  };
  const validationSchema = yup.object().shape({
    newEmail: yup
      .string('Please enter your email')
      .required('Please enter your email'),
    newEmailOTP: yup
      .string('Please enter a valid email verification code.')
      .required('Please enter a valid email verification code.'),
    oldEmailOTP: (myProfile && myProfile.email !== "" && myProfile.email !== undefined)
      ?
      yup
        .string('Please enter a 6-digit verification code.')
        .required('Enter the 6-digit code')
      :
      yup.string(),
    oldPhonenoOTP: (myProfile && myProfile.phoneno !== "" && myProfile.phoneno !== undefined)
      ?
      yup
        .string('Please enter a 6-digit verification code.')
        .required('Enter the 6-digit code')
        .max(6, 'Please enter the correct SMS code')
      :
      yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      newEmail: '',
      newEmailOTP: '',
      oldPhonenoOTP: '',
      oldEmailOTP: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        pageName: "changeEmail",
        newEmailOTP: values.newEmailOTP,
        newEmail: values.newEmail,
        oldEmailOTP: values.oldEmailOTP,
        oldPhonenoOTP: values.oldPhonenoOTP
      }
      const params = {
        url: `${Config.V2_API_URL}user/changeVerificationDetail`,
        method: 'POST',
        data: payload
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = "success";
        handleClose();
      }
      toast({ type, message: response.message });
    },
  });

  async function getCode(data) {
    try {
      let payload = {};
      if (data.target == "newEmailOTP") {
        payload = {
          pageName: "changeEmail",
          newEmail: data.value,
        }
      } else if (data.target == "newPhonenoOTP") {
        payload = {
          pageName: "changeEmail",
        }
      } else if (data.target == "oldPhonenoOTP") {
        payload = {
          pageName: "changeEmail",
        }
      }
      else if (data.target == "oldEmailOTP") {
        payload = {
          pageName: "changeEmail",
          oldEmailOTP: data.value
        }
      }
      payload.target = data.target;
      const params = {
        url: `${Config.V2_API_URL}otp/getCode`,
        method: 'POST',
        data: payload
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = "success";
      }
      toast({ type, message: response.message });
    } catch (err) { }
  }
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={openEmail}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          fullWidth={false}
          maxWidth={'xs'}
          aria-describedby="alert-dialog-slide-description"
          className='mx-auto'
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{`${(myProfile && (myProfile.email === undefined || myProfile.email == '')) ? "Enable" : "Change"} Email verification`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <p className='label-text mb-2'>New Email</p>
                <InputGroup className="mb-1" size='sm'>
                  <Form.Control
                    placeholder=""
                    type='email'
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className='form-popup'
                    id="newEmail"
                    name='newEmail'
                    autoComplete='off'
                    value={formik.values.newEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newEmail && Boolean(formik.errors.newEmail)}
                    helperText={formik.touched.newEmail && formik.errors.newEmail}
                  />
                </InputGroup>
                {formik.errors.newEmail ? <p className='text-danger mb-2'><small>{formik.errors.newEmail}</small> </p> : null}

                <p className='label-text mb-2'>New Email Verification Code</p>
                <InputGroup className="mb-1" size='sm'>
                  <Form.Control
                    placeholder=""
                    type='number'
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className='form-popup'
                    id="newEmailOTP"
                    name='newEmailOTP'
                    autoComplete='off'
                    value={formik.values.newEmailOTP}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newEmailOTP && Boolean(formik.errors.newEmailOTP)}
                    helperText={formik.touched.newEmailOTP && formik.errors.newEmailOTP}
                  />
                  <InputGroup.Text id="basic-addon2" className='handle-btn-popup fw-bold' onClick={() => getCode({ target: "newEmailOTP", value: formik.values.newEmail })} style={{ cursor: "pointer" }}>Get Code</InputGroup.Text>
                </InputGroup>
                {formik.errors.newEmailOTP ? <p className='text-danger mb-2'><small> {formik.errors.newEmailOTP}</small> </p> : <p className="mb-3"></p>}

                {myProfile && myProfile._id && <p className='label-text mb-2'>Security verification</p>}
                {(myProfile && myProfile.phoneno !== "" && myProfile.phoneno !== undefined) && <>
                  <p className='label-text mb-2'>Phone Number verification Code</p>

                  <InputGroup className="mb-1" size='sm'>
                    <Form.Control
                      placeholder=""
                      type='number'
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className='form-popup'
                      id="oldPhonenoOTP"
                      name='oldPhonenoOTP'
                      autoComplete='off'
                      value={formik.values.oldPhonenoOTP}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.oldPhonenoOTP && Boolean(formik.errors.oldPhonenoOTP)}
                      helperText={formik.touched.oldPhonenoOTP && formik.errors.oldPhonenoOTP}
                    />
                    <InputGroup.Text id="basic-addon2" className='handle-btn-popup fw-bold' onClick={() => getCode({ target: "oldPhoneOTP", value: myProfile && myProfile.phoneno })} style={{ cursor: "pointer" }}>Get Code</InputGroup.Text>
                  </InputGroup>
                  {myProfile && myProfile.phoneno &&
                    <p className='text-danger'><small>
                      {"Enter the 6-digit code sent to " + showPhone(myProfile && myProfile.phoneno)}
                    </small> </p>
                  }
                  {formik.errors.oldPhonenoOTP ? <p className='text-danger'><small>{formik.errors.oldPhonenoOTP}</small> </p> : null}
                </>}
                {(myProfile && myProfile.email !== "" && myProfile.email !== undefined) && <>
                  <p className='label-text mb-2'>Phone Number verification Code</p>

                  <InputGroup className="mb-1" size='sm'>
                    <Form.Control
                      placeholder=""
                      type='number'
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className='form-popup'
                      id="oldEmailOTP"
                      name='oldEmailOTP'
                      autoComplete='off'
                      // disabled={emailStatus}
                      value={formik.values.oldEmailOTP}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.oldEmailOTP && Boolean(formik.errors.oldEmailOTP)}
                      helperText={formik.touched.oldEmailOTP && formik.errors.oldEmailOTP}
                    />
                    <InputGroup.Text id="basic-addon2" className='handle-btn-popup fw-bold' style={{ cursor: "pointer" }} onClick={() => getCode({ target: "oldEmailOTP" })}>Get Code</InputGroup.Text>
                  </InputGroup>

                  <p className='text-danger'><small>
                    {"Enter the 6-digit code sent to " + showEmail(myProfile && myProfile.email)}
                  </small> </p>
                  {formik.errors.oldEmailOTP ? <p className='text-danger'><small> {formik.errors.oldEmailOTP} </small> </p> : null}
                </>}
              </DialogContentText>
            </DialogContent>
            <DialogActions className='mx-auto text-center justify-content-center'>
              <button className='btn-profile shadow px-5 fs-6 py-2 mb-3' style={{ width: 'auto', height: "auto" }} type="submit">Enable</button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </div >
  );
};

export default EmailPopUp;