import React, { useEffect, useState } from "react";
import './Portfolio.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Form } from 'react-bootstrap';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {
    useParams,
    useNavigate,
} from "react-router-dom";
import { RiArrowDownSFill, RiFileCopyFill } from 'react-icons/ri';

import $ from "jquery";

import Config from "../../core/config";
import { makeRequest } from "../../core/services/v1/request";

import NavbarOne from "../../Pages/separate/NavbarOne";
import Footer from "../../Pages/separate/Footer";

import { toast } from "../../core/lib/toastAlert";


import ReactTooltip from "react-tooltip";
import { dateFormat } from '../../core/helper/date-format';
import { shortAdrress } from '../../core/helper/short-address';

import { useContextData } from '../../core/context/index';

import "../../assets/style.css";
import Loader from "../../Component/Loader/Loader";


const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "600",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'

}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "#C8C8C8",
    textAlign: 'center'
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));


const DepositCrypto = (props) => {
    const navigate = useNavigate();

    let { currencyId } = useParams();
    const { myProfile } = useContextData();
    const [loader, setLoader] = useState(false);
    const [currencies, setCurrencies] = useState("");
    const [qrCode, setqrCode] = useState("");
    const [depositData, setdepositData] = useState({ address: "" });
    const [depositStatus, setdepositStatus] = useState(null);
    const [networkName, setnetworkName] = useState("");
    const [networkId, setnetworkId] = useState("");
    const [networkList, setnetworkList] = useState([]);
    const [depositcryptoList, setdepositcryptoList] = useState([]);
    const [defaultNetwork, setdefaultNetwork] = useState("");
    const [uniticCounts, setUniticCounts] = useState(0);
    const [defaultCurrency, setdefaultCurrency] = useState("");
    const [defaultCurrencyList, setdefaultCurrencyList] = useState({ currencySymbol: "", image: "" });
    const [currencyList, setCurrencyList] = useState({});
    const [currencyID, setCurrencyID] = useState(currencyId);
    const [depositText, setDepositText] = useState("CLAIM DEPOSIT ?");
    const [particularCurrencyList, setparticularCurrencyList] = useState({});

    const [curCurrencySymbol, setCurCurrencySymbol] = useState("");
    useEffect(() => {
        $(".crypto-workflow-section-close-button").click(function () {
            $(".deposit-second-section").hide();
        });
    }, []);

    useEffect(() => {
        if (myProfile && myProfile.email == "") {
            toast({ type: "error", message: "Please enable email verification" });
            navigate('/my/email-authenticator');
        }
        if (myProfile && (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1)) {
            toast({ type: "error", message: "Please complete the kyc details" });
            navigate('/my/identification');
        }
        depositETHTRX();
    }, [currencyId, myProfile]);

    useEffect(() => {
        async function getWalletCurrency() {
        }
        getWalletCurrency();
        getWalletCurrency_func();
        getparticularCurrency_func();
    }, []);
    useEffect(() => {
        if (defaultCurrencyList.currencySymbol == 'UNITIC') {
            checkDepositCrypto()
        }
    }, [myProfile, defaultCurrencyList]);

    async function getparticularCurrency_func() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: "POST",
                body: { CurrencyID: "" }
            };
            const response = await makeRequest(params);
            if (response.status && response.data) {
                setparticularCurrencyList(response.data);
            }
        } catch (err) { }
    }

    async function getWalletCurrency_func() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
                method: "GET",
            };
            const response = await makeRequest(params);
            if (response.status && response.data) {
                let currencyArray = [];
                let currencySymbol = "";
                let image = "";
                let networkName = "";
                let defaultNetwork = "";
                let networkId = "";
                response.data.map((item) => {
                    let id = "";
                    const findOne = response.data.find(
                        (element) => element._id == currencyId
                    );
                    if (findOne.curnType == "Fiat") {
                        id = response.data[0]._id;
                    } else {
                        id = currencyId;
                    }
                    if (item._id == id) {
                        networkId = item.currencyId;
                        currencySymbol = item.currencySymbol;
                        image = item.image;
                        item.ids.length > 0 && item.ids.map((item) => {
                            networkName = item.basecoin;
                            defaultNetwork = item._id;
                            if (item.depositEnable == 0) {
                                setdepositStatus(false);
                            }
                            if (item.depositEnable == 1) {
                                setdepositStatus(true);
                            }
                        });
                        setnetworkList(item.ids);
                        setdefaultNetwork(defaultNetwork);
                    }
                    currencyArray.push({
                        value: item._id,
                        currencySymbol: item.currencySymbol,
                        image: item.image,
                        currencyName: item.currencyName
                    });
                });
                setCurrencyList(currencyArray);
                setdefaultCurrencyList({ currencySymbol: currencySymbol, image: image })
                setnetworkId(networkId);
                setnetworkName(networkName);
                getparticularHistory(currencySymbol);
                createAddress(defaultNetwork);
                getparticularCurrencyDet(defaultNetwork);

                setCurCurrencySymbol(currencySymbol);
            }
        } catch (err) { }
    }
    async function checkDepositCrypto() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/checkDepositCrypto`,
                method: "POST",
                data: { currencySymbol: "UNITIC", userId: myProfile._id, basecoin: "ARB20" }
            };
            const response = await makeRequest(params);
            if (response.status) {
                setUniticCounts(() => response?.totalAmount || 0)
            }
        } catch (err) { }
    }

    async function getparticularCurrencyDet(defaultNetwork) {
        try {
            const value = { CurrencyID: defaultNetwork };
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: "POST",
                data: value,
            };
            const result = await makeRequest(params);
            if (result.status) {
                if (result.data.depositEnable == 0) {
                    setdepositStatus(false);
                }
                setdepositStatus(true);
                setCurrencies(result.data);
                setnetworkName(result.data.basecoin);
            }
        } catch (err) { }
    }
    async function handlenetworkChange(networkId) {
        setnetworkId(networkId);
        getselectedNetwork(networkId);
        createAddress(networkId);
    }
    async function getselectedNetwork(networkId) {
        try {
            const value = { CurrencyID: networkId };
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: "POST",
                data: value,
            };
            const response = await makeRequest(params);
            if (response.status) {
                if (response.data.depositEnable == 0) {
                    setdepositStatus(false);
                }
                setdepositStatus(true);
                setCurrencies(response.data);
                setnetworkName(response.data.basecoin);
            } else {
                navigate("/my/dashboard");
            }
        } catch (err) { }
    }
    async function createAddress(networkId) {
        try {
            const value = { CurrencyID: networkId };
            const params = {
                url: `${Config.V1_API_URL}wallet/createAddress`,
                method: "POST",
                data: value,
            };
            const result = await makeRequest(params);
            if (result.status) {
                setdepositData(result.data);
                const qrCode =
                    "https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=" +
                    result.data.address +
                    "&choe=UTF-8&chld=L";
                setqrCode(qrCode);
            }
        } catch (err) { }
    }
    //Config.FRONEND_URL
    async function handleCurrencyChange(currencyId) {
        window.history.pushState({}, '', Config.FRONEND_URL + "deposit/crypto/" + currencyId);
        setCurrencyID(currencyId);
        getSelectedCurrency(currencyId);
    }
    async function getSelectedCurrency(currencyValue) {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
                method: "GET",
            };
            const response = await makeRequest(params);
            if (response.status && response.data) {
                let currencyArray = [];
                let currencySymbol = "";
                let image = "";
                let network = "";
                let defaultNetwork = "";
                let networkId = "";
                response.data.map((item) => {
                    if (item._id == currencyValue) {
                        networkId = item.currencyId;
                        currencySymbol = item.currencySymbol;
                        image = item.image;
                        item.ids.map((item) => {
                            network = item.basecoin;
                            defaultNetwork = item._id;
                            if (item.depositStatus == 0) {
                                setdepositStatus(false);
                            }
                            if (item.depositStatus == 1) {
                                setdepositStatus(true);
                            }
                        });
                        setnetworkList(item.ids);
                        setdefaultNetwork(defaultNetwork);
                    }
                    currencyArray.push({
                        value: item._id,
                        currencySymbol: item.currencySymbol,
                        image: item.image, currencyName:
                            item.currencyName
                    });
                });
                setCurrencyList(currencyArray);
                setdefaultCurrencyList({ currencySymbol: currencySymbol, image: image });
                setnetworkId(networkId);
                setnetworkName(network);
                createAddress(defaultNetwork);
                getparticularHistory(currencySymbol);
                getparticularCurrencyDet(defaultNetwork);

                setCurCurrencySymbol(currencySymbol);
            }
        } catch (err) { }
    }
    async function getparticularHistory(Currency) {
        setLoader(true);
        try {
            const value = {
                type: "Deposit",
                currencyType: "Crypto",
                currencySymbol: Currency
            };
            const params = {
                url: `${Config.V1_API_URL}wallet/getHistory`,
                method: "POST",
                data: value,
            };
            const result = await makeRequest(params);
            if (result.status) {
                if (defaultCurrencyList.currencySymbol == 'UNITIC') {
                    checkDepositCrypto()
                }
                const array = [];
                result.data.map((item) => {
                    if (Currency == item.currencyId.currencySymbol) {
                        array.push({
                            currencySymbol: item.currencyId.currencySymbol,
                            image: item.currencyId.image,
                            amount: item.amount,
                            fees: item.fees,
                            txnId: item.txnId,
                            status: item.status,
                            createdDate: item.createdDate,
                        });
                    }
                });
                setdepositcryptoList(array);
            }
            else {
                setdepositcryptoList([]);
            }
        } catch (err) { }
        setLoader(false);
    }
    async function depositETHTRX() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/depositETHTRX`,
                method: "GET",
            };
            const response = await makeRequest(params);
        } catch (err) { }
    }
    const missingDeposit = async () => {
        setDepositText('Loading...')
        const params = {
            url: `${Config.V1_API_URL}wallet/depositETHTRX`,
            method: 'GET'
        }
        const response = (await makeRequest(params));
        if (response.status) {
            let type = 'success';
            toast({ type, message: "Success" });
            setDepositText('CLAIM DEPOSIT ?');
        } else {
            // let type = 'error';
            // toast({ type, message: response.msg });
            setDepositText('CLAIM DEPOSIT ?')
        }
        getparticularHistory(curCurrencySymbol);
    }

    function copyToColumn(data = {}) {
        var input = document.createElement("input");
        document.body.appendChild(input);
        input.value = data.text;
        input.select();
        document.execCommand("Copy");
        input.remove();
        toast({ type: "success", message: data.message });
    }

    const handleSearch = (e) => {
        const searchval = e.target.value;
        if (searchval !== '') {
            let results = []
            results = currencyList.filter((item) => {
                return (item.currencySymbol.split('_').join('/').toLowerCase().indexOf(searchval.toLowerCase()) >= 0 || item.currencySymbol.toLowerCase().indexOf(searchval.toLowerCase()) >= 0)
            });
            if (results.length > 0) {
                setCurrencyList(results);
            } else {
                setCurrencyList([]);
            }
        } else {
            getWalletCurrency_func();
        }
    }

    function bonusAmount(trxn) {
        try {
            if (typeof trxn.bonusData == 'object' && trxn.bonusData.bonusGiven > 0) {
                return trxn.bonusData.bonusGiven.toFixed(trxn.currencyId.siteDecimal);
            } else {
                if (trxn.depositType == 'Pre Booking') {
                    return '-';
                } else {
                    return 'NA';
                }
            }
        } catch (err) { }
    }

    const copyToClipboard = (data = {}) => {
        toast({ type: "success", message: data.message });
    }
    return (
        <section >
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className='min-vh-100 bg-default text-light pb-5 pt-3'>
                <div className='container pb-5 pt-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/my/dashboard")}><strong><ArrowBackIcon style={{ cursor: "pointer" }} /> Deposit Crypto</strong></h4>
                        <h5 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/deposit/fiat/" + particularCurrencyList?._id)}><strong>Deposit Fiat <ArrowForwardIcon style={{ cursor: "pointer" }} /></strong></h5>

                    </div>
                    <div className='mx-auto text-center my-5 pb-3'>
                        <img src="/assets/deposit.png" alt="" className='img-fluid' />
                    </div>

                    <div className='withdraw-box mx-auto my-5 pb-4'>
                        <h5 className='text-center py-4 fw-bold'>
                            Crypto Deposit
                        </h5>
                        <div>
                            <Form className='w-75  mx-auto'>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Select Crypto</Form.Label>

                                    <div
                                        className="d-flex flex-row align-items-center deposit-select-coin-section"
                                        data-bs-toggle="modal"
                                        data-bs-target="#selectcoinModal"
                                    >
                                        <div className="">
                                            <img
                                                src={defaultCurrencyList && defaultCurrencyList.image}
                                                className="deposit-logo-image"
                                                alt="logo"
                                            />
                                            <span key={'i'} className="deposit-text-4"> {defaultCurrencyList && defaultCurrencyList.currencySymbol} </span>
                                        </div>
                                        <div className="ms-auto">
                                            <RiArrowDownSFill />
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Deposit Network</Form.Label>
                                    <div
                                        className="d-flex flex-row align-items-center deposit-select-coin-section"
                                        data-bs-toggle="modal"
                                        data-bs-target="#depositnetworkModal"
                                    >
                                        <div className="">
                                            {/* <img src="https://seeklogo.com/images/E/ethereum-logo-EC6CDBA45B-seeklogo.com.png" className='deposit-logo-image' alt="logo" /> */}
                                            <span className="deposit-text-4"></span>
                                            <span className="deposit-text-5 text-light">{networkName == "Coin"
                                                ?
                                                defaultCurrencyList.currencySymbol
                                                :
                                                networkName
                                            }</span>
                                        </div>
                                        <div className="ms-auto">
                                            <RiArrowDownSFill />
                                        </div>
                                    </div>
                                </Form.Group>

                            </Form>

                            <div style={{
                                color: '#C8C8C8',
                                fontSize: '14px',
                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                            }} className='w-75 mx-auto'>
                                {defaultCurrency == "INR" ?
                                    <div className="deposit-third-right-section">
                                        <span className="deposit-text-33">{(currencies?.depositEnable == 0) ? defaultCurrency + "  Deposit Disabled" : ""}</span>
                                    </div>
                                    :
                                    <div className="deposit-third-right-section">
                                        <span className="deposit-text-33">{((currencies?.depositEnable == 0) || (currencies?.currencySymbol === 'UNITIC' && uniticCounts >= 10000)) ? networkName + "  Deposit Disabled" : ""}</span>
                                    </div>
                                }
                                {((currencies?.depositEnable != 0 && depositStatus == true) && !(currencies?.currencySymbol === 'UNITIC' && uniticCounts >= 10000)) && <>

                                    {depositData && depositData.tag != '' &&
                                        <>
                                            <span className="deposit-text-33">Tag</span>
                                            <div className="d-flex flex-row">
                                                <div className="">
                                                    <span className="deposit-text-6">
                                                        {depositData && depositData.tag ? depositData.tag : <>&nbsp;</>}
                                                    </span>
                                                </div>
                                                <div className="ms-auto">
                                                    <span data-tip data-for="noOverridePosition">
                                                        <RiFileCopyFill className="deposit-icon-1" onClick={() => copyToColumn({
                                                            message: "Destination Tag copied successfully!",
                                                            text: depositData && depositData.tag != '' ? depositData.tag : '-'
                                                        })} />
                                                    </span>
                                                    <ReactTooltip
                                                        id="noOverridePosition"
                                                        place="top"
                                                        className="tooltip-text-Theme text-center"
                                                    >
                                                        <span className="tooltip-text-1">Click to Copy</span>
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                        </>}

                                    <p className='pb-3'>Address : {depositData && depositData.address ? depositData.address : <>&nbsp;</>} <CopyToClipboard text={`${depositData && depositData.address != '' ? depositData.address : '-'}`} onCopy={() => copyToClipboard({ message: "Address copied successfully!" })}>
                                        <ContentCopyIcon fontSize='small' sx={{ cursor: 'pointer' }} />
                                    </CopyToClipboard>
                                        <>
                                            <span data-tip data-for="noOverridePosition2">
                                                <QrCodeIcon fontSize='small' sx={{ cursor: 'pointer' }} />
                                            </span>
                                            <ReactTooltip
                                                id="noOverridePosition2"
                                                effect="solid"
                                                place="bottom"
                                                className="tooltip-text-Theme"
                                            >
                                                <span className="tooltip-text-1">
                                                    Scan the code on the withdrawal page of the platform APP or Wallet APP
                                                </span>
                                                <br />
                                                {qrCode && <img className="qr-code-tooltip-image my-2" alt="qr-code" src={qrCode} />}
                                                <ul className="p-0 ps-2">
                                                    <li className="tooltip-text-1">
                                                        Send only {currencies.currencySymbol} {currencies.basecoin != 'Coin' ? '- ' + currencies.basecoin : ''} to this deposit address.
                                                    </li>
                                                    <li className="tooltip-text-1">
                                                        Ensure the network is {networkName == "Coin"
                                                            ?
                                                            defaultCurrencyList.currencySymbol
                                                            :
                                                            networkName}
                                                    </li>
                                                </ul>
                                            </ReactTooltip>
                                        </>
                                    </p>
                                    <li>Minimum deposit More than <span className="deposit-text-9">{currencies?.mindepamt} {currencies.currencySymbol}</span>, deposits below that cannot be recovered.</li>
                                    <li>Send only <span className="deposit-text-9">{currencies.currencySymbol} {currencies.basecoin != 'Coin' ? '- ' + currencies.basecoin : ''}</span> to this deposit address.</li>

                                    <li className='pb-5'>{currencies.depositNotes && <li>
                                        <span className="deposit-text-3">
                                            {currencies.depositNotes}
                                        </span>
                                    </li>}</li>

                                </>}
                            </div >
                        </div>


                    </div>

                    <div className='mt-5 pt-4'>
                        <h4 className='pb-2 ps-3 ps-md-5 fw-bold'>Recent Deposit</h4>
                        <p className='pb-2 ps-3 ps-md-5 '>
                            If your previous deposit is not reflecting, you can claim your deposit below &nbsp;
                        </p>
                        <button className='btn-profile shadow mb-2 ms-3 ms-md-5 mb-4' style={{ width: 'auto', fontSize: "16px", display: currencies?.currencySymbol === 'UNITIC' && uniticCounts >= 10000 ? 'none' : '' }} onClick={() => missingDeposit()}>{depositText}</button>
                        {loader ? <Loader /> :
                            <TableContainer>
                                <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                                    <StyledTableHead>
                                        <TableRow>
                                            <StyledTableHeadRow>Assets</StyledTableHeadRow>
                                            <StyledTableHeadRow>Amount</StyledTableHeadRow>
                                            <StyledTableHeadRow>Bonus</StyledTableHeadRow>
                                            <StyledTableHeadRow>Txn.id</StyledTableHeadRow>
                                            <StyledTableHeadRow>Status</StyledTableHeadRow>
                                            <StyledTableHeadRow>Date</StyledTableHeadRow>
                                        </TableRow>
                                    </StyledTableHead>
                                    <TableBody>
                                        {depositcryptoList.length > 0 && depositcryptoList.map((row, i) => {
                                            if (row) {
                                                return (
                                                    <TableRow key={i}>
                                                        <StyledTableCell component="th" scope="row"><span className='tb-img'>
                                                            <img src={row?.image} alt="Fibit" width="20px" height="20px" className="img-fluid img-res" style={{ height: '20%', width: '20%' }} />
                                                        </span> &nbsp; {row?.currencySymbol}</StyledTableCell>
                                                        <StyledTableCell>{row?.amount}</StyledTableCell>
                                                        <StyledTableCell>{bonusAmount(row)}</StyledTableCell>
                                                        <StyledTableCell onClick={() => copyToColumn({
                                                            text: row?.txnId != '' ? row?.txnId : '-',
                                                            message: "Txn.Id copied successfully!"
                                                        })}>{shortAdrress(row?.txnId != "" ? row?.txnId : "-")}</StyledTableCell>
                                                        <StyledTableCell>{row.status == 0 ? 'Pending' : row.status == 1 ? "Approved" : row.status == 2 ? 'Rejected' : 'Processing'}</StyledTableCell>
                                                        <StyledTableCell>{dateFormat(row?.createdDate)}</StyledTableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })}
                                        {depositcryptoList.length == 0 &&
                                            <TableRow >
                                                <StyledTableCell component="th" scope="row"></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell ><img
                                                    className="no-record-image"
                                                    src="/assets/no-re.png"
                                                    alt="no-record"
                                                />
                                                    <br />
                                                    <span className="text-center">No Records Found</span></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </div>
                    <div
                        className="modal fade deposit-crypto-modal"
                        id="selectcoinModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header ">
                                    <h4 className="modal-title" id="exampleModalLabel">
                                        Select coin to deposit
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body modal-2-overflow-scroll">
                                    <input
                                        id="myInput2"
                                        type="text"
                                        placeholder="Search here..."
                                        autoComplete="off"
                                        name="search"
                                        onChange={handleSearch}
                                    />
                                    <ul id="myUL2" >
                                        {currencyList.length > 0 && currencyList.map((currency, i) => {
                                            if (currency.currencySymbol != 'INR') {
                                                return (
                                                    <li onClick={() => handleCurrencyChange(currency.value)}>
                                                        <a
                                                            data-bs-dismiss="modal"
                                                            className="deposit-modal-list-sizing"
                                                        >
                                                            <div className="row deposit-modal-row-sizing">
                                                                <div className="col-1">
                                                                    <img
                                                                        src={currency.image}
                                                                        alt="logo"
                                                                        className="me-1 deposit-modal-coin-images"
                                                                    />
                                                                </div>
                                                                <div className="col">
                                                                    <div className="row ps-2">
                                                                        <span className="deposit-modal-coin-heading">
                                                                            {currency.currencySymbol}
                                                                        </span>
                                                                        <span className="deposit-modal-coin-muted-text ">
                                                                            {currency.currencyName}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="modal fade deposit-crypto-modal"
                        id="depositnetworkModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="exampleModalLabel">
                                        Select network
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body modal-2-overflow-scroll">
                                    <p className="select-network-text-1 pb-3">
                                        Please note that only supported networks on platform are shown, if you deposit via another network your assets may be lost.
                                    </p>
                                    <ul id="myUL2">
                                        {networkList.length > 0 && networkList.map((item, i) => {
                                            if (item._id == defaultNetwork) {
                                                return (
                                                    <li selected={true} onClick={() => handlenetworkChange(item._id)}>
                                                        <a
                                                            data-bs-dismiss="modal"
                                                            className="deposit-modal-list-sizing"
                                                        >
                                                            <div className="row deposit-modal-network-sizing">
                                                                <span className="deposit-modal-coin-heading">

                                                                    {networkName == item.basecoin
                                                                        ?
                                                                        networkName == "Coin"
                                                                            ?
                                                                            defaultCurrencyList.currencySymbol
                                                                            :
                                                                            networkName
                                                                        :
                                                                        item.basecoin}
                                                                </span>
                                                                <span className="deposit-modal-coin-muted-text ">
                                                                    {/* BNB Smart Chain (BEP20) */}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li onClick={() => handlenetworkChange(networkName == item.basecoin ? networkName : item._id)}>
                                                        <a
                                                            data-bs-dismiss="modal"
                                                            className="deposit-modal-list-sizing"
                                                        >
                                                            <div className="row deposit-modal-network-sizing">
                                                                <span className="deposit-modal-coin-heading">{networkName == item.basecoin ? networkName : item.basecoin}</span>
                                                                <span className="deposit-modal-coin-muted-text ">
                                                                    {/* BNB Smart Chain (BEP20) */}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </section>
    );
};

export default DepositCrypto;