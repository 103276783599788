import React from 'react';
import Button from '@mui/material/Button';
import ButtonB from 'react-bootstrap/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useContextData } from '../../../core/context/index'
import { deleteCookie } from '../../../core/helper/cookie';
import SearchBar from '../../../Component/SearchBar/SearchBar';
const Header = ({ siteSettings, userLogin }) => {
  const { myProfile, setUserProfile } = useContextData();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const menuBar = <ThemeProvider theme={theme}>
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="contained" className='btn-user' sx={{ textTransform: 'none' }} {...bindTrigger(popupState)}>
            <span className='btn-font'>{myProfile && <>{myProfile?.firstname ? myProfile?.firstname : myProfile.email ? myProfile.email : myProfile.phoneno}{" "}</>}</span> <KeyboardArrowDownIcon sx={{ color: "white" }} />
          </Button>
          <Menu {...bindMenu(popupState)} >
            <MenuItem sx={{ fontSize: '0.938rem', fontWeight: 600 }} onClick={popupState.close} as={Link} to="/my/profile" className='text-decoration-none text-white'><img src="/assets/User.png" alt="" className='img-fluid me-1' width={22} /> Profile</MenuItem>
            <MenuItem sx={{ fontSize: '0.938rem', fontWeight: 600 }} onClick={popupState.close} as={Link} to="/my/identification" className='text-decoration-none text-white'><img src="/assets/LockLaminated.png" alt="" className='img-fluid me-1' width={22} />Identification</MenuItem>
            <MenuItem sx={{ fontSize: '0.938rem', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-white' as={Link} to="/my/payment"><img src="/assets/CreditCard.png" alt="" className='img-fluid me-1' width={22} />Payment</MenuItem>
            <MenuItem sx={{ fontSize: '0.938rem', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-white' as={Link} to="/my/referral"><img src="/assets/UserGear.png" alt="" className='img-fluid me-1' width={22} />Referral</MenuItem>
            <MenuItem sx={{ fontSize: '0.938rem', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-white' as={Link} to="/my/SpotTradeHistory"><img src="/assets/ClockCounterClockwise.png" alt="" className='img-fluid me-1' width={22} />Trade history</MenuItem>
            <MenuItem sx={{ fontSize: '0.938rem', fontWeight: 600 }} onClick={popupState.close} className='text-decoration-none text-white' as={Link} to="/my/wallet/history"><img src="/assets/ClockCounterClockwise.png" alt="" className='img-fluid me-1' width={22} />Transactions</MenuItem>
            <MenuItem sx={{ fontSize: '0.938rem', fontWeight: 600 }} onClick={() => {
              popupState.close();
              deleteCookie('userToken');
              setUserProfile();
              navigate('/login');
            }} className='text-decoration-none text-white'><img src="/assets/SignOut.png" alt="" className='img-fluid me-1' width={22} />Logout</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  </ThemeProvider >

  return (
    <div style={{ background: "#282525", color: "#DADADA" }} className='py-0'>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} collapseOnSelect className='py-2'>
          <Container fluid>
            <Navbar.Brand href="#" as={Link} to={"/"} >
              {(siteSettings) && (< img src={siteSettings.siteLogo} alt="logo" className='img-fluid' width={90} />)}
            </Navbar.Brand>
            <Navbar.Toggle style={{ fontSize: '0.938rem' }} aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className='w-75'
              style={{ background: "#282525", color: "#DADADA" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>

                  {(siteSettings) && (< img src={siteSettings.siteLogo} alt="logo" className='img-fluid' width={100} />)}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body >
                <Nav className="me-auto align-items-start align-items-lg-center flex-grow-1 darkNavCss">
                  {/* <Nav.Link href="#home" as={Link} to={"/"}>Home</Nav.Link> */}
                  <Nav.Link as={Link} to={"/markets"} href="#market">Market</Nav.Link>
                  <Nav.Link as={Link} to={"/signal"} href="#signal">Signal</Nav.Link>
                  <NavDropdown
                    title="Trade"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    style={{ backgroundColor: "#282525", marginLeft: "0px" }}
                    className={`text-white bootstrap-custom-dropdown-header ${(pathname.includes("/home")) ? "" : ""}`}
                  >
                    <NavDropdown.Item href="#action4" as={Link} to={"/spot/TRX_INR"} className='fw-bold'>Spot</NavDropdown.Item>
                    <NavDropdown.Item href="#action3" as={Link} to={"/Insta-Trade"} className='fw-bold'>Insta</NavDropdown.Item>
                    <NavDropdown.Item href="#action2" className='fw-bold'
                      as={Link}
                      to={"/futures/trade/XBTUSDTM"}
                    >Futures</NavDropdown.Item>

                  </NavDropdown>
                  <Nav.Link href="https://utistaking.com/" target='_blank'>Staking</Nav.Link>
                  {userLogin ?
                    <Nav.Link href="#portfolio" as={Link} to={"/my/dashboard"}>Portfolio</Nav.Link> : null
                  }
                  {/* <Nav.Link>
                    <Googletranslate />
                  </Nav.Link> */}
                  <Nav.Link>
                    <SearchBar
                    />
                  </Nav.Link>
                </Nav>
                <Nav className=" align-items-start align-items-lg-center">
                  {
                    userLogin ? <Nav.Link >{menuBar} </Nav.Link> : <>
                      <Nav.Link href="#login" as={Link} to={"/login"}>
                        <ButtonB className='border-0' style={{
                          background: 'transparent',
                          height: '32px', fontSize: '0.938rem', color: '#dadada', fontWeight: 600
                        }}>Login</ButtonB>
                      </Nav.Link>
                      <Nav.Link href="#register" as={Link} to={"/register"}>
                        <ButtonB variant="light" style={{ height: '32px', fontSize: '14px', padding: '0px 20px', fontWeight: 600 }} className='border text-dark'>Register</ButtonB>

                      </Nav.Link></>
                  }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <hr style={{
        border: '1.5px solid white',
        backgroundColor: 'white',
        color: 'rgb(174, 171, 171)',
        opacity: 1, margin: 0
      }} />
    </div>
  );
};

export default Header;