import React from 'react';
import './Profile.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import PhonePopUp from '../Authenticator/PhonePopUp';
import EmailPopUp from '../Authenticator/EmailPopUp';
import ChangePasswordPopUp from '../Authenticator/ChangePasswordPopUp';
import { useContextData } from '../../../core/context';
import { showEmail, showPhone } from '../../../core/helper/date-format';

const Profile = () => {
    const [openPhn, setOpenPhn] = React.useState(false);
    const [openEmail, setOpenEmail] = React.useState(false);
    const [openPassword, setOpenPassword] = React.useState(false);
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    return (
        <section className=''>
            <div className=' py-5'>
                <div>
                    <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Profile</strong></h4>
                    <p className='pt-2 fs-5'>Two factor Authentication (2FA)</p>
                </div>

                <div className='my-4 overflow-hidden pb-5'>
                    <div className='row gx-0 gy-4'>
                        <div className="col-12 mx-auto box-profile p-2 p-md-4">
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="/assets/google-authenticator1.png" alt="" width={100} />
                                <div className='ms-2 ms-md-3'>
                                    <h5  >Google Authenticator <span className='d-none d-md-block'>(Recommended)</span></h5>
                                    <p className='mb-0' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>
                                        protect your account  and Transcations.
                                    </p>

                                </div>
                            </div>
                            <div>
                                {myProfile?.tfaenablekey == '' ? <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }} className='text-danger fw-bold'>Not linked</p> : <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: "#21CA50" }} className=' fw-bold'>On</p>}
                            </div>
                            <div>
                                <button className='btn-profile shadow' onClick={() => navigate('/google-authenticator')}>{myProfile?.tfaenablekey != '' ? 'Disable' : 'Enable'}</button>
                            </div>
                        </div>
                        <div className="col-12 mx-auto box-profile p-2 p-md-4">
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="/assets/PHONEVERIFICATION.png" alt="" width={100} />
                                <div className='ms-2 ms-md-3'  >
                                    <h5 >Phone Number verification</h5>
                                    <p className='mb-0' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>
                                        verify your phone number
                                    </p>

                                </div>
                            </div>
                            <div>
                                {myProfile && myProfile?.phoneno == '' ? <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }} className='fw-bold d-none d-md-block text-danger'>Not linked</p> : <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: "#21CA50" }} className='fw-bold d-none d-md-block'>{showPhone(myProfile && myProfile?.phoneno)}</p>}

                            </div>
                            <div>
                                <button className='btn-profile shadow' onClick={() => setOpenPhn(true)}>{myProfile?.phoneno == '' ? "Enable" : "Change"}</button>
                            </div>
                        </div>
                        <div className="col-12 mx-auto box-profile p-2 p-md-4">
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="/assets/EMAILADDRESSVERIFICATION.png" alt="" width={100} />
                                <div className='ms-2 ms-md-3' >
                                    <h5 >Email Address verification</h5>
                                    <p className='mb-0' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>
                                        verify your Email-id
                                    </p>

                                </div>
                            </div>
                            <div>
                                {myProfile && myProfile?.email == '' ? <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }} className='text-danger fw-bold d-none d-md-block'>Not linked</p> : <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: "#21CA50" }} className='fw-bold d-none d-md-block'>{showEmail(myProfile && myProfile?.email)}</p>}

                            </div>
                            <div>
                                <button className='btn-profile shadow' onClick={() => setOpenEmail(true)}>{myProfile?.email == '' ? "Enable" : "Change"}</button>
                            </div>
                        </div>
                        <div className="col-12 mx-auto"><p className='pt-2 fs-5'>Advanced Security</p></div>
                        <div className="col-12 mx-auto box-profile p-2 p-md-4">
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src="/assets/LOGINPASSWORD.png" alt="" width={100} />
                                <div className='ms-2 ms-md-3'>
                                    <h5 >Login Password</h5>
                                    <p className='mb-0' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>
                                        Login password is used to login in to your Account
                                    </p>

                                </div>
                            </div>
                            <div>
                                <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: "#21CA50" }} className='fw-bold d-none d-md-block'>{showEmail(myProfile && myProfile?.email)}</p>
                            </div>
                            <div>
                                <button className='btn-profile shadow' onClick={() => setOpenPassword(true)}>Change</button>
                            </div>
                        </div>
                        <div className="col-12 mx-auto"><p className='pt-2 fs-5'>Activities</p></div>
                        <div className="col-12 mx-auto box-profile p-2 p-md-4">
                            <div className='d-flex justify-content-center align-items-center'  >
                                <img src="/assets/LOGINACTIVITIES.png" alt="" width={100} />
                                <div className='ms-2 ms-md-3'>
                                    <h5 >Login Activities</h5>
                                    <p className='mb-0' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>
                                        Login password is used to login in to your Account
                                    </p>

                                </div>
                            </div>
                            <div>
                                <p style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', color: "#21CA50" }} className='fw-bold d-none d-md-block'>{showEmail(myProfile && myProfile?.email)}</p>
                            </div>
                            <div>
                                <button className='btn-profile shadow' onClick={() => navigate("/device-management")}>Change</button>
                            </div>
                        </div>

                    </div>
                </div>
                <PhonePopUp openPhn={openPhn} setOpenPhn={setOpenPhn} />
                <EmailPopUp openEmail={openEmail} setOpenEmail={setOpenEmail} />
                <ChangePasswordPopUp openPassword={openPassword} setOpenPassword={setOpenPassword} />
            </div>
        </section>
    );
};

export default Profile;