import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import $ from 'jquery';
import { AuthProvider } from './core/context/';
import Home from './Pages/Home';
import Transactionhistory from './Pages/Transactionhistory';
import Market from './Pages/Market';
import SignIn from './Pages/SignIn';
import SignUp from './Pages/SignUp';
import ForgotPassword from './Pages/ForgotPassword';
import ForgotPasswordOTP from './Pages/ForgotPasswordOTP';
import ResetPassword from './Pages/ResetPassword';
import Dashboard from './Pages/Dashboard';
import Faq from './Pages/Faq';

import CMS from './Pages/CMS';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddNewPost from './Pages/Ads/Add-New-Post';
import UpdatePost from './Pages/Ads/Update-Postads';
import OrderDetails from './Pages/OrderDetails';
import BuyUSDT from './Pages/BuyUSDT';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './assets/style.css';
import './assets/custom.css';
import './assets/styledev.css';
import './assets/modes.css';

import AppealHistory from './Pages/AppealHistory.js';
import Addpaymentmethod from './Pages/P2P-Payment-Details/add-payment-method';
import Updatepaymentmethod from './Pages/P2P-Payment-Details/update-payment-method';
import Myadspage from './Pages/Myads.js';
import ProtectedRoute from "./route.protected";
import PostNewAddFinal from "./Pages/PostNewAddFinal";
import PostNewAddStart from "./Pages/PostNewAddStart";
import FeeStructure from "./Pages/Fee-Structure";
import CoinStatus from "./Pages/Coin-Status";


// dashboard profile
import GoogleAuthenticator from "./PagesV2/Dashboard/Authenticator/GoogleAuthenticator.js";
import Spotnew from "./Pages/exchange-trade/Spot";
// dashboard profile 

import Comingsoon from "./Pages/Comingsoon";
import Error404 from "./Pages/Error404";
import LoginActivities from "./PagesV2/Dashboard/LoginActivities/LoginActivities.js";
import PaymentMethod from "./PagesV2/Dashboard/PaymentMethod/PaymentMethod.js";
import WithdrawCrypto from "./PagesV2/Portfolio/WithdrawCrypto.js";
import WithdrawFlatV2 from "./PagesV2/Portfolio/WithdrawFlat.js";
import DepositCrypto from "./PagesV2/Portfolio/DepositCrypto.js";
import DepositFlat from "./PagesV2/Portfolio/DepositFlat.js";
import Contact from "./PagesV2/Contact/Contact.js";
import ApiDocumentation from "./Pages/ApiDocumentation/ApiDocumentation.js";
import MaintainceBreak from "./Component/MaintainceBreak/MaintainceBreak.js";
import Signals from "./PagesV2/Signals/Signals.js";
import SpotChart from "./Pages/exchange-trade/SpotChart.js";
import FuturesTradeWrap from "./Pages/FuturesTradeWrap.js";
import FuturesTradeChart from "./Pages/FuturesTradeChart.js";
import Blogs from "./Pages/Blogs.js";
import BlogsDetails from "./Pages/BlogsDetails.js";
import ScrollToTop from "./Component/ScrollToTop/ScrollToTop.js";
import ConditionalStyles from "./Component/ConditionalStyles/ConditionalStyles.js";
import ThankYou from "./PagesV2/Contact/ThankYou.js";
import InstaTrade from "./Pages/InstaTrade.js";
function App() {
  const mode = localStorage.getItem('mode') ? localStorage.getItem('mode') : 'light-new';

  const [theme, setTheme] = useState(mode);
  const [isBreak, setIsBreak] = useState(false);
  const routes = [
    // common§±
    { path: '/', name: "home", component: <Home setTheme={setTheme} theme={theme} /> },
    // { path: '/sitemap.xml', name: "sitemap", component: <SitemapBuild /> },
    { path: '/faq', component: <Faq setTheme={setTheme} theme={theme} /> },
    { path: '/markets', component: <Market setTheme={setTheme} theme={theme} /> },
    { path: '/signal', authChk: "afterLoginOnly", component: <Signals setTheme={setTheme} theme={theme} /> },
    { path: '/futures/trade/:pairName', component: <FuturesTradeWrap setTheme={setTheme} theme={theme} /> },
    { path: '/futures-chart/:pairName', component: <FuturesTradeChart setTheme={setTheme} theme={theme} /> },
    { path: '/Insta-Trade', authChk: "afterLoginOnly", component: <InstaTrade setTheme={setTheme} theme={theme} /> },
    // { path: '/sellcrypto', authChk: "afterLoginOnly", component: <SellCrypto setTheme={setTheme} theme={theme} /> },
    // { path: '/api', component: <API setTheme = {setTheme} theme={theme} />},
    { path: '/about', component: <CMS setTheme={setTheme} theme={theme} identify="about" /> },
    { path: '/otc_desk', component: <CMS setTheme={setTheme} theme={theme} identify="otc" /> },
    { path: '/contact-support', component: <CMS setTheme={setTheme} theme={theme} identify="contactus" /> },
    { path: '/thank_you', component: <ThankYou setTheme={setTheme} theme={theme} /> },
    { path: '/help-center', component: <Contact setTheme={setTheme} theme={theme} /> },
    // { path: '/terms', component: <CMS setTheme={setTheme} theme={theme} identify="terms" /> },
    // { path: '/privacy-policy', component: <CMS setTheme={setTheme} theme={theme} identify="privacy" /> },
    { path: '/fee-structure', component: <FeeStructure setTheme={setTheme} theme={theme} /> },
    { path: '/coin-status', component: <CoinStatus setTheme={setTheme} theme={theme} /> },
    { path: '/api-docs', component: <ApiDocumentation setTheme={setTheme} theme={theme} /> },

    // user entry
    // { path: '/WelcomeToBinance', authChk:"beforeLoginOnly", component: <WelcomeToBinance setTheme = {setTheme} theme={theme} />},
    { path: '/register', authChk: "beforeLoginOnly", component: <SignUp setTheme={setTheme} theme={theme} /> },
    { path: '/login', authChk: "beforeLoginOnly", component: <SignIn setTheme={setTheme} theme={theme} /> },
    { path: '/ForgotPassword', authChk: "beforeLoginOnly", component: <ForgotPassword setTheme={setTheme} theme={theme} /> },
    { path: '/ForgotPasswordOTP', authChk: "beforeLoginOnly", component: <ForgotPasswordOTP setTheme={setTheme} theme={theme} /> },
    { path: '/ResetPassword', authChk: "beforeLoginOnly", component: <ResetPassword setTheme={setTheme} theme={theme} /> },

    // user
    { path: '/my/wallet/history', authChk: "afterLoginOnly", component: <Transactionhistory setTheme={setTheme} theme={theme} /> },
    { path: '/my/wallet/history/:tabName', authChk: "afterLoginOnly", component: <Transactionhistory setTheme={setTheme} theme={theme} /> },
    { path: '/my/add-payment-method', authChk: "afterLoginOnly", component: <PaymentMethod setTheme={setTheme} theme={theme} /> },
    // { path: '/my/sms-authenticator', authChk: "afterLoginOnly", component: <Phonenumberverificationchange setTheme={setTheme} theme={theme} /> },
    // { path: '/my/email-authenticator', authChk: "afterLoginOnly", component: <Emailverificationchange setTheme={setTheme} theme={theme} /> },
    // { path: '/my/password', authChk: "afterLoginOnly", component: <Changepassworddashboard setTheme={setTheme} theme={theme} /> },
    { path: '/my/:tabName', authChk: "afterLoginOnly", component: <Dashboard setTheme={setTheme} theme={theme} /> },

    { path: '/deposit/crypto/:currencyId', authChk: "afterLoginOnly", component: <DepositCrypto setTheme={setTheme} theme={theme} /> },
    { path: '/deposit/fiat/:currencyId', authChk: "afterLoginOnly", component: <DepositFlat setTheme={setTheme} theme={theme} /> },
    { path: '/deposit', authChk: "afterLoginOnly", component: <DepositCrypto setTheme={setTheme} theme={theme} /> },
    { path: '/withdraw/fiat/:currencyId', authChk: "afterLoginOnly", component: <WithdrawFlatV2 setTheme={setTheme} theme={theme} /> },
    { path: '/withdraw/crypto/:currencyId', authChk: "afterLoginOnly", component: <WithdrawCrypto setTheme={setTheme} theme={theme} /> },
    { path: '/withdraw', authChk: "afterLoginOnly", component: <WithdrawCrypto setTheme={setTheme} theme={theme} /> },
    // { path: '/security', authChk:"afterLoginOnly", component: <Security setTheme = {setTheme} theme={theme} />},
    { path: '/google-authenticator', authChk: "afterLoginOnly", component: <GoogleAuthenticator setTheme={setTheme} theme={theme} /> },
    // { path: '/sportclone', authChk:"afterLoginOnly", component: <SportClone setTheme = {setTheme} theme={theme} />},
    { path: '/device-management', component: <LoginActivities setTheme={setTheme} theme={theme} /> },
    // new p2p updated design check this, its just only for reference
    // { path: '/p2ptradenewdesignupdate', authChk: "beforeLoginOnly", component: <P2Ptradenewdesignupdate setTheme={setTheme} theme={theme} /> },
    // p2p
    // { path: '/P2P', component: <P2P setTheme={setTheme} theme={theme} /> },
    // { path: '/p2p-orders-list', component: <Orderp2ptrade setTheme={setTheme} theme={theme} /> },
    // { path: '/p2p-user-center', authChk: "afterLoginOnly", component: <P2pusercenter setTheme={setTheme} theme={theme} /> },
    // { path: '/p2p-advertiser-user-center/:advertiserNo', authChk: "afterLoginOnly", component: <P2PAdvertiserDetails setTheme={setTheme} theme={theme} /> },
    { path: '/payment/add/:paymentId', authChk: "afterLoginOnly", component: <Addpaymentmethod setTheme={setTheme} theme={theme} /> },
    { path: '/payment/edit/:paymentId', authChk: "afterLoginOnly", component: <Updatepaymentmethod setTheme={setTheme} theme={theme} /> },
    { path: '/myads', authChk: "afterLoginOnly", component: <Myadspage setTheme={setTheme} theme={theme} /> },
    { path: '/edit-myads/:editId', authChk: "afterLoginOnly", component: <UpdatePost setTheme={setTheme} theme={theme} /> },
    { path: '/postAd', authChk: "afterLoginOnly", component: <AddNewPost setTheme={setTheme} theme={theme} /> },
    { path: '/order-details/:orderId', authChk: "afterLoginOnly", component: <OrderDetails setTheme={setTheme} theme={theme} /> },
    { path: '/appeal-history/:orderId', authChk: "afterLoginOnly", component: <AppealHistory setTheme={setTheme} theme={theme} /> },
    { path: '/buyusdt', authChk: "afterLoginOnly", component: <BuyUSDT setTheme={setTheme} theme={theme} /> },

    { path: '/PostNewAddFinal', component: <PostNewAddFinal setTheme={setTheme} theme={theme} /> },
    { path: '/postnewaddstart', component: <PostNewAddStart setTheme={setTheme} theme={theme} /> },
    { path: '/blogs', component: <Blogs setTheme={setTheme} theme={theme} identify="dyn" /> },
    { path: '/blog/:link', component: <BlogsDetails setTheme={setTheme} theme={theme} identify="dyn" /> },

    { path: '/spot/:pairName', component: <Spotnew setTheme={setTheme} theme={theme} /> },
    { path: '/spot-chart/:pairName', component: <SpotChart setTheme={setTheme} theme={theme} /> },

    { path: '/comingsoon', component: <Comingsoon setTheme={setTheme} theme={theme} /> },
    { path: '/error404', component: <Error404 setTheme={setTheme} theme={theme} /> },
    { path: '/:dynCMS', component: <CMS setTheme={setTheme} theme={theme} identify="dyn" /> },
    // { path: '*', component: <Error404 setTheme = {setTheme} theme={theme} />},
  ];

  useEffect(() => {

    if ($('#themesection-body').hasClass('light-new')) {
      $('body').addClass('light-new');
      $('body').removeClass('dark-new');
    } else {
      $('body').addClass('dark-new');
      $('body').removeClass('light-new');
    }


    if (window.location.pathname === "/") {
      setTheme("light-new");
      $("#classy-navbar-mobile").css("background-color", "transparent");
      $(".theme-mode-dropdown").hide();
    }
  }, [theme]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <AuthProvider>
      <div className={theme} id="themesection-body">
        <ToastContainer />
        <Router>
          <ScrollToTop />
          <ConditionalStyles />
          {isBreak ? <MaintainceBreak /> :
            <Routes>
              {routes.map((data, index) => {
                return <Route
                  element={<ProtectedRoute authChk={
                    data.authChk ? data.authChk : ""
                  } />}
                >
                  <Route
                    onUpdate={() => window.scrollTo(0, 0)}
                    exact={true}
                    path={data.path}
                    element={data.component}
                    key={index}
                  />
                </Route>
              })}
            </Routes>
          }
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;