import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageTitleDes = ({ title, description }) => {
    // console.log("t,d", title, description);
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={`${description}`} />
        </Helmet>
    );
};

export default PageTitleDes;