import React, { useState, useEffect } from 'react'
import './LoginActivities.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';

import Config from "../../../core/config/";
import { makeRequest } from "../../../core/services/v1/request";
import { dateFormat } from '../../../core/helper/date-format';

import {useNavigate } from "react-router-dom";
import Pagination from 'react-responsive-pagination';
import '../../../pagination.css';
import NavbarOne from '../../../Pages/separate/NavbarOne';

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "26px",
    fontWeight: "600",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white"
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "white"
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white"
}));

const LoginActivities = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [loginActivities, setloginActivities] = useState([]);
    const [recordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [activitiesTotal, setactivitiesTotal] = useState(0);


    useEffect(() => {
        getLoginActivities();
    }, []);

    async function getLoginActivities() {
        try {
            const data = {
                limit: recordsPerPage,
                offset: 0
            }
            const params = {
                url: `${Config.V2_API_URL}user/loginHistory`,
                method: "POST",
                body: data
            };
            const response = await makeRequest(params);
            if (response.status && response.history) {
                setloginActivities(response.history);
                setactivitiesTotal(response.activitiesTotal)
            }
        } catch (err) { }
    }
    const noOfPages = Math.ceil(activitiesTotal / recordsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= noOfPages; i++) {
        pageNumbers.push(i);
    }
    const prevPage = () => {
        pageChange(currentPage - 1);
    }
    const clickPageNo = (pgNumber) => {
        pageChange(pgNumber);
    }
    const nextPage = () => {
        pageChange(currentPage + 1);
    };
    const pageChange = (newCurrentPage) => {
        if (newCurrentPage >= 1 && newCurrentPage <= noOfPages) {
            setCurrentPage(newCurrentPage);
            getPagination(newCurrentPage)
        }
    }
    async function getPagination(
        newCurrentPage
    ) {
        try {
            const data = {
                limit: recordsPerPage,
                offset: recordsPerPage * (newCurrentPage - 1)
            }
            const params = {
                url: `${Config.V2_API_URL}user/loginHistory`,
                method: "POST",
                body: data
            };
            const response = await makeRequest(params);
            if (response.status && response.history) {
                setloginActivities(response.history);
            }
        } catch (err) { }
    }
    return (
        <section className='bg-default min-vh-100 text-white bg-particular'>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className='container pb-5 pt-2'>
                <div>
                    <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Security</strong></h4>
                    <p className='pb-2 pt-3 fs-4' style={{ color: "#10EDB6", fontWeight: 600 }}>Login Activates</p>
                    <p className='pb-2 fs-5'>Your login activities details listed</p>
                </div>

                <div className='box-login-activity my-3 mx-auto'>
                    <TableContainer >
                        <Table sx={{ minWidth: window.innerWidth < 700 ? 700 : 100 }} aria-label="simple table">
                            <StyledTableHead>
                                <TableRow>
                                    <StyledTableHeadRow>Device</StyledTableHeadRow>
                                    <StyledTableHeadRow>Date</StyledTableHeadRow>
                                    <StyledTableHeadRow>Location</StyledTableHeadRow>
                                    <StyledTableHeadRow>IP Address</StyledTableHeadRow>
                                </TableRow>
                            </StyledTableHead>
                            <TableBody>
                                {loginActivities.length > 0 ?
                                    loginActivities.map((row, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <StyledTableCell component="th" scope="row">{row.browser}</StyledTableCell>
                                                <StyledTableCell>{dateFormat(row.dateTime)}</StyledTableCell>
                                                <StyledTableCell>{row.ipDetails != "" ? JSON.parse(row?.ipDetails)?.city : "-"}</StyledTableCell>
                                                <StyledTableCell>{row?.ip != "" ? row.ip : "-"}</StyledTableCell>
                                            </TableRow>
                                        )
                                    }) : <TableRow >
                                        <StyledTableCell component="th" scope="row"></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell><img
                                            className="no-record-image"
                                            src="/assets/images/deposit/no-re.png"
                                            alt="no-record"
                                        /></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className='mt-2'>
                        <Pagination className="spot_history_pagination"
                            total={Math.ceil(activitiesTotal / recordsPerPage)}
                            current={currentPage}
                            onPageChange={page => clickPageNo(page)}
                        />
                    </div>
                </div>

            </div>
        </section>
    );
};

export default LoginActivities;