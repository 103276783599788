import React, { useEffect } from 'react';
import NavbarOne from "./separate/NavbarOne";
import error from "../assets/images/404.png";
import "../assets/styledev.css";

import Error404Content from './Error404Content';

export default function Error404(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [])
  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <Error404Content />
    </div>
  )
}
