import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useContextData } from '../../core/context/index'
import { getCookie } from '../../core/helper/cookie'
import jwt_decode from 'jwt-decode';
import Header from "./Header/Header";
import HeaderHome from "./Header/HeaderHome.js";

export default function NavbarOne(props) {
    const { siteSettings } = useContextData();

    const [userLogin, setUserLogin] = useState(false);

    const loginChk = async (event) => {
        const token = getCookie("userToken");
        const decodedToken = token ? jwt_decode(token, { header: true }) : {};
        if (decodedToken.typ === 'JWT') {
            setUserLogin(true);
        } else {
            $(".dashboardbutton").hide();
        }
    }

    useEffect(() => {
        if ($('body').hasClass('dark-new')) {
            $("#checkBoxasad").prop('checked', true);
        } else {
            $("#checkBoxasad").prop('checked', false);
        }


        var switchStatus = false;
        $("#checkBoxasad").on('change', function () {
            if ($(this).is(':checked')) {
                switchStatus = $(this).is(':checked');
                const mode = "dark-new";
                props.setTheme(mode);
                localStorage.setItem('mode', mode);
            }
            else {
                switchStatus = $(this).is(':checked');
                const mode = "light-new";
                props.setTheme(mode);
                localStorage.setItem('mode', mode);
            }
        });
        loginChk();
    }, []);


    return (
        <>
            {(window.location.pathname === "/") ? <HeaderHome siteSettings={siteSettings} userLogin={userLogin} /> : <Header siteSettings={siteSettings} userLogin={userLogin} />}
        </>
    )
}
