import React from 'react';
import FuturesTrade from './FuturesTrade';
import { useNavigate, useParams } from 'react-router-dom';

const FuturesTradeWrap = () => {
    const { pairName } = useParams();
    const navigate = useNavigate();

    const handleNavigation = (newSymbol) => {
        // setOrderBookData(null);
        // popupState.close();
        navigate('/futures/trade/' + newSymbol);
    };
    return (
        <div>
            <FuturesTrade handleNavigation={handleNavigation} key={pairName} pairName={pairName} />
        </div>
    );
};

export default FuturesTradeWrap;