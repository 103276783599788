import React from 'react';
import loadAnimation from '../../assets/images/LoaderAnimationV2.gif';

const LoaderUnitic = ({width=35}) => {
    return (
        <div className='d-flex justify-content-center align-items-center h-100' style={{background:'transparent'}}>
        <img src={loadAnimation} alt="loaderAnimationV2" className='img-fluid' width={width} style={{background:'transparent'}}/>
    </div>
    );
};

export default LoaderUnitic;