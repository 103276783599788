import React from 'react';
import { useLocation } from 'react-router-dom';

function ConditionalStyles() {
  const location = useLocation();

  // Check if the current path is not the home page
  const isHomePage = location.pathname === '/';

  return (
    <style>
      {isHomePage
        ? ''
        : `.btn-close { filter: invert(1) grayscale(100%) brightness(200%); }`}
    </style>
  );
}

export default ConditionalStyles;
