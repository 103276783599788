import React from 'react';
import './EachDataComponent.css';
import { Toolbar } from '@mui/material';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { atelierCaveDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import { atomDark, materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierCaveDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from '../../../core/lib/toastAlert';

const GetDoc = ({ data, id }) => {

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast({ type: "success", message: "Copied to clipboard!" });
    };
    return (
        <div id={id} className='code-container'>
            <Toolbar />
            <div className='row getGap'>
                <div className='col-12 col-md-6 mt-3'>
                    <h4 className='headingEndTitle'>{data?.tab}</h4>
                    <p>{data?.des}</p>
                    <br />
                    <p style={{ fontSize: '15px' }} className='fw-bold'>HTTP Request</p>
                    <p className='pt-2'><code className='content-code'>{`${data?.meth} ${data?.url}`}</code></p>
                </div>
                {/* style={{ background: 'rgb(25, 23, 28)', minHeight: '90vh' }} */}
                <div className="col-12 col-md-6" >
                    <div className="code-header">
                        <span>
                            <blockquote><p className='fw-bold' style={{ fontSize: '14px' }}>JS:</p>
                            </blockquote>
                            <SyntaxHighlighter language="javascript" style={atelierCaveDark} className='code-block-v2'>
                                {`${data?.code}`}
                            </SyntaxHighlighter>
                        </span>
                        <button onClick={() => copyToClipboard(data?.code)}><ContentCopyIcon /></button>
                    </div>

                    <div className="code-header">
                        <span>
                            <blockquote><p className='fw-bold' style={{ fontSize: '14px' }}>JSON Response:</p></blockquote>
                            <SyntaxHighlighter language="json" style={atelierCaveDark} className='code-block-v2'>
                                {data?.json}
                            </SyntaxHighlighter>
                        </span>
                        <button onClick={() => copyToClipboard(data?.json)}><ContentCopyIcon /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetDoc;