import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoaderSpin = () => {
    return (
        <div className='d-flex justify-content-center align-items-center h-100' style={{background:'transparent'}}>
            <Spinner animation="border" variant="light"/>
        </div>
    );
};

export default LoaderSpin;