import React, { useState, useEffect } from "react";

const CountDown = ({ startTime, status = '0' }) => {
    const [timeLeft, setTimeLeft] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const startTimestamp = new Date(startTime).getTime();
        const duration = 24 * 60 * 60 * 1000;

        const updateTimer = () => {
            const currentTime = new Date().getTime();
            const timePassed = currentTime - startTimestamp;
            const timeRemaining = duration - timePassed;

            if (timeRemaining <= 0) {
                setTimeLeft({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });
            } else {

                const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
                const seconds = Math.floor((timeRemaining / 1000) % 60);

                setTimeLeft({
                    hours,
                    minutes,
                    seconds,
                });
            }
        };
        if (status == '0') {
            updateTimer();
            const interval = setInterval(updateTimer, 1000);

            return () => clearInterval(interval);
        }
    }, [startTime, status]);

    return (
        <div>
            <p style={{ color: (timeLeft?.hours == 0 && timeLeft?.minutes == 0 && timeLeft?.seconds == 0) ? '' : '#25e05a' }}>
                {timeLeft?.hours}h : {timeLeft?.minutes}m : {timeLeft?.seconds}s
            </p>
        </div>
    );
};

export default CountDown;