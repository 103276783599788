import React, { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
} from "react-router-dom";

import NavbarOne from "./separate/NavbarOne";
import BankPayment from "./BankPayment";
import Voucher from "./Voucher";

import Inner404 from "./separate/Inner404";

import { toast, toastDismiss } from "../core/lib/toastAlert";
import { useContextData } from "../core/context/index";
import { isEmpty } from "../core/helper/common";
import Identification from "../PagesV2/Dashboard/Identification/Identification";
import TradeHistory from "../PagesV2/Dashboard/TradeHistory/TradeHistory";
import Profile from "../PagesV2/Dashboard/Profile/Profile";
import PhonePopUp from "../PagesV2/Dashboard/Authenticator/PhonePopUp";
import EmailPopUp from "../PagesV2/Dashboard/Authenticator/EmailPopUp";
import ReferralWrap from "../PagesV2/Dashboard/Referral/ReferralWrap";
import Portfolio from "../PagesV2/Portfolio/Portfolio";

export default function Dashboard(props) {
  const { myProfile, setUserProfile } = useContextData();

  const navigate = useNavigate();
  let { tabName } = useParams();
  const [openPhn, setOpenPhn] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [tabnameNew, setTabnameNew] = useState("");
  const statusChk = async () => {
    
    if (myProfile && myProfile._id) {
      if (isEmpty(myProfile.email)) {
        toastDismiss();
        toast({ type: "error", message: "Please update your email address." });
        // navigate("/my/email-authenticator");
        setOpenEmail(() => true);
      }
      else if (isEmpty(myProfile.phoneno)) {
        toastDismiss();
        toast({ type: "error", message: "Please update your phone number." });
        // navigate("/my/sms-authenticator");
        setOpenPhn(() => true);
      }
      else if (tabnameNew == "payment" && (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1)) {
        toastDismiss();
        toast({
          type: "error",
          message: "Please complete your KYC to continue.",
        });
        navigate("/my/identification");
      }

    }
  };

  useEffect(() => {
    setTabnameNew(tabName);
    if (tabName == "profile" && (myProfile?.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1)) {
      toast({
        type: "error",
        message: "Please complete your KYC to continue.",
      });
      navigate("/my/identification");
    }
  }, [tabName]);

  useEffect(() => {
    if (
      tabnameNew == "identification" ||
      tabnameNew == "payment" ||
      tabnameNew == "voucher"
    ) {
      setUserProfile();
    }
  }, [tabnameNew]);

  useEffect(() => {
    if (tabnameNew == "identification" || tabnameNew == "payment") {
      statusChk();
    }

  }, [myProfile]);

  return (<div className="bg-default min-vh-100 text-white bg-particular">
    <NavbarOne setTheme={props.setTheme} theme={props.theme} />
    <div className={`${tabnameNew === "dashboard"?'':'container'}`}>
      <div class="tab-content" id="v-pills-tabContent">
        <div
          class={
            "tab-pane fade " +
            (tabnameNew === "dashboard" ? "show active" : "")
          }
        >
          {tabnameNew === "dashboard" && <Portfolio />}
        </div>
        <div
          class={
            "tab-pane fade " +
            (tabnameNew === "profile" ? "show active" : "")
          }
        >
          {tabnameNew === "profile" && <Profile />}
        </div>
        <div
          class={
            "tab-pane fade " +
            (tabnameNew === "identification" ? "show active" : "")
          }
        >
          <Identification tabnameNew={tabnameNew} />
        </div>
        <div
          class={
            "tab-pane fade " +
            (tabnameNew === "payment" ? "show active" : "")
          }
        >
          {tabnameNew === "payment" && <BankPayment />}
        </div>
        <div
          class={
            "tab-pane fade " +
            (tabnameNew === "referral" ? "show active" : "")
          }
        >
          {tabnameNew === "referral" && <ReferralWrap />}
        </div>
        <div
          class={
            "tab-pane fade " +
            (tabnameNew === "voucher" ? "show active" : "")
          }
        >
          {tabnameNew === "voucher" && <Voucher />}
        </div>

        <div
          className={
            "tab-pane fade " +
            (tabnameNew &&
              tabnameNew.toLowerCase() === "spottradehistory"
              ? "show active"
              : "")
          }
        >
          {tabnameNew === "SpotTradeHistory" && <TradeHistory />}
        </div>
        <div
          className={
            "tab-pane fade " +
            (tabnameNew &&
              tabnameNew !== "dashboard" &&
              tabnameNew !== "profile" &&
              tabnameNew !== "identification" &&
              tabnameNew !== "payment" &&
              tabnameNew !== "referral" &&
              tabnameNew !== "voucher" &&
              tabnameNew.toLowerCase() !== "spottradehistory"
              ? "show active"
              : "")
          }
        >
          {tabnameNew &&
            tabnameNew !== "dashboard" &&
            tabnameNew !== "profile" &&
            tabnameNew !== "identification" &&
            tabnameNew !== "payment" &&
            tabnameNew !== "referral" &&
            tabnameNew !== "voucher" &&
            tabnameNew.toLowerCase() !== "spottradehistory" && (
              <Inner404 />
            )}
        </div>
      </div>
      <PhonePopUp openPhn={openPhn} setOpenPhn={setOpenPhn} />
      <EmailPopUp openEmail={openEmail} setOpenEmail={setOpenEmail} />
    </div>
  </div>
  );
}
