import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import Loader from '../../../Component/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import FavStar from './FavStar';
import { useContextData } from '../../../core/context';
import DataTable from './DataTable';
import config from '../../../core/config';

export default function FuturesMenu({ pairsData: defData, pairsLoader, pairName, setOrderBookData, updateFavPairs, formatSymbolName, pairDetails, favs, handleNavigation }) {
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    const [tabName, setTabName] = React.useState(2);
    const [search, setSearch] = React.useState('');
    const [pairsData, setPairsData] = React.useState([]);
    const [currentData, setCurrentData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const itemsPerPage = 10;

    React.useEffect(() => {
        fetchInitialData();
    }, []);

    React.useEffect(() => {
        const handleData = () => {
            if (pairsData.length > 0) {
                if (!search) {
                    setCurrentData(pairsData.slice(0, (page + 1) * itemsPerPage));
                } else {
                    const filteredData = defData.filter((data) =>
                        data.symbol.toLowerCase().includes(search.toLowerCase())
                    );
                    setCurrentData(filteredData.slice(0, (page + 1) * itemsPerPage));
                }
            }
        };
        handleData();
    }, [search, pairsData, page, defData]);

    const onSearch = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const fetchInitialData = async () => {
        const initialData = await fetchMoreData(0, itemsPerPage);
        // setPairsData(initialData);
        setPairsData(() => {
            const uniqueData = Array.from(new Map(initialData.map(item => [item.symbol, item])).values());
            return uniqueData;
        });
    };

    const fetchMoreData = async (page, itemsPerPage) => {

        const response = await fetch(`${config.V2_API_URL}trade-futures/get-all-pairs?page=${page}&limit=${itemsPerPage}`);
        const newData = await response.json();
        return newData?.pairs || [];
    };

    const loadMoreData = async () => {
        if (tabName === 1) {
            // Load more data logic for favs
            // const moreData = await fetchMoreData(page + 1, itemsPerPage);
            // setFavs((prevFavs) => [...prevFavs, ...moreData]);
        } else {

            const moreData = await fetchMoreData(page + 1, itemsPerPage);
            // setPairsData((prevPairsData) => [...prevPairsData, ...moreData]);
            setPairsData((prevPairsData) => {
                const combinedData = [...prevPairsData, ...moreData];
                const uniqueData = Array.from(new Map(combinedData.map(item => [item.symbol, item])).values());
                return uniqueData;
            });
        }
        setPage((prevPage) => prevPage + 1);
    };

    // const onSearch = (e) => {
    //     setSearch(() => e.target.value)
    //     const pairFiler = pairsData?.filter(data => ((data?.symbol)?.toLowerCase())?.includes(((e.target.value).toLowerCase())?.toLowerCase()))
    //     setCurrentData(() => pairFiler);
    // }
    return (
        <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
                <div>
                    <Button variant="contained" {...bindTrigger(popupState)} endIcon={<KeyboardArrowDownIcon />} sx={{
                        background: 'transparent',
                        fontSize: '1rem',
                        fontFamily: "'Roboto', sans-serif",
                        padding: '0px',
                        fontWeight: 600,
                        boxShadow: 'none',
                        '&.MuiButton-root:hover': {
                            background: 'transparent',
                            boxShadow: 'none',
                        }
                    }} className='text-capitalize'>
                        {/* {removeSwapSuffix(pairName).split('-')} */}
                        {formatSymbolName(pairDetails?.data?.baseCurrency, pairDetails?.data?.quoteCurrency)}
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        onClose={popupState.close}
                    >
                        <Typography sx={{
                            p: 3, bgcolor: '#2E2C2C', color: 'white', width: '500px', height: '500px',
                            overflow: 'hidden',
                            '@media (max-width: 520px)': {
                                width: '100% !important',
                            },
                        }}>
                            <div className='handleFuturesSearchBar'>
                                <input placeholder='Search' value={search} onChange={onSearch} />
                            </div>
                            <div className='handleFuturesOption'>
                                <p className={`${tabName == 1 ? 'color-green-futures color-transition' : 'color-transition'}`} onClick={(() => setTabName(1))}>Favorites</p>
                                <p className={`${tabName == 2 ? 'color-green-futures color-transition' : 'color-transition'}`} onClick={(() => setTabName(2))}>USDT</p>
                                {/* <p>COIN M</p> */}
                            </div>
                            {/* <div className='handleFuturesOptionSecond'>
                                <p>All</p>
                                <p>USDC</p>
                                <p>Al</p>
                                <p className='color-green-futures'>BIT Coin ECO</p>
                                <p>Cross Pair</p>
                                <p>DAO</p>
                                <p>DeFi</p>
                                <p>DEX</p>
                                <p>Index</p>
                            </div> */}
                            <Divider />
                            <div className='handleFuturesOptionThird'>

                                <TableContainer component={Paper} sx={{
                                    bgcolor: 'transparent', boxShadow: 'none', height: '350px', overflow: 'auto', '&::-webkit-scrollbar': {
                                        display: 'none'
                                    }
                                }}>
                                    <Table sx={{ width: '100%' }} size="small" aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '12px', backgroundColor: '#2E2C2C', padding: '6px 0px' }} >Contract</TableCell>
                                                <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '12px', backgroundColor: '#2E2C2C', padding: '6px 0px' }}>Last price</TableCell>
                                                <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '12px', backgroundColor: '#2E2C2C', padding: '6px 0px' }}>Change</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pairsLoader ? <Loader /> : (!pairsLoader && pairsData.length <= 0) ? <TableRow
                                                sx={{ border: 0 }}
                                            >
                                                <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}></TableCell>
                                                <TableCell className='color-green-futures' sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}>No Content Found</TableCell>
                                                <TableCell className='color-red-futures' sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}></TableCell>
                                                <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}></TableCell>
                                            </TableRow> : (tabName == 1 && (!myProfile?.futuresFav || !favs)) ? <TableRow sx={{ border: 0 }}>
                                                <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}></TableCell>
                                                <TableCell className='color-green-futures' sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}>No Data Found</TableCell>
                                                <TableCell className='color-red-futures' sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}></TableCell>
                                                <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', padding: '6px 0px' }}></TableCell>
                                            </TableRow> : <DataTable
                                                data={tabName === 1 ? favs : currentData}
                                                loadMoreData={loadMoreData}
                                                handleNavigation={handleNavigation}
                                                formatSymbolName={formatSymbolName}
                                                updateFavPairs={updateFavPairs}
                                                favs={favs}
                                            />
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Typography>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
}