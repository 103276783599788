import React from 'react';

const TradingData = () => {
    return (
        <div className='row g-5 pt-4'>
            <div className='col-12 col-md-6 col-lg-6 col-xl-4'><div className='w-100'><img src='/assets/chartGrp.png' alt='' className='img-fluid' /></div></div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-4'><div className='w-100'><img src='/assets/chartGrp.png' alt='' className='img-fluid' /></div></div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-4'><div className='w-100'><img src='/assets/chartGrp.png' alt='' className='img-fluid' /></div></div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-4'><div className='w-100'><img src='/assets/chartGrp.png' alt='' className='img-fluid' /></div></div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-4'><div className='w-100'><img src='/assets/chartGrp.png' alt='' className='img-fluid' /></div></div>
            <div className='col-12 col-md-6 col-lg-4'><div className='w-100'><img src='/assets/chartGrp.png' alt='' className='img-fluid' /></div></div>
        </div>
    );
};

export default TradingData;