import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import '../../assets/style.css';
import { Button } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { useContextData } from '../../core/context';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        },
    },
    elements: {
        point: {
            radius: 0,
        },
        line: {
            tension: 0,
        },
    },
    layout: {
        padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
        },
    },
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => Math.random({ min: -1000, max: 1000 })),
            borderColor: "red",
            backgroundColor: 'red',

        }
    ],
};
const chartStyles = {
    width: "100px",
    overflow: "hidden"
};

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontSize: "20px",
    fontWeight: "600",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    textAlign: "right",
    width: "150px !important",

}));
const StyledTableHeadRowFirst = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontSize: "20px",
    fontWeight: "600",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    textAlign: "left",
    width: "150px !important",
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    color: "#000000",
    textAlign: "right",
    width: "50px !important",

}));
const StyledTableCellFirst = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    color: "#000000",
    textAlign: "left",
    width: "50px !important",

}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "50px !important",
}));

const MarketTable = ({ marketValues, marketCurrency }) => {
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const dynamicClass = (currentUrl === "http://localhost:3000/" || currentUrl === "https://uniticexchange.com/") ? true : false;
    const { coindcxDecimal } = useContextData();

    const labels = ['1st day', '2nd day', '3rd day'];
    const getTheChart = (get) => {
        let val = get?.price ? (get?.price).toFixed(get?.decimalValue) : get?.price;
        // console.log("val", val, Math.random({ min: -1000, max: 1000 }));
        const datas = {
            labels,
            datasets: [
                {
                    label: "Dataset 1",
                    data: labels.map(() => val),
                    borderColor: "red",
                    backgroundColor: 'red',
                    yAxisID: 'y',

                }
            ],
        };

        return <div style={chartStyles}>
            <Line options={options} data={datas} redraw={true} />
        </div>
    }
    return (
        <div className='designBox my-3' style={{ backgroundColor: 'white' }}>
            {marketValues[marketCurrency].length > 0 ?
                <TableContainer sx={{
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: dynamicClass ? '#21CA50' : '#20b3e0;',
                    },
                }}>
                    <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 500 }} aria-label="simple table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableHeadRowFirst>Market</StyledTableHeadRowFirst>
                                <StyledTableHeadRow>Price</StyledTableHeadRow>
                                <StyledTableHeadRow>24h High</StyledTableHeadRow>
                                <StyledTableHeadRow>24h Vol</StyledTableHeadRow>
                                <StyledTableHeadRow>24h Change</StyledTableHeadRow>
                                <StyledTableHeadRow></StyledTableHeadRow>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                                marketValues[marketCurrency]?.map((data, index) => {
                                    const coindcxDecimalSingle = coindcxDecimal[data?.pair.split('_').join('')]
                                    return (
                                        <TableRow key={index} className={`${data?.pair === 'QDT_INR' ? 'd-none' : ' '}`}>
                                            <StyledTableCellFirst component="th" scope="row" onClick={() => navigate("/spot/" + data?.pair)} style={{ cursor: 'pointer' }}>

                                                {data?.fromCurrency && data?.fromCurrency.image && <img src={data?.fromCurrency.image} className="icon-size-forul-li-img" alt="logo" />} {data?.pair && data?.pair.split('_').join('/')}
                                            </StyledTableCellFirst>
                                            <StyledTableCell className='text-success'>
                                                {data?.price ? (data?.price).toFixed(coindcxDecimalSingle?.base_currency_precision || 5) : data?.price}
                                            </StyledTableCell>
                                            <StyledTableCell >
                                                {data?.price ? (data?.high).toFixed(coindcxDecimalSingle?.base_currency_precision || 5) : data?.price}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {data?.volume ? (data?.volume).toFixed(2) : (data?.volume).toFixed(2)}
                                            </StyledTableCell>
                                            <StyledTableCell className={`${data.change >= 0 ? 'text-success' : 'text-danger'}`}>{data.change >= 0 ? "+" + (data?.change).toFixed(2) : (data?.change).toFixed(2)} %</StyledTableCell>
                                            {/* <StyledTableCell>
                                        {getTheChart(data)}

                                    </StyledTableCell> */}
                                            <StyledTableCell>{dynamicClass ? <Button variant="contained" style={{ background: '#21CA50' }} className='border text-white' onClick={() => navigate("/spot/" + data?.pair)}>Trade</Button> : <Button variant="light" className='border text-primary' onClick={() => navigate("/spot/" + data?.pair)}>Trade</Button>}</StyledTableCell>
                                        </TableRow>

                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer> : <Loader />
            }
        </div>
    );
};

export default MarketTable;