import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import * as yup from 'yup';
import { useFormik } from 'formik';

import { useContextData } from '../../../core/context/index'
import { makeRequest } from "../../../core/services/v1/request";
import { toast } from "../../../core/lib/toastAlert";
import Config from "../../../core/config/";
import { showEmail, showPhone } from '../../../core/helper/date-format';

import phone from 'phone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ThemeProvider, createTheme } from '@mui/material';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './popup.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PhonePopUp = ({ openPhn, setOpenPhn }) => {
  const { myProfile, setUserProfile} = useContextData();
  const [newPhoneno, setnewphoneNo] = useState('');
  const [newphoneno_err, setnewphonenoerr] = useState('');

  const validationSchema = yup.object().shape({
    newPhoneOTP: yup
      .string('Please enter your SMS authentication code')
      .required('Please enter your SMS authentication code')
      .max(6, 'Please enter the correct SMS code'),
    oldEmailOTP: (myProfile && myProfile.email !== "" && myProfile.email !== undefined)
      ?
      yup
        .string('Please enter a 6-digit verification code.')
        .required('Enter the 6-digit code')
      :
      yup.string(),
    oldPhonenoOTP: (myProfile && myProfile.phoneno !== "" && myProfile.phoneno !== undefined)
      ?
      yup
        .string('Please enter a 6-digit verification code.')
        .required('Enter the 6-digit code')
        .max(6, 'Please enter the correct SMS code')
      :
      yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      newPhoneno: '',
      newPhoneOTP: '',
      oldEmailOTP: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.newPhoneno = '+' + newPhoneno;
      let phn = '+' + newPhoneno;
      let payload = {
        pageName: "changePhoneNumber",
        newPhonenoOTP: values.newPhoneOTP,
        newPhoneno: values.newPhoneno,
        oldPhonenoOTP: values.oldPhonenoOTP,
        oldEmailOTP: values.oldEmailOTP
      }
      if ((phn == "") || (typeof phn == "undefined") || (typeof phn == undefined)) {
        setnewphonenoerr("Please enter a valid phone number.");
        return false;
      } else {
        const phoneDetail = phone(phn, { country: "" });
        if (phoneDetail.isValid === false) {
          setnewphonenoerr("Invalid phone number, Please enter correct phone number");
          return false;
        }
        payload.phonecode = phoneDetail.countryCode ? phoneDetail.countryCode : "";
        payload.country = phoneDetail.countryIso3 ? phoneDetail.countryIso3 : phoneDetail.countryIso2;
      }
      setnewphonenoerr("");

      const params = {
        url: `${Config.V2_API_URL}user/changeVerificationDetail`,
        method: 'POST',
        data: payload
      }
      const response = (await makeRequest(params));
      if (response.status) {
        let type = "success";
        toast({ type, message: response.message });
        setUserProfile();
        // navigate("/my/profile");
        handleClose();
      } else {
        let type = 'error';
        toast({ type, message: response.message });
      }
    },
  });

  async function getCode(data, code = "") {
    try {
      let payload = {};
      if (data.target == "newPhoneOTP") {
        const phoneDetail = phone(newPhoneno, { country: "" });
        if (phoneDetail.isValid === false) {
          setnewphonenoerr("Invalid phone number, Please enter correct phone number");
          return false;
        }
        payload = {
          pageName: "changePhoneNumber",
          newPhoneno: newPhoneno,
          newPhoneOTP: code,
        }
      } else if (data.target == "oldPhoneOTP") {
        payload = {
          pageName: "changePhoneNumber",
        }
      } else if (data.target == "newEmailOTP") {
        payload = {
          pageName: "changePhoneNumber",
          oldEmailOTP: code
        }
      } else if (data.target == "oldEmailOTP") {
        payload = {
          pageName: "changePhoneNumber",
          oldEmailOTP: code
        }
      }

      payload.target = data.target;
      const params = {
        url: `${Config.V2_API_URL}otp/getCode`,
        method: 'POST',
        data: payload
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = "success";
      }
      toast({ type, message: response.message });
    } catch (err) { }
  }

  useEffect(() => {
    // if ((newPhoneno == "") || (typeof newPhoneno == "undefined") || (typeof newPhoneno == undefined)) {
    //   setnewphonenoerr("Enter your phone number");
    // } 
    // else if(validator.isMobilePhone(newPhoneno) === false){
    //   setnewphonenoerr("Invalid phone number, Please enter correct phone number");
    // }
    // else {
    //   setnewphonenoerr("");
    // }
  }, [newPhoneno])

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const handleClickOpen = () => {
    setOpenPhn(true);
  };

  const handleClose = () => {
    setOpenPhn(false);
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={openPhn}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className='mx-auto'
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>{`${myProfile?.phoneno == '' ? "Enable" : "Change"} phone Number verification`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <p className='label-text mb-2'>New phone Number</p>
                <InputGroup className="mb-3" size='sm'>
                
                  <PhoneInput
                    international
                    name="newPhoneno"
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    className='form-popup form-control d-flex justify-content-start align-items-center text-light'
                    style={{ gap: "5px" }}
                    onChange={setnewphoneNo}
                    value={formik.values.newPhoneno}
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                {newphoneno_err ? <p className='text-danger mb-2'><small> {formik.errors.newPhoneOTP}</small>{newphoneno_err}</p> : null}
                <p className='label-text mb-2'>New phone Number Verification</p>
                <InputGroup className="mb-1" size='sm'>
                  <Form.Control
                    placeholder=""
                    type='number'
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className='form-popup'
                    id="newPhoneOTP"
                    name='newPhoneOTP'
                    autoComplete='off'
                    // disabled={newphoneStatus}
                    value={formik.values.newPhoneOTP}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newPhoneOTP && Boolean(formik.errors.newPhoneOTP)}
                    helperText={formik.touched.newPhoneOTP && formik.errors.newPhoneOTP}
                  />
                  <InputGroup.Text id="basic-addon2" className='handle-btn-popup fw-bold' onClick={() => getCode({ target: "newPhoneOTP" })} style={{ cursor: "pointer" }}>Get Code</InputGroup.Text>
                </InputGroup>
                {formik.errors.newPhoneOTP ? <p className='text-danger mb-2'><small> {formik.errors.newPhoneOTP}</small> </p> : <p className="mb-3"></p>}

                {myProfile && myProfile._id && <p className='label-text mb-2'>Security verification</p>}

                {(myProfile && myProfile.phoneno !== "" && myProfile.phoneno !== undefined) && <>
                  <p className='label-text mb-2'>phone Number Verification</p>
                  <InputGroup className="mb-1" size='sm'>
                    <Form.Control
                      placeholder=""
                      type='number'
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className='form-popup'
                      id="oldPhonenoOTP"
                      name='oldPhonenoOTP'
                      autoComplete='off'
                      value={formik.values.oldPhonenoOTP}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.oldPhonenoOTP && Boolean(formik.errors.oldPhonenoOTP)}
                      helperText={formik.touched.oldPhonenoOTP && formik.errors.oldPhonenoOTP}
                    />
                    <InputGroup.Text id="basic-addon2" className='handle-btn-popup fw-bold' style={{ cursor: "pointer" }} onClick={() => getCode({ target: "oldPhoneOTP", values: myProfile && myProfile.phoneno })}>Get Code</InputGroup.Text>
                  </InputGroup>
                  {myProfile && myProfile.phoneno && <p className='text-danger mb-2'><small>
                    {"Enter the 6-digit code sent to " + showPhone(myProfile && myProfile.phoneno)}
                  </small> </p>
                  }
                  {formik.errors.oldPhonenoOTP ? <p className='text-danger mb-2'><small> {formik.errors.oldPhonenoOTP}</small> </p> : null}
                </>
                }
                {(myProfile && myProfile.email !== "" && myProfile.email !== undefined) && <>
                  <p className='label-text mb-2'>Email-id verification Code</p>

                  <InputGroup className="mb-1" size='sm'>
                    <Form.Control
                      placeholder=""
                      type='number'
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className='form-popup'
                      id="oldEmailOTP"
                      name='oldEmailOTP'
                      autoComplete='off'
                      // disabled={emailStatus}
                      value={formik.values.oldEmailOTP}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.oldEmailOTP && Boolean(formik.errors.oldEmailOTP)}
                      helperText={formik.touched.oldEmailOTP && formik.errors.oldEmailOTP}
                    />
                    <InputGroup.Text id="basic-addon2" className='handle-btn-popup fw-bold' onClick={() => getCode({ target: "oldEmailOTP" })} style={{ cursor: "pointer" }}>Get Code</InputGroup.Text>
                  </InputGroup>

                  <p className='text-danger'><small>{"Enter the 6-digit code sent to " + showEmail(myProfile && myProfile.email)}</small> </p>
                  {formik.errors.oldEmailOTP ? <p className='text-danger'><small> {formik.errors.oldEmailOTP}</small> </p> : null}
                </>}
              </DialogContentText>
            </DialogContent>
            <DialogActions className='mx-auto justify-content-center text-center'>
              <button className='btn-profile shadow px-5 fs-6 py-2 mb-3' type="submit" style={{ width: 'auto', height: "auto" }}>Enable</button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default PhonePopUp;