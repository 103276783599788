import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '1px solid rgba(200, 200, 200, 0.32)'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function TpSlDetails({tpTriggerPx,slTriggerPx,tpTriggerPxType,tpOrdPx,slOrdPx,slTriggerPxType}) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
            <span onClick={handleClickOpen} className="cursor-pointer">
                View
            </span>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    TP | SL
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>

                    <div className='handlePropsColor gy-3'>
                        <div className='d-flex justify-content-between align-items-center my-2 flex-wrap'>
                            <p className='fw-normal'>TP trigger price</p>
                            <p className='fw-normal'>{tpTriggerPx} USDT <span style={{textTransform: "capitalize",}}>({tpTriggerPxType})</span></p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center my-2 flex-wrap'>
                            <p className='fw-normal'>TP order price</p>
                            <p className='fw-normal'>{tpOrdPx=== "-1" ? "Market" : "Limit"}</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center my-2 flex-wrap'>
                            <p className='fw-normal'>SL trigger price</p>
                            <p className='fw-normal'>{slTriggerPx} USDT <span style={{textTransform: "capitalize",}}>({slTriggerPxType})</span></p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center my-2 flex-wrap'>
                            <p className='fw-normal'>SL order price</p>
                            <p className='fw-normal'>{slOrdPx=== "-1" ? "Market" : "Limit"}</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {/* <button className='btn-cancel' onClick={handleClose}>
                        Cancel
                    </button> */}
                    <button className='btn-confirm' onClick={handleClose}>

                        Confirm
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}