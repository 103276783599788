import React from 'react';
import FooterChild from './FooterChild';
import FooterHome from './FooterHome';

function Footer() {
    return (
        <>
            {window.location.pathname==='/' ? <FooterHome /> : <FooterChild />}
        </>

    );
}

export default Footer;