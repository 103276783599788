import React from 'react'
import { Typography, Grid, Box } from '@mui/material'

const UTIStrengthen = () => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}
    >
      <Grid
        sx={{
          overflow: 'hidden',
        }}
        container
        spacing={2}
      >
        <Grid
          data-aos='fade-right'
          data-aos-duration='1500'
          item
          xs={12}
          md={7}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: {xs:24,sm:28, md:30, xl:32},
              my: 2,
            }}
          >
            Unitic Token Elevation
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            Hold UNITIC Token To Enjoy Benefits <br />
            UNITIC, Exchange Native Token
          </Typography>
<Box className='h-75 d-flex align-items-center'>
          <Box
            sx={{
                  bgcolor: ' #F9F9F9',
                  borderRadius: 3,
                  p: 3,
                  ' &:hover': {
                    bgcolor: '#E0FFE9',
                  },
                }}
            >
          <Typography
            sx={{
              fontSize: {xs:16,lg:18,xl:20},
              mb: 4,
              textAlign: 'justify',
            }}
          >
            The Heart Of This Ground Breaking Ecosystem Is UNITIC Token, Our Exchange Native Token.
          </Typography>
          <br />
          <Typography
            sx={{
              fontSize: {xs:16,lg:18,xl:20},
              textAlign: 'justify',
            }}
          >
            Step into the future of cryptocurrency with UNITIC, the ultimate token
            for savvy traders. Enjoy perks like reduced fees, VIP access to
            token launches, and seamless integration across Unitic's innovative
            ecosystem. Elevate your trading game today with UNITIC!"
          </Typography>
          </Box>
          </Box>
        </Grid>
        <Grid data-aos='fade-left' data-aos-duration='1500' item xs={12} md={5}>
          <img className='img-fluid' src='/group_ottom.png' alt=''/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UTIStrengthen
