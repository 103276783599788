import React from 'react';
import { Box, Typography } from '@mui/material'
import backSoon from "../../assets/images/break.png";

const MaintainceBreak = () => {
    return (
        <div className='vh-100 d-flex justify-content-center align-items-center' style={{backgroundColor: '#292524'}}>
            <div>
                <img
                    className='img-fluid'
                    src={backSoon}
                    alt='break'
                />
             <Typography
                    sx={{
                        color: '#fff',
                        textAlign: 'center',
                        mt: 5,
                        fontSize: '26px',
                    }}
                >
                    Unitic Exchange Under Maintanence, Will be Back Soon
                </Typography>
            </div>
           
        </div>
    );
};

export default MaintainceBreak;
