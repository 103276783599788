import React, { Suspense } from 'react';
import '../../../../assets/style.css';
// import Loader from '../../../../Component/Loader/Loader';
// import LoaderSpin from '../../../../Component/Loader/LoaderSpin';
import LoaderUnitic from '../../../../Component/Loader/LoaderUnitic';
const TVChartContainerFutures = React.lazy(() => import("./TVChartContainerFutures"));
// import TVChartContainerFutures from './TVChartContainerFutures'
const TradingViewFutures = (props) => {

  return (
    <Suspense fallback={<LoaderUnitic />}>
    <TVChartContainerFutures {...props} />
    </Suspense>

  );
}
export default TradingViewFutures;