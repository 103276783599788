import React from 'react';
import {
    alpha, Box, TableCell, TableHead,
    TableRow, TableSortLabel, Toolbar, Typography, IconButton, Tooltip,
    Grid,
    Button,
    Avatar
} from "@mui/material";
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';

const CustomTableRow = (props) => {
    const { row, isSelected, coindcxDecimal, index, balShow, pendingOrderAmount, handleClick } = props;
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const { _id, BTCvalue = 0, ETHvalue = 0, INRvalue = 0, USDvalue = 0, apiid = "", autoWithdraw = 0, balance = 0, balanceV2 = 0, basecoin = "", cointype = 0, contractAddress = "", count = 0, curnType = "", currencyId = "", currencyName = "", currencySymbol = "", currencySymbolCode = "", depositEnable = 0, depositNotes = "", fees = 0, feetype = 0, hold = 0, image = "", inrValue = 0, lastPurchasedPrice = 0, mindepamt = 0, minwithamt = 0, p2pAmount = 0, p2pHold = 0, price = 0, siteDecimal = 2, stakingAmount = 0, stakingHold = 0, stakingTransferEnable = 0, status = 1, tagContent = "", totalTradePrice = 0, tradeEnable = 0, transferEnable = 0, withdrawEnable = 0, withdrawNotes = "", toCurrency = ""
    } = row;
    const isItemSelected = isSelected(
        currencyName
    );
    const labelId = `enhanced-table-checkbox-${index}`;
    const firstCurrecny = currencySymbol;
    const secondCurrecny = toCurrency;
    const tradePairName = firstCurrecny + "_" + secondCurrecny;
    const decimal = coindcxDecimal[firstCurrecny + secondCurrecny]
    const baseDecimal = decimal?.base_currency_precision ? decimal?.base_currency_precision : 2
    const targetDecimal = decimal?.target_currency_precision ? decimal?.target_currency_precision : 3
    // const avl = ((currencyName == 'Indian Rupee' && props.pendingOrderAmount) ? (
    //     <>

    //         {balShow ? ((Number(balance) - (Number(props.pendingOrderAmount))).toFixed(targetDecimal)) : "******"}
    //     </>
    // ) : (
    //     <>
    //         {balShow ? (row?.balance).toFixed(targetDecimal) : "******"}
    //     </>
    // ))
    // const total = ((currencyName == 'Indian Rupee' && props.pendingOrderAmount) ? (
    //     <>

    //         {balShow ? ((Number(balanceV2) - (Number(props.pendingOrderAmount))).toFixed(targetDecimal)) : "******"}
    //     </>
    // ) : (
    //     <>
    //         {balShow ? (row?.balanceV2).toFixed(targetDecimal) : "******"}
    //     </>
    // ))

    // const avlForAsset = (currencyName == 'Indian Rupee' && pendingOrderAmount) ? (Number(balanceV2) - Number(pendingOrderAmount)).toFixed(targetDecimal) : row?.balanceV2.toFixed(targetDecimal);
    const avl = balShow ? (row?.balance).toFixed(targetDecimal) : "******";
    const total = balShow ? (row?.balanceV2).toFixed(targetDecimal) : "******";

    const avlForAsset = row?.balanceV2.toFixed(targetDecimal);
    let one = 1;
    const ltp = currencyName == 'Indian Rupee' ? one.toFixed(baseDecimal) : (price).toFixed(baseDecimal)

    const totalAsset = (+avlForAsset * (+ltp)) ? (+avlForAsset * (+ltp)).toFixed(2) : 0;

    const entryPrice = currencyName == 'Indian Rupee' ? 1.00 : (lastPurchasedPrice).toFixed(baseDecimal)
    const profitNum = (avlForAsset === '0' || !avlForAsset || avlForAsset === 0) ? 0 : (((+ltp) - entryPrice) * (+avlForAsset)).toFixed(2);
    const profitPercentage = (avlForAsset === '0' || !avlForAsset || avlForAsset === 0) ? 0 : ((ltp - entryPrice) / entryPrice * 100).toFixed(2);
    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected} onClick={(event) =>
                    handleClick(event, currencyName)
                }>
                <TableCell component="th" scope="row" sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate("/spot/" + tradePairName)}
                    >
                        {/* <img
                            height={20}
                            width={20}
                            className='rounded-pill'
                            src={image}
                            alt=''
                        /> */}
                        <Avatar
                            alt="Remy Sharp"
                            src={image}
                            sx={{ width: { xs: 20, lg: 30 }, height: { xs: 20, lg: 30 } }}
                        />
                        <Box>
                            <Typography sx={{ fontSize: { xs: '0.7rem', lg: '0.9rem' } }} className='text-nowrap'>{currencyName}</Typography>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">{props?.loading ? "Loading..." : avl}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">{hold?.toFixed(targetDecimal) || 0}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">{total}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">&#8377; {entryPrice}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">&#8377; {totalAsset}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left"><span style={{ color: profitNum > 0 ? ' #21CA50' : '#ff4b5e' }}>{profitNum > 0 ? `+${profitNum}` : profitNum}</span> (<span style={{ color: profitPercentage > 0 ? '#21CA50' : '#ff4b5e' }}>{(profitPercentage != 'NaN' || !profitPercentage || profitPercentage == null || profitPercentage === Infinity) ? profitPercentage > 0 ? `+${profitPercentage}` : profitPercentage : 0}%</span>)</TableCell>
                <TableCell sx={{ width: 20, pl: 0, pr: 1, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xs: 'block', lg: 'none' } }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Box sx={{ margin: 0, display: { xs: 'none', lg: 'flex' }, gap: '5px' }} className=' justify-content-end align-items-center w-100 my-3'>

                        <Box className='text-center'>
                            <Button
                                sx={{
                                    bgcolor: '#C8C8C8',
                                    padding: { xs: '2px 5px', lg: '2px 7px' },
                                    borderRadius: '20px',
                                    color: '#000',
                                    fontSize: { xs: '10px', lg: '11px' },
                                    textAlign: 'center',
                                    // mt: 3,
                                    cursor: 'pointer',
                                    lineHeight: 1.8,
                                    '&:disabled': {
                                        cursor: 'not-allowed',
                                        bgcolor: '#c8c8c8aa',
                                        color: '#000'
                                    },
                                    '&:hover': {
                                        color: 'white'
                                    },
                                    width: '100%'
                                }}
                                disabled={withdrawEnable === 1 ? false : true}
                                onClick={() => navigate("/withdraw/" + curnType.toLowerCase() + "/" + _id)}
                            >
                                Withdraw
                            </Button>
                        </Box>
                        <Box className='text-center'>
                            <Button
                                sx={{
                                    bgcolor: '#C8C8C8',
                                    padding: { xs: '2px 5px', lg: '2px 7px' },
                                    borderRadius: '20px',
                                    color: '#000',
                                    fontSize: { xs: '10px', lg: '11px' },
                                    textAlign: 'center',
                                    // mt: 3,
                                    cursor: 'pointer',
                                    lineHeight: 1.8,
                                    '&:disabled': {
                                        cursor: 'not-allowed',
                                        bgcolor: '#c8c8c8aa',
                                        color: '#000'
                                    },
                                    '&:hover': {
                                        color: 'white'
                                    },
                                    width: '100%'
                                }}
                                disabled={depositEnable === 1 ? false : true} onClick={() => navigate("/deposit/" + curnType.toLowerCase() + "/" + _id)}
                            >
                                Deposit
                            </Button>
                        </Box>

                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: 20, paddingLeft: 0, paddingRight: 1, }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0, ml: '-5px' }} className='d-flex justify-content-end align-items-center w-100 my-3'>

                            <Box
                                className='row align-items-center gx-2'
                                sx={{ width: window.innerWidth > 600 ? '20%' : window.innerWidth > 1700 ? '20%' : '30%' }}
                            >
                                <Box className='col-6 text-center'>
                                    <Button
                                        sx={{
                                            bgcolor: '#C8C8C8',
                                            padding: '2px 5px',
                                            borderRadius: '20px',
                                            color: '#000',
                                            fontSize: '10px',
                                            textAlign: 'center',
                                            // mt: 3,
                                            cursor: 'pointer',
                                            lineHeight: 1.8,
                                            '&:disabled': {
                                                cursor: 'not-allowed',
                                                bgcolor: '#c8c8c8aa',
                                                color: '#000'
                                            },
                                            '&:hover': {
                                                color: 'white'
                                            },
                                            width: '100%'
                                        }}
                                        disabled={withdrawEnable === 1 ? false : true}
                                        onClick={() => navigate("/withdraw/" + curnType.toLowerCase() + "/" + _id)}
                                    >
                                        Withdraw
                                    </Button>
                                </Box>
                                <Box className='col-6 text-center'>
                                    <Button
                                        sx={{
                                            bgcolor: '#C8C8C8',
                                            padding: '2px 5px',
                                            borderRadius: '20px',
                                            color: '#000',
                                            fontSize: '10px',
                                            textAlign: 'center',
                                            // mt: 3,
                                            cursor: 'pointer',
                                            lineHeight: 1.8,
                                            '&:disabled': {
                                                cursor: 'not-allowed',
                                                bgcolor: '#c8c8c8aa',
                                                color: '#000'
                                            },
                                            '&:hover': {
                                                color: 'white'
                                            },
                                            width: '100%'
                                        }}
                                        disabled={depositEnable === 1 ? false : true} onClick={() => navigate("/deposit/" + curnType.toLowerCase() + "/" + _id)}
                                    >
                                        Deposit
                                    </Button>
                                </Box>
                            </Box>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
};
export default CustomTableRow;