import React, { useState, useEffect } from "react";
import {
    useNavigate
} from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
    alpha, Box, TableCell, TableHead,
    TableRow, TableSortLabel, Toolbar, Typography, IconButton, Tooltip,
    Grid,
    Button,
} from "@mui/material";
import { Modal, Spinner } from "react-bootstrap";
import { makeRequest } from "../../core/services/v1/request";
import { toast } from "../../core/lib/toastAlert";
import Config from "../../core/config/";
import * as yup from 'yup';
import { useFormik } from 'formik';
import './Portfolio.css';
import { useContextData } from '../../core/context/index';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import CustomTableRow from "./CustomTableRow";
import SearchIcon from '@mui/icons-material/Search';
import { BsSortDownAlt, BsSortDown } from "react-icons/bs";
import SwapVertIcon from '@mui/icons-material/SwapVert';

const validationSchema = yup.object({
    amount: yup
        .number('Enter your Amount')
        .required('Amount is required'),
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const WalletListV2 = (props) => {
    const {
        walletData: propswalletData = [],
        balShow = false,
        tabName = "wallet",
        coindcxDecimal = {},
        setEstimatedValue, estimatedValue = 0, balShowHideCall, loading, pendingOrderAmount,
        search, setSearch
    } = props;
    const { myProfile } = useContextData();
    const [foc, setFoc] = useState(false);
    const navigate = useNavigate();
    // var userId = myProfile && myProfile?.length && myProfile._id;
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("balance");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [open, setOpen] = useState(false);
    const [currency, setCurrency] = useState({});
    const [fromAccount, setFromAccount] = useState('Main Wallet');
    const [toAccount, setToAccount] = useState('P2P Wallet');
    const [isLoading, setisLoading] = useState(false);
    const [loadingWallet, setLoadingWallet] = useState(true);
    const [walletData, setWalletData] = useState([]);
    const [sort, setSort] = useState(1);
    const handleClose = () => {
        setOpen(false);
        formik.values.amount = "";
        formik.values.toWallet = "";
    };
    function customSort(array, sortOption = sort) {
        let one = 1;
        const getSort = array.sort((a, b) => {

            const adecimal = coindcxDecimal[a.currencySymbol + a.toCurrency]
            const abaseDecimal = adecimal?.base_currency_precision ? adecimal?.base_currency_precision : 2
            const atargetDecimal = adecimal?.target_currency_precision ? adecimal?.target_currency_precision : 3

            const bdecimal = coindcxDecimal[b.currencySymbol + b.toCurrency]
            const bbaseDecimal = bdecimal?.base_currency_precision ? bdecimal?.base_currency_precision : 2
            const btargetDecimal = bdecimal?.target_currency_precision ? bdecimal?.target_currency_precision : 3

            const avlForAssetA = (a.currencyName === 'Indian Rupee' && a.pendingOrderAmount)
                ? (Number(a.balanceV2) - Number(a.pendingOrderAmount)).toFixed(atargetDecimal)
                : Number(a.balanceV2).toFixed(atargetDecimal);

            const avlForAssetB = (b.currencyName === 'Indian Rupee' && b.pendingOrderAmount)
                ? (Number(b.balanceV2) - Number(b.pendingOrderAmount)).toFixed(btargetDecimal)
                : Number(b.balanceV2).toFixed(btargetDecimal);

            const ltpA = a.currencyName === 'Indian Rupee'
                ? +(one).toFixed(abaseDecimal)
                : +(a.price).toFixed(abaseDecimal);

            const ltpB = b.currencyName === 'Indian Rupee'
                ? +(one).toFixed(bbaseDecimal)
                : +(b.price).toFixed(bbaseDecimal);

            const totalAssetA = (+avlForAssetA) * (+ltpA) ? ((+avlForAssetA) * (+ltpA)).toFixed(2) : 0
            const totalAssetB = (+avlForAssetB) * (+ltpB) ? ((+avlForAssetB) * (+ltpB)).toFixed(2) : 0

            if (sortOption == 1) {
                return totalAssetB - totalAssetA; // Descending
            } else if (sortOption == 2) {
                return totalAssetA - totalAssetB; // Ascending
            }
        });

        return getSort;
    }
    // useEffect(() => {
    //     if (props && props.walletData) {
    //         setWalletData(props.walletData);
    //         // setEstimatedValue(props.estimatedValue)

    //     }
    // }, [props]);

    useEffect(() => {
        getWalletCurrency();
    }, []);

    async function getWalletCurrency() {
        setLoadingWallet(true)
        if (tabName == "wallet") {
            const params = {
                url: `${Config.V2_API_URL}wallet/getWalletCurrency?search=${search}`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status) {
                // const { estimateINR = 0,
                //     estimateUSD = 0}=response
                const crypto = response.data.filter(coin => coin.curnType === 'Crypto');
                const fiat = response.data.filter(fiat => fiat.curnType === 'Fiat');
                setWalletData(crypto);
                // setEstimatedValue({
                //     estimateINR,
                //     estimateUSD
                // });
                // setCryptoWallet(crypto);
                // setCryptoWalletClone(crypto);
                // setFiatWallet(fiat);
                // setestimateINR(response.estimateINR)
            }
        }
        else {
            const params = {
                url: `${Config.V2_API_URL}wallet/getSpotHoldings?search=${search}`,
                method: 'GET'
            };
            const response = await makeRequest(params);
            if (response) {
                const {
                    status = false,
                    data = [],
                    estimateINR = 0,
                    estimateUSD = 0,
                } = response;
                setWalletData(data);
                setEstimatedValue({
                    estimateINR,
                    estimateUSD
                });

            }
        }
        setLoadingWallet(false)
    }

    const transferFund = (data) => {
        setOpen(true);
        setCurrency(data)
    };

    const fromWallet = (e) => {
        setFromAccount(e.target.value);
        setToAccount(e.target.value == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet');
    }
    const toWallet = (e) => {
        setToAccount(e.target.value);
        // console.log(e.target.value);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = walletData.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const formik = useFormik({
        initialValues: {
            amount: '',
            toWallet: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setisLoading(true);
            let balance = fromAccount == 'Main Wallet' ? currency.balance : currency.p2pAmount;
            if (balance >= values.amount) {
                const body = {
                    amount: values.amount,
                    currencyId: currency.currencyId,
                    fromAccount: fromAccount,
                    toAccount: toAccount
                }
                const params = {
                    url: `${Config.V1_API_URL}wallet/submitTransfer`,
                    method: 'POST',
                    body
                }
                const response = (await makeRequest(params));
                let type = 'error';
                setisLoading(false);
                if (response.status) {
                    type = 'success';
                    handleClose();
                    getWalletCurrency();
                }
                toast({ type, message: response.message });
            } else {
                toast({ type: 'error', message: 'Insufficient Balance On ' + fromAccount });
            }
        },
    });
    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - walletData.length) : 0;

    // console.log("props.pendingOrderAmount==============", propswalletData);
    const customStyle = {
        // tableLayout:'fixed',
        // minWidth: window.innerWidth < 700 ? 1000 : 100,
        justifyContent: 'center'
    }

    return (
        <div className='mb-5'>

            <Box
                sx={
                    {
                        // bgcolor: '#282525',
                    }
                }
            >
                <Box
                    sx={{
                        bgcolor: '#3d3d3d',
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        p: 2,
                        pl: 3,
                        pr: 3,
                        borderRadius: 5,
                        mt: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textAlign: { xs: 'center', md: 'left' },
                                fontSize: { xs: '0.85rem', lg: '0.9rem' },
                            }}
                        >
                            <span onClick={() => window.open('https://utistaking.com/', '_blank', 'noopener,noreferrer')}>Estimated</span> Balance {balShow ? <AiFillEye
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} /> : <AiFillEyeInvisible
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} />}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: '1rem', lg: '1.2rem' },
                                textAlign: { xs: 'center', md: 'left' },
                                fontWeight: 'bold',
                                mt: 1.5,
                            }}
                        >
                            {balShow ? <>&#8377; {loading ? 'Loading...' : ((estimatedValue.estimateINR) - pendingOrderAmount)?.toFixed(2)}</> : <>***Balance hidden***</>}
                        </Typography>
                    </Box>
                    <Box sx={{
                        textAlign: { xs: 'center', md: 'left' },
                        fontWeight: 'bold',
                        mt: { xs: 1.5, md: 0 },
                    }} className='d-flex justify-content-start align-items-center'>

                        <input type="text" className={`${foc ? 'text-light' : 'text-gray'} searchGlassFutures`} style={{ borderRadius: '30px 0 0px 30px', paddingLeft: '12px' }} placeholder="Search" value={search} onChange={(e) => {
                            setSearch(e.target.value)
                        }} onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                getWalletCurrency();
                            }
                        }} onFocus={() => { setFoc(() => true); }} onBlur={() => {
                            setTimeout(() => {
                                setFoc(() => false);
                            }, 100);
                        }} />
                        <button className='searchBtnFutures' style={{ borderRadius: '0 30px 30px 0', paddingLeft: '0px', paddingRight: '12px' }} onClick={() => getWalletCurrency()}><SearchIcon fontSize='sm' /></button>

                    </Box>
                </Box>

                <Box
                    sx={{
                        mt: 2,
                    }}
                >
                    {
                        (loadingWallet || (walletData && walletData.length > 0)) &&
                        <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', maxHeight: { xs: 'calc(86vh - 200px)', sm: 'calc(92vh - 200px)', md: 'calc(96vh - 200px)' } }}>
                            <Table stickyHeader aria-label="sticky table" sx={customStyle} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Token</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Avl</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Hold</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Total</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">AVG</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Value <SwapVertIcon sx={{cursor:'pointer',fontWeight: 600, fontSize: { xs: '1rem', lg: '1.3rem' }}}  onClick={() => setSort((el) => el == 1 ? 2 : 1)} /></TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">PnL</TableCell>
                                        <TableCell sx={{ width: 20, pl: 0, pr: 1, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loadingWallet ? <React.Fragment >
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            <TableCell component="th" scope="row" sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }}></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"><Box sx={{ display: { xs: 'block', sm: 'none' } }}><Spinner animation="border" variant="light" size="sm" /></Box></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"><Box sx={{ display: { xs: 'none', sm: 'block' } }}><Spinner animation="border" variant="light" size="sm" /></Box></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                        </TableRow>
                                    </React.Fragment> : customSort(
                                        walletData,
                                        sort
                                    )
                                        .map((row, index) => (
                                            <CustomTableRow key={index} row={row} isSelected={isSelected} coindcxDecimal={coindcxDecimal} index={index} balShow={balShow} pendingOrderAmount={props.pendingOrderAmount} handleClick={handleClick} />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Box>
            </Box>
            {!loadingWallet && walletData && walletData.length == 0 && (
                <div className="text-center mx-auto mt-5">
                    <img
                        className="no-record-image"
                        src="/assets/no-re.png"
                        alt="no-record"
                    />
                    <br />
                    <span className="text-center">No Records Found</span>
                </div>
            )}
            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer {currency.currencySymbol}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row justify-content-center align-items-center ">
                            <div className="row mt-4">
                                <span className="phonenumber-change-text-2">
                                    Internal Transfer are free on Unitic
                                </span>
                                <select class="f-control f-dropdown" placeholder="Select" value={fromAccount} onChange={fromWallet}>
                                    <option value="Main Wallet">Main Wallet</option>
                                    <option value="P2P Wallet">P2P Wallet</option>
                                </select>
                            </div>
                            <div className="row mt-4">
                                <select class="f-control f-dropdown" placeholder="Select" value={toAccount} onChange={toWallet}>
                                    <option value={fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}>{fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}</option>
                                    {/* {(fromAccount== 'Main Wallet') &&<option value={fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}>{fromAccount == 'Main Wallet' ? 'P2P Wallet' : 'Main Wallet'}</option>} */}
                                </select>
                            </div>
                            <div className="row mt-4">
                                <span className="phonenumber-change-text-2">
                                    Amount
                                </span>
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        autoComplete='off'
                                        label="Amount"
                                        id="amount"
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                                        helperText={formik.touched.amount && formik.errors.amount}
                                    />
                                </div>
                                {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <span className="phonenumber-change-text-2">
                                Balance: {fromAccount == 'Main Wallet' ? currency.balanceV2 : currency.p2pAmount} {currency.currencySymbol}
                            </span>
                            <div className='col'>
                                <div class="d-grid">
                                    <button class="add-payment-method-confirm-button" type="submit" disabled={isLoading}>Transfer Amount</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default WalletListV2;