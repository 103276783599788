import React, { useState } from 'react';
import './Login.css';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import phone from "phone";
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useContextData } from '../../../core/context';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useEffect } from 'react';
import { makeRequest } from "../../../core/services/v1/request";
import { toast } from "../../../core/lib/toastAlert";
import { setCookie } from "../../../core/helper/cookie";
import Config from "../../../core/config/";
import { showEmail, showPhone } from "../../../core/helper/date-format";
import { useFormik } from "formik";
import * as yup from "yup";
import Otp from '../Otp/Otp';

const validationSchema = yup.object({
    email: yup
        .string("Enter your email")
        // .email('Enter a valid email')
        .required("Email is required"),
    password: yup.string("Enter your password").required("Password is required"),
});
const validationSchemaPhNum = yup.object({
    password: yup.string("Enter your password").required("Password is required"),
});

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#ffffff !important',
    },
    input: {
        color: '#ffffff !important',
    },
    outlinedInput: {
        '& fieldset': {
            borderColor: '#ffffff !important',
            '&:hover': {
                borderColor: '#ffffff !important',
            },
            '&.Mui-focused': {
                borderColor: '#ffffff !important',
            },
        },
    },
}));


const Login = (props) => {
    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    const [value, setValue] = React.useState(0);
    let inputRef = useRef();
    let inputRef1 = useRef();

    const navigate = useNavigate();

    const { setUserProfile } = useContextData();

    const [showLogin, setshowLogin] = useState(true);
    const [isLoading, setisLoading] = useState(false);
    const [otpShow, setotpShow] = useState(0);
    const [phoneno_err, setphoneno_err] = useState("");
    const [phoneno, setphoneno] = useState("");
    const [tabname, setTabname] = useState("email");
    const [timer_resendOtp, setTimer_resendOtp] = useState(0);

    useEffect(() => {
        timer_resendOtp > 0 &&
            setTimeout(() => setTimer_resendOtp(timer_resendOtp - 1), 1000);
    }, [timer_resendOtp]);

    const tabNameChange = async (val = "email") => {
        if (tabname != val) {
            setotpShow(0);
            setshowLogin(true);
            setTabname(val);
        }
    };

    const loginSubmit = async (values, target = "submit") => {
        // console.log("click")
        if (target == "resendOTP") {
            let valuesCopy = Object.assign({}, values);
            valuesCopy.otp = "";
            values = valuesCopy;
        }

        setisLoading(true);
        const params = {
            url: `${Config.V1_API_URL}user/login`,
            method: "POST",
            body: values,
        };
        const response = await makeRequest(params);
        setisLoading(false);
        let type = "error";
        if (response.status) {
            type = "success";
            setotpShow(response.type);
            setshowLogin(false);
            if (!response.type) {
                setCookie("userToken", response.token);
                setUserProfile();
                navigate("/my/dashboard");
            } else {
                setTimer_resendOtp(Config.timer.resendOtp);
            }
        }
        toast({ type, message: response.message });
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            otp: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!showLogin) {
                if (formik.values.otp == "") {
                    formik.errors.otp = "Please enter valid Code";
                    return false;
                }
            }
            loginSubmit(values);
        },
    });

    const formikPhNum = useFormik({
        initialValues: {
            email: "",
            password: "",
            otp: "",
        },
        validationSchema: validationSchemaPhNum,
        onSubmit: async (values) => {
            values.phoneno = '+' + phoneno;
            let phn = '+' + phoneno;
            if (phn == "" || typeof phn == undefined) {
                setphoneno_err("Enter your phone number");
                return false;
            }
            const phoneDetail = phone(phn, { country: "" });
            if (phoneDetail.isValid === false) {
                setphoneno_err(
                    "Invalid phone number, Please enter correct phone number"
                );
                return false;
            }
            setphoneno_err("");

            if (!showLogin) {
                if (formikPhNum.values.otp == "") {
                    formikPhNum.errors.otp = "Please enter valid Code";
                    return false;
                }
            }
            loginSubmit(values);
        },
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <section className='bg-login'>
            <div className='container'>
                <div className="d-flex justify-content-center align-items-center py-3  flex-column flex-lg-row">
                    <div className="login-intro w-100">
                        <h1>The Most <span>Reliable</span><br /><Typography component='h3' className='login-intro-h1'>Cryptocurrency Exchange</Typography></h1>
                        <Typography className="pt-2 pb-2 login-intro-p" component='h2'>Get Started with the easiest and most
                            secure platform to buy, sell. trade, and earn
                            cryptocurrencies</Typography>
                    </div>

                    {showLogin ?
                        <div className='w-100 mx-auto bg-login-card'>
                            <div className='make-login-box mx-auto pb-1'>
                                <h4 className='ps-4 py-2'><strong>Login</strong></h4>
                                <Box sx={{ width: '90%', height: "auto", marginLeft: "auto", marginRight: "auto", bgcolor: '#4D4D4D', borderRadius: "10px", color: "#C8C8C8", padding: "10px" }} className="my-3">
                                    <Tabs value={value} onChange={handleChange} centered sx={{
                                        '& .MuiTabs-indicator': {
                                            display: 'none'
                                        },
                                        '& .MuiTab-root': {
                                            color: 'white',
                                            textTransform: "capitalize",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                            fontStyle: "normal",
                                            paddingLeft: "16px",
                                            paddingRight: "16px",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                        },
                                        '& .MuiTab-root.Mui-selected': {
                                            background: 'linear-gradient(90.92deg, #10EDB6 0.28%, #4C62F7 94.44%);',
                                            borderRadius: "10px",
                                            color: "white",
                                            fontWeight: "bold",
                                        }

                                    }}>
                                        <Tab label="Email-ID" />
                                        <Tab label="Phone Number" />
                                    </Tabs>

                                </Box>
                                <div className='mx-auto text-center my-4 text-light'>
                                    <ThemeProvider theme={theme}>
                                        {value === 0 &&
                                            <>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    label="Email-ID"
                                                    sx={{
                                                        color: 'white', m: 1,
                                                        '& .MuiInputLabel-root': {
                                                            fontSize: "1.5rem",
                                                            color: 'white',
                                                        },

                                                        '& .MuiInputBase-root': {
                                                            marginTop: '25px',
                                                            color: '#dadada',
                                                        }

                                                    }}

                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircle sx={{ color: 'white' }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    fullWidth
                                                    type="email"
                                                    name="email"
                                                    autoComplete="off"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.email && Boolean(formik.errors.email)
                                                    }
                                                    helperText={formik.touched.email && formik.errors.email}
                                                />
                                                {formik.values.email === "" ||
                                                    !formik.values.email ||
                                                    (formik.values.email &&
                                                        isNaN(formik.values.email) === true)
                                                    ? ""
                                                    : ""}
                                                <FormControl sx={{ m: 1, width: '100%', marginTop: 2 }} variant="standard" fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-password" sx={{
                                                        color: 'white',
                                                        fontSize: (formik.values.password.length > 0) ? "1.5rem" : "1.2rem",

                                                    }}>Password</InputLabel>
                                                    <Input
                                                        id="standard-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        fullWidth
                                                        sx={{
                                                            color: '#dadada',

                                                            '& .MuiInputBase-input': {
                                                                // fontSize: "1.2rem",
                                                                marginTop: "10px"
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: 'white' }} />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        name="password"
                                                        label="Password"
                                                        autoComplete="off"
                                                        value={formik.values.password}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.touched.password &&
                                                            Boolean(formik.errors.password)
                                                        }
                                                        helperText={
                                                            formik.touched.password && formik.errors.password
                                                        }
                                                        ref={inputRef}
                                                    />
                                                    {formik.errors.password ? (
                                                        <small className="text-red text-start pt-2">
                                                            {formik.errors.password} <br />
                                                        </small>
                                                    ) : null}
                                                </FormControl>
                                                <p className='text-white text-end pb-2' style={{ cursor: 'pointer' }} onClick={() => navigate("/ForgotPassword")}>Forget Password?</p>
                                                <div className='mx-auto text-center'>
                                                    <button className="btn-register w-50" disabled={isLoading} type='submit' onClick={() => formik.handleSubmit()}>Login</button>
                                                </div>
                                            </>
                                        }
                                        {
                                            value === 1 && <>
                                                <PhoneInput
                                                    containerClass="container"
                                                    containerStyle={{ width: "97%", borderBottom: "1px solid white", borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius: "0", padding: "5px 1px", }}
                                                    inputStyle={{ background: "transparent", color: '#dadada', border: "none", outline: "none" }}
                                                    buttonStyle={{ height: "35px", marginTop: "5px" }}
                                                    dropdownStyle={{ textAlign: "left" }}
                                                    searchStyle={{ color: "white" }}
                                                    required
                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,
                                                        autoFocus: true
                                                    }}
                                                    international
                                                    country={'in'}
                                                    className='form-control handleModalBody'
                                                    countryCallingCodeEditable={true}
                                                    value={formikPhNum.values.phoneno}
                                                    onChange={setphoneno}
                                                    name="phoneno"
                                                    autoComplete="off"
                                                    onBlur={formikPhNum.handleBlur}

                                                />
                                                {phoneno_err ? (
                                                    <small className="text-red text-start pt-2">
                                                        {phoneno_err} <br />
                                                    </small>
                                                ) : null}

                                                <FormControl sx={{ m: 1, width: '100%', marginTop: 3 }} variant="standard" fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-password" sx={{
                                                        color: 'white',
                                                        fontSize: (formikPhNum.values.password.length > 0) ? "1.3rem" : "1.2rem",
                                                    }}>Password</InputLabel>

                                                    <Input
                                                        id="standard-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        fullWidth
                                                        sx={{
                                                            color: '#dadada',
                                                            '& .MuiInputBase-input': {
                                                                // fontSize: "1.2rem",
                                                                marginTop: "10px"
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityOff sx={{ color: 'white' }} /> : <Visibility sx={{ color: 'white' }} />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        name="password"
                                                        label="Password"
                                                        autoComplete="off"
                                                        value={formikPhNum.values.password}
                                                        onChange={formikPhNum.handleChange}
                                                        onBlur={formikPhNum.handleBlur}
                                                        error={
                                                            formikPhNum.touched.password &&
                                                            Boolean(formikPhNum.errors.password)
                                                        }
                                                        helperText={
                                                            formikPhNum.touched.password &&
                                                            formikPhNum.errors.password
                                                        }
                                                        ref={inputRef1}
                                                    />
                                                    {formikPhNum.errors.password ? (
                                                        <small className="text-red text-start pt-2">
                                                            {formikPhNum.errors.password} <br />
                                                        </small>
                                                    ) : null}
                                                </FormControl>
                                                <p className='text-white text-end pb-2' style={{ cursor: 'pointer' }} onClick={() => navigate("/ForgotPassword")}>Forget Password?</p>
                                                <div className='mx-auto text-center'>
                                                    <button className="btn-register w-50" disabled={isLoading} type='submit' onClick={() => formikPhNum.handleSubmit()}>Login</button>
                                                </div>
                                            </>
                                        }

                                    </ThemeProvider>

                                    <p className='text-decoration-underline pt-5 mb-0' style={{ cursor: "pointer" }} onClick={() => navigate("/register")}>Create a new Account</p>
                                </div>
                            </div>
                            <div className='bg-login-card-2'></div>
                        </div> :
                        <>
                            {value == 0 && <Otp otpShow={otpShow} formik={formik} timer_resendOtp={timer_resendOtp} isLoading={isLoading} loginSubmit={loginSubmit} value={value} showEmail={showEmail} />}

                            {value == 1 && <Otp formikPhNum={formikPhNum} otpShow={otpShow} isLoading={isLoading} timer_resendOtp={timer_resendOtp} loginSubmit={loginSubmit} showPhone={showPhone} value={value} />}
                        </>
                    }


                </div>
            </div>
        </section >
    );
};

export default Login;