import React, { useState } from 'react';
import './Otp.css';
import OtpInput from 'react-otp-input';

const Otp = ({ otpShow, formik, timer_resendOtp, isLoading, loginSubmit, value, showEmail,

    formikPhNum, showPhone,

}) => {
    const [otp, setOtp] = useState('');

    const emailOtp = (e) => {
        setOtp(e);
        formik.handleChange('otp')(e);
    }
    const phoneOtp = (e) => {
        setOtp(e);
        formikPhNum.handleChange('otp')(e);
    }

    return (
        <>

            {value == 0 &&
                <div className='w-100 mx-auto bg-otp-card'>
                    <div className='make-otp-box mx-auto pb-1'>
                        <h4 className='pt-2'><strong> {otpShow === 1 ? "Enter OTP Code" : "Enter 2FA Code"}</strong></h4>

                        <div className='mx-auto text-center my-4 text-light'>
                            <div className='mx-auto text-center mt-3 mb-4'>
                                <p className='text-start mb-3' style={{ color: "#C8C8C8", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>{otpShow === 1 ? "OTP Code" : "2FA Code"} Was Sent to{otpShow === 1 ? " Email" : ""} {showEmail(formik.values.email)}{otpShow === 1 ? "" : " [Google Auth]"}</p>
                                <OtpInput
                                    id="otp"
                                    name="otp"
                                    autoComplete="off"
                                    value={otp}
                                    onChange={(e) => emailOtp(e)}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.otp && Boolean(formik.errors.otp)
                                    }
                                    helperText={formik.touched.otp && formik.errors.otp}
                                    numInputs={6}
                                    inputType="number"
                                    shouldAutoFocus={true}
                                    containerStyle={{ width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                                    inputStyle={{ borderRadius: "40px", border: "none", width: "100%", height: window.innerWidth < 500 ? "45px" : "52px", outline: "none", fontWeight: "600", fontSize: "22px", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', textAlign: "center", background: "#7B7B7B", color: "white" }}
                                    renderSeparator={<span className='mx-2'></span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                                {otpShow === 1 ? (

                                    <p className='text-end text-danger pt-3' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', cursor: 'pointer' }} disabled={
                                        isLoading || timer_resendOtp > 0 ? true : false
                                    }
                                        onClick={() =>
                                            loginSubmit(formik.values, "resendOTP")
                                        }>Resend OTP {timer_resendOtp > 0 ? (
                                            <span className="timeLeft">
                                                ({timer_resendOtp})
                                            </span>
                                        ) : (
                                            ""
                                        )}</p>
                                ) : (
                                    ""
                                )}
                                {formik.errors.otp ? (
                                    <small className="invalid-otp error">
                                        {formik.errors.otp} <br />
                                    </small>
                                ) : null}


                            </div>

                            <div className='mx-auto text-center'>
                                <button className="btn-register w-50" onClick={() => formik.handleSubmit()} disabled={isLoading}>Submit</button>
                            </div>

                        </div>
                    </div>
                    <div className='bg-otp-card-2'></div>
                </div>
            }


            {value == 1 && <div className='w-100 mx-auto bg-otp-card'>
                <div className='make-otp-box mx-auto pb-1'>
                    <h4 className='pt-2'><strong> {otpShow === 1 ? "Enter OTP Code" : "Enter 2FA Code"}</strong></h4>

                    <div className='mx-auto text-center my-4 text-light'>
                        <div className='mx-auto text-center mt-3 mb-4'>
                            <p className='text-start' style={{ color: "#C8C8C8", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>{otpShow === 1 ? "OTP Code" : "2FA Code"} Was Send to {showPhone(formikPhNum.values.phoneno)}</p>
                            <OtpInput
                                id="otp"
                                name="otp"
                                autoComplete="off"
                                value={otp}
                                onChange={(e) => phoneOtp(e)}
                                onBlur={formikPhNum.handleBlur}
                                error={
                                    formikPhNum.touched.otp &&
                                    Boolean(formikPhNum.errors.otp)
                                }
                                helperText={
                                    formikPhNum.touched.otp && formikPhNum.errors.otp
                                }
                                numInputs={6}
                                inputType="number"
                                shouldAutoFocus={true}
                                containerStyle={{ width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                                inputStyle={{ borderRadius: "40px", border: "none", width: "100%", height: window.innerWidth < 500 ? "45px" : "52px", outline: "none", fontWeight: "600", fontSize: "22px", fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"', textAlign: "center", background: "#7B7B7B", color: "white" }}
                                renderSeparator={<span className='mx-2'></span>}
                                renderInput={(props) => <input {...props} />}
                            />
                            {otpShow === 1 ? (

                                <p className='text-end text-danger pt-3' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }} disabled={
                                    isLoading || timer_resendOtp > 0 ? true : false
                                }
                                    onClick={() => {
                                        loginSubmit(formikPhNum.values, "resendOTP");
                                    }}>
                                    Resend OTP  {timer_resendOtp > 0 ? (
                                        <span className="timeLeft">
                                            ({timer_resendOtp}s)
                                        </span>
                                    ) : (
                                        ""
                                    )}</p>
                            ) : (
                                ""
                            )}
                            {formikPhNum.errors.otp ? (
                                <small className="invalid-otp error">
                                    {formikPhNum.errors.otp} <br />
                                </small>
                            ) : null}


                        </div>

                        <div className='mx-auto text-center'>
                            <button className="btn-register w-50" onClick={() => formikPhNum.handleSubmit()} disabled={isLoading}>Submit</button>
                        </div>

                    </div>
                </div>
                <div className='bg-otp-card-2'></div>
            </div>}
        </>

    );
};

export default Otp;