import React, { useState, useEffect, useRef, useCallback } from 'react';
import useInfiniteScroll from './useInfiniteScroll';
import FavStar from './FavStar';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import Loader from '../../../Component/Loader/Loader';

const DataTable = ({ data, loadMoreData, handleNavigation, formatSymbolName, updateFavPairs, favs, pairsLoader, tabName, myProfile }) => {
    const [visibleData, setVisibleData] = useState([]);
    const [page, setPage] = useState(0);
    const itemsPerPage = 10;

    // useEffect(() => {
    //     setVisibleData(()=>data)
    // }, []);
    // console.log(data)
    useEffect(() => {
        setVisibleData(data.slice(0, (page + 1) * itemsPerPage));
    }, [data, page]);

    const lastElementRef = useInfiniteScroll(() => {
        if (visibleData.length < data.length) {
            setPage((prevPage) => prevPage + 1);
        }
        loadMoreData();
    });
    return (
        <>
            {visibleData.map((dataSingle, index) => {
                const { symbol, lastTradePrice, priceChgPct, markPrice, baseCurrency, quoteCurrency } = dataSingle;
                const isLastElement = index === visibleData.length - 1;
                return (
                    <TableRow
                        sx={{ border: 0 }}
                        ref={isLastElement ? lastElementRef : null}
                        key={index}
                    >
                        <TableCell sx={{ lineHeight: 2, border: 0, color: '#FFFFFF', fontSize: '14px', cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px', padding: '6px 0px' }}>
                            <FavStar updateFavPairs={updateFavPairs} symbol={symbol} favs={favs} dataSingle={dataSingle} />
                            <span onClick={() => handleNavigation(symbol)}>
                                {formatSymbolName(baseCurrency, quoteCurrency)}
                            </span>
                        </TableCell>
                        <TableCell className={`${lastTradePrice <= markPrice ? 'color-green-futures' : 'color-red-futures'}`} sx={{ lineHeight: 2, border: 0, fontSize: '14px', padding: '6px 0px' }}>
                            {lastTradePrice}
                        </TableCell>
                        <TableCell className={`${(priceChgPct * 100) > 0 ? 'color-green-futures' : 'color-red-futures'}`} sx={{ lineHeight: 2, border: 0, fontSize: '14px', padding: '6px 0px' }}>
                            {(priceChgPct * 100).toFixed(2)}%
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export default DataTable;

