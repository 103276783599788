import React from 'react';
import NavbarOne from './separate/NavbarOne';
import Buycrypto from './Buycrypto';
import SellCryptoPro from './SellCryptoPro';

const InstaTrade = (props) => {
    const [tab, setTab] = React.useState(true);
    return (
        <div className='bg-default  text-white bg-particular pb-3' style={{ minHeight: '100vh' }}>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            /><section >

                <div className='container pt-5'>
                    <div>
                        <div className='tab-trade mx-auto'>
                            <button className={`${tab ? 'btn-trade' : 'btn-false'}`} onClick={() => {
                                setTab(true);
                            }} type='button'>BUY</button>
                            <button className={`${tab ? 'btn-false' : 'btn-trade-sell'}`} style={{ background: tab ? '' : '#dc3545' }} onClick={() => setTab(false)} type='button'>SELL</button>
                        </div>
                    </div>
                    {
                        tab ? <Buycrypto /> : <SellCryptoPro />
                    }
                </div>
            </section>
        </div>

    );
};

export default InstaTrade;