import React, { useState, useEffect } from "react";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material';
import "../assets/style.css";
import { styled } from '@mui/material/styles';
import { GoChevronLeft } from "react-icons/go";
import NavbarOne from "./separate/NavbarOne";
import Footer from "./separate/Footer";
import { makeRequest } from "../core/services/v1/request";
import Config from "../core/config/";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .Mui-expanded': {

    borderRadius: '17px 17px 0px 0px',
  },
  marginBottom: '24px',
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? '#535151'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  height: '65px',
  borderRadius: '17px',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
  backgroundColor: '#535151',
  borderRadius: '0px 0px 17px 17px',
}));
export default function Withdraw(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [faqList, setfaqList] = useState([]);
  const navigate = useNavigate();
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount < 0) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }



    getFaq();
  }, []);

  async function getFaq() {
    try {
      const params = {
        url: `${Config.V1_API_URL}faq/getfaq`,
        method: 'POST',
        body: { type: "common" }
      }
      const { status, data, error } = (await makeRequest(params));
      if (status && data) {
        setfaqList(data)
      }
    } catch (err) { }
  }
  async function backButton() {
    try {
      const length = window.history.length;
      if (length > 0) {
        window.history.back();
      } else {
        navigate("/");
      }
    } catch (err) { }
  }
  return (
    <div className="bg-default text-white bg-particular min-vh-100">
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <Helmet>
        <link rel="canonical" href="https://www.uniticexchange.com/faq" />
      </Helmet>
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <div>
                  <GoChevronLeft className="deposit-back-button-icon" onClick={() => backButton()} />
                  <span className="deposit-text-1">FAQs on Crypto Trading in India</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-second-section mb-5">
        <div className="container">
          <ThemeProvider theme={theme}>
            {faqList.length > 0 && faqList.map((row, i) => {
              if (row.type == "common") {
                return (
                  <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} >
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      {row?.title}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {row?.description}

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )
              }
            })
            }
          </ThemeProvider>
        </div>
      </div>
      <Footer />
    </div>
  );
}