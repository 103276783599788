import React, { useEffect, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ButtonB from "react-bootstrap/Button";
import { BiSort } from "react-icons/bi";
import InputAdornment from '@mui/material/InputAdornment';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../assets/style.css'
import {
    Autocomplete,
    Box,
    Input,
    Modal as ModalMUI,
    TextField,
    Typography,
} from '@mui/material'
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, md: 400 },
    bgcolor: '#2E2C2C',
    boxShadow: 24,
    padding: { xs: '10px', md: '25px 15px' },
    borderRadius: 2,
}
const TransferModal = ({ futuresBalance, walletData = [], fundLoading, fundMove }) => {
    const [spot, setSpot] = useState(0)
    const [futures, setFutures] = useState(null)
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [toAccount, setToAccount] = useState('futures');

    useEffect(() => {
        if (walletData.length > 0) {
            const spotBalance = walletData.find(data => data?.currencyName === 'Tether USD')
        setSpot(() => spotBalance?.balance || 0)
        }
    }, [walletData])

    const subTransfer = async () => {
        await fundMove(parseFloat(futures), toAccount)
        handleClose()
    }

    return (
        <div>
            <span className='ms-2' onClick={handleOpen}>Transfer</span>
            <ModalMUI
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={modalStyle}>
                    <Typography
                        sx={{
                            color: '#fff',
                            fontSize: { xs: 15, md: 22 },
                            fontWeight: 'bold',
                            paddingBottom: 1
                        }}
                    >
                        Transfer{' '}
                    </Typography>

                    <Box sx={{ marginBottom: { xs: 1, md: 2 } }}>
                        {/* <Typography
                            sx={{
                                color: '#fff',
                                marginBottom: 1.5,
                                fontSize: { xs: '12px', md: '16px' },
                            }}
                        >
                            Copy Trade Fund
                        </Typography> */}
                        <Box
                            sx={{
                                bgcolor: '#535151',
                                p: { xs: 1.5, md: 2.5 },
                                borderRadius: { xs: 3, md: 6 },
                                color: '#fff',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>From</Typography>
                                <Typography sx={{ fontSize: { xs: 14, md: 16 }, position: 'relative', left: toAccount === 'futures' ? '-105px' : '-85px' }}>{toAccount === 'futures' ? 'Spot Account' : 'Futures Account'}</Typography>
                                {/* <KeyboardArrowDownIcon /> */}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginY: { xs: 1, md: 2 },
                                }}
                            >
                                <Box className='cursor-pointer' onClick={() => setToAccount((data) => {
                                    if (data === 'spot') {
                                        return 'futures'
                                    }
                                    else {
                                        return 'spot'
                                    }
                                })}>
                                    {/* {toAccount === 'spot' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />} */}
                                    <BiSort fontSize={25} />
                                </Box>
                                <Box
                                    sx={{ width: '85%', height: '2px', bgcolor: '#fff' }}
                                ></Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>To</Typography>
                                <Typography sx={{ fontSize: { xs: 14, md: 16 }, position: 'relative', left: toAccount === 'futures' ? '-85px' : '-105px' }}>{toAccount === 'futures' ? 'Futures Account' : 'Spot Account'}</Typography>
                                {/* <KeyboardArrowDownIcon /> */}
                            </Box>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            color: '#fff',
                            marginBottom: 1,
                            fontSize: { xs: '12px', md: '16px' },
                        }}
                        id='demo-simple-select-label'
                    >
                        Batch Transfer
                    </Typography>
                    <Box>
                        {/* <Typography
                            sx={{
                                color: '#fff',
                                marginBottom: 1,
                                fontSize: { xs: '12px', md: '16px' },
                            }}
                            id='demo-simple-select-label'
                        >
                            Coin
                        </Typography> */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2" style={{
                                backgroundColor: '#535151',

                                color: 'white',
                                // border: 'none',
                                padding: '10px',
                                borderRadius: '25px 0px 0px 25px',
                                // '&::before': {
                                //     border: 'none',
                                // },
                            }}><img src='https://assets.coingecko.com/coins/images/325/small/Tether-logo.png' alt='' className='img-fluid' width={window.innerWidth > 650 ? 30 : 25} /></InputGroup.Text>
                            <Form.Control
                                placeholder="USDT"
                                value={'USDT'}
                                readOnly
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                style={{
                                    backgroundColor: '#535151',
                                    fontSize: window.innerWidth > 650 ? '1rem' : '0.8rem',
                                    color: 'white',
                                    // border: 'none',
                                    padding: '12px',
                                    borderRadius: '0px 25px 25px 0px',
                                    // '&::before': {
                                    //     border: 'none',
                                    // },
                                }}
                            />
                        </InputGroup>

                    </Box>
                    <Box
                        sx={{
                            marginY: { xs: 1, md: 2 },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'Space-between',
                                alignContent: 'center',
                            }}
                        >
                            <Typography
                                sx={{ color: '#fff', fontSize: { xs: '12px', md: '16px' } }}
                            >
                                Amount
                            </Typography>
                            {/* <Typography sx={{ color: '#C8C8C8', fontSize: '10px', textAlign: 'end' }}>
                                {toAccount === 'futures' ? <>Futures : {futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance)?.toFixed(2) : 0.00} USDT{' '}</> : <>Spot : {(spot || 0).toFixed(2)} USDT{' '}</>}
                            </Typography> */}

                        </Box>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={futures}
                                onChange={(e) => setFutures(() => e.target.value)}
                                placeholder="USDT"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                style={{
                                    backgroundColor: '#535151',
                                    fontSize: window.innerWidth > 650 ? '1rem' : '0.8rem',
                                    color: 'white',
                                    border: 'none',
                                    padding: '12px',
                                    borderRadius: '25px 0px 0px 25px',
                                    '&::before': {
                                        border: 'none',
                                    },
                                }}

                            />
                            <ButtonB variant="light" className='border text-primary' style={{ borderRadius: '0px 25px 25px 0px', }} onClick={() => setFutures(() => {

                                if (toAccount === 'futures') {
                                    return spot
                                }
                                else {
                                    return (+futuresBalance?.availableBalance || 0)
                                }
                            })}>Max</ButtonB>
                        </InputGroup>
                        <Typography sx={{ color: '#C8C8C8', fontSize: '10px', textAlign: 'end', marginTop: { xs: 1, md: 1 } }}>
                            {toAccount === 'futures' ? <>Spot : {(spot || 0).toFixed(2)} USDT{' '}</> : <>Futures : {futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance) : 0.00} USDT{' '}</>}
                        </Typography>
                    </Box>
                    <button
                        className='confirm-btn-transfer'
                        onClick={() => subTransfer()}
                        disabled={fundLoading}
                    >
                        {fundLoading ? 'Loading' : 'Confirm'}
                    </button>
                </Box>
            </ModalMUI>
        </div>
    );
};

export default TransferModal;