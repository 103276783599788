import React, { useEffect } from 'react';
import NavbarOne from "./separate/NavbarOne";
import error from "../assets/images/404.png";
import "../assets/styledev.css";

export default function Error404Content() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [])
  return (
    <center className="bg-default text-white bg-particular">
      <div className="container">
        <div className="row min-vh-100">
          <div className="col-lg-12 mt-5 pt-5">
            <h1 className='my-5'>404</h1>
            <p>Sorry! The page you’re looking for cannot be found.</p>
            <p>404 is a status code that tells a web user that a requested page is not available.</p>
            <img src={error} alt="comming soon" className='img-fluid-css' />
          </div>
        </div>
      </div>
    </center>
  )
}
