import React, { useState, useEffect } from "react";
import './Portfolio.css';
import {
    useNavigate
} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { Modal, Form } from "react-bootstrap";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
} from "chart.js";
import {
    Box,
    Tab,
    Tabs,
} from '@mui/material'
import * as yup from 'yup';
import { Formik } from 'formik';

import { useContextData } from '../../core/context/index'
import { dateFormat, showEmail } from '../../core/helper/date-format';
import Config from "../../core/config";
import { makeRequest, makeRequestWihActualStatus } from "../../core/services/v1/request";
import { toast } from "../../core/lib/toastAlert";
import { getCookie, setCookie } from "../../core/helper/cookie";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletIcon from '@mui/icons-material/Wallet';
import WalletListV2 from "./WalletListV2";
import WalletListFutures from "./WalletListFutures";
import OrderDetails from "../../Pages/separate/futuresTrading/dialogs.js/OrderDetails";
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme } from '@mui/material';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Legend
);


const validationSchema = yup.object({
    username: yup
        .string('Please enter the username')
        .required('Username is required'),
});

const drawerWidth = 165;
const drawerWidth2 = 200;
const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1260,
            xl: 1536,
        },
    },
});
function PortfolioV2() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [tab, setTab] = React.useState(0);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const { myProfile, setUserProfile, coindcxDecimal, futuresBalance } = useContextData();
    const initialValues = () => {
        return {
            username: myProfile && myProfile.username
        }
    }

    const [walletData, setWalletData] = useState([]);
    const [spotWalletData, setSpotWalletData] = useState([]);
    const [estimatedValue, setEstimatedValue] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [nicknameformOpen, setnicknameformOpen] = useState(false);
    const [balShow, setBalShow] = useState(getCookie("userBalShow") ? getCookie("userBalShow") : false);
    const [pendingOrderAmount, setPendingOrderAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [fundLoading, setFundLoading] = useState(false);
    const [openOrderDetails, setOpenOrderDetails] = useState(false);
    const [top, setTop] = useState(80);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setTop(0);
            } else {
                setTop(80);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleClickOpenOrderDetails = () => {
        setOpenOrderDetails(true);
    };
    const handleCloseOrderDetails = () => {
        setOpenOrderDetails(false);
    };
    useEffect(() => {
        getWalletCurrency();
        getSpotWalletCurrency();
    }, []);

    async function getWalletCurrency() {
        // setLoading(true);
        const params = {
            url: `${Config.V2_API_URL}wallet/getWalletCurrency?search=${search}`,
            method: 'GET'
        };
        const response = await makeRequest(params);


        if (response) {
            const {
                status = false,
                data = [],
                estimateINR = 0,
                estimateUSD = 0,
            } = response;

            setWalletData(data);
        }
        else {
        }
    }

    async function getSpotWalletCurrency() {
        setLoading(true);
        const params = {
            url: `${Config.V2_API_URL}wallet/getSpotHoldings?search=${search}`,
            method: 'GET'
        };
        const response = await makeRequest(params);

        if (response) {
            const {
                status = false,
                data = [],
                estimateINR = 0,
                estimateUSD = 0,
            } = response;
            setSpotWalletData(data);
            setEstimatedValue({
                estimateINR,
                estimateUSD
            });
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
        else {
            setSpotWalletData([]);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }

    const onSubmit = async (values) => {
        setisLoading(true);
        const params = {
            url: `${Config.V1_API_URL}user/updateMyProfile`,
            method: 'POST',
            body: values
        }
        const response = (await makeRequest(params));
        setisLoading(false);
        let type = "error";
        if (response.status) {
            type = 'success';
            setnicknameformOpen(false);
            toast({ type, message: "Username Updated Successfully!" });
        } else {
            toast({ type, message: response.message });
        }
        setUserProfile();
        setisLoading(false);
    }

    const balShowHideCall = () => {

        if (balShow) {
            setCookie('userBalShow', false);
            setBalShow(false);
        }
        else {
            setCookie('userBalShow', true);
            setBalShow(true);
        }
    }

    const toNavigateFirstCurrency = (type = "") => {
        if (walletData && walletData.length > 0 && (type === "deposit" || type === "withdraw")) {
            let firstData = walletData.filter((e) => e.curnType == "Fiat");
            if (firstData && firstData.length > 0) {
                navigate("/" + type + "/fiat/" + firstData[0]._id);
            }
        }
    }

    const getDetails = async () => {
        setLoading(true);
        const payload = {
            userID: myProfile && myProfile?._id
        }
        const params2 = {
            url: `${Config.V1_API_URL}buycrypto/Userbuycryptoodrders`,
            method: 'POST',
            body: payload
        };
        const response2 = await makeRequest(params2);
        if (response2) {
            const {
                data2 = [],
            } = response2;


            const orderpending = response2.data.filter(element => element.status == 2);
            const newArray = [];

            orderpending.forEach(obj => newArray.push(obj.amount));

            let sum = 0;

            for (let i = 0; i < newArray.length; i++) {
                sum += newArray[i];
            }
            setPendingOrderAmount(sum);
        }
        else {
        }

    }
    useEffect(() => {
        getDetails();
    }, [myProfile])

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    const handleChangeV3 = (event, newValue) => {
        setTab(() => newValue)
    }
    const fundMove = async (amount, toAccount, currencyId = '640f648499ad61d2ff936aa2') => {
        setFundLoading(() => true)
        const from = toAccount === 'spot' ? 'futures' : 'spot'
        // console.log(toAccount)
        const params = {
            url: `${Config.V1_API_URL}futures-fund/create-fund?from=${from}&to=${toAccount}`,
            method: 'POST',
            body: { amount: amount, currencyId: currencyId }
        }
        const response = (await makeRequestWihActualStatus(params));
        let type = "error";
        if (response.status) {
            // type = 'success';
            // toast({ type, message: response.message });
            handleClickOpenOrderDetails()
        } else {
            toast({ type, message: response.message });
        }
        setFundLoading(() => false)
        setUserProfile();
        getSpotWalletCurrency()
    }
    const data = [
        { id: 0, name: 'Spot Wallet', icon: <AccountBalanceWalletIcon /> },
        { id: 1, name: 'Futures Wallet', icon: <WalletIcon /> },
        { id: 2, name: 'Deposit', icon: <InboxIcon /> },
        { id: 3, name: 'Withdraw', icon: <CreditCardIcon /> },
    ]
    const drawer = (
        <div>
            {/* <Toolbar /> */}
            {/* <Divider /> */}
            <List>
                {data.map((el, index) => {
                    if (el?.id == 1) {
                        if ((!myProfile?.futuresSubAccount?.isActive || !myProfile?.futuresSubAccount?.uid)) {
                            return null;
                        }
                    }
                    return (
                        <ListItem key={index} disablePadding sx={{ background: el?.id == tab ? '#3d3d3d' : '', transition: 'all 0.2s ease-in-out' }}>
                            <ListItemButton onClick={() => { setTab(el?.id); setSearch('') }}>
                                <ListItemIcon sx={{ fontSize: { xs: '0.85rem', lg: '0.9rem' }, '&.MuiListItemIcon-root': { minWidth: '40px' } }}>
                                    {el?.icon}
                                </ListItemIcon>
                                <ListItemText primary={el?.name} sx={{
                                    '&.MuiListItemText-root .MuiTypography-root': {
                                        fontSize: { xs: '0.85rem', lg: '0.9rem' }
                                    }
                                }} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    );


    return (
        <section>
            <Helmet>
                <link rel="canonical" href="https://uniticexchange.com/portfolio" />
            </Helmet>

            <ThemeProvider theme={theme}>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        component="nav"
                        sx={{ width: { sm: drawerWidth, lg: drawerWidth2 }, flexShrink: { sm: 0 } }}
                        aria-label="mailbox folders"
                    >
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', sm: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: drawerWidth, lg: drawerWidth2 }, top: top, zIndex: 1, backgroundColor: 'transparent', transition: 'all 0.2s ease-in-out' },
                            }}
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, pl: 0, pr: 0, pt: window.innerWidth > 600 ? 1.5 : 1, pb: 0, width: { xs: `calc(100% - ${drawerWidth}px)`, lg: `calc(100% - ${drawerWidth2}px)` }, maxHeight: '89vh' }}
                    >
                        <Toolbar sx={{ display: { xs: 'block', sm: 'none' }, paddingLeft: 0, paddingRight: 0 }}>
                            <Box className='pt-2'>
                                <Tabs
                                    // centered
                                    value={tab}
                                    onChange={handleChangeV3}
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example"
                                    sx={{
                                        '& .MuiTabs-indicator': {
                                            display: 'none',
                                        },
                                        '& .MuiTab-root': {
                                            color: '#fff',
                                            fontSize: '12px',
                                            padding: '8px 0px'
                                        },
                                        '& .MuiTab-root.Mui-selected': {
                                            borderBottom: '3px solid #21CA50',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                >
                                    <Tab label='Spot' {...a11yProps(0)} />
                                    {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount?.uid) ? <Tab label='Futures' {...a11yProps(1)} /> : null}
                                    <Tab label='Deposit' {...a11yProps(2)} />
                                    <Tab label='Withdraw' {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                        </Toolbar>
                        {
                            tab === 0 ? <>
                                <div className="pb-1">
                                    {(() => {
                                        if (myProfile && myProfile.kycstatus == 3) {
                                            return (
                                                <div id="applicant_name_Enable_hide" className="mt-3">

                                                    <div className="col-lg container pb-2">

                                                        {" "}
                                                        {/* <img
                src={(myProfile && myProfile.profileimage != "") ?  myProfile.profileimage : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                alt="logo"
                className="img-user"
              /> */}
                                                        {
                                                            myProfile && myProfile.username && myProfile && myProfile.username.charAt(0)
                                                                ?
                                                                <p className="custom-letterAvatar">{myProfile.username.charAt(0)}</p>
                                                                :
                                                                <img
                                                                    src={(myProfile && myProfile.profileimage != "") ? myProfile.profileimage : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                                                                    alt="logo"
                                                                    className="img-user"
                                                                />
                                                        }

                                                    </div>

                                                    <div className="col-lg" >
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <p className="password-text-44">
                                                                        {" "}

                                                                        <b>Name : {(myProfile && myProfile.username) ? myProfile.username : "---"}</b>{" "}

                                                                        <span className="btn-muted">Level {(myProfile && myProfile.level) ? myProfile.level : "1"}</span>

                                                                    </p>
                                                                </div>
                                                                {
                                                                    myProfile &&
                                                                    <div className="col-lg">
                                                                        <small className="d-block tittle-text">Two-Factor Authentication</small>
                                                                        <small className={"d-block data-info " + (myProfile.tfaenablekey ? "color-green" : "color-red")}>{myProfile.tfaenablekey ? "Enabled" : "Disabled"}</small>
                                                                    </div>}
                                                                <div className="col-lg">
                                                                    <small className="d-block tittle-text">Identity Verification</small>
                                                                    {myProfile && myProfile.kycstatus != undefined ?
                                                                        <>
                                                                            {myProfile.kycstatus == 0 ? <small className="color-yellow d-block data-info">Waiting for KYC approval</small> :
                                                                                myProfile.kycstatus == 1 ? <small className="color-green d-block data-info">Verified</small> :
                                                                                    myProfile.kycstatus == 2 ? <small className="color-red d-block data-info">Rejected</small> :
                                                                                        myProfile.kycstatus == 3 && <small className="color-red d-block data-info">Not Verified</small>}
                                                                        </> : <small className="color-green d-block data-info"></small>}
                                                                </div>
                                                                {myProfile && myProfile.lastLoginBy && myProfile.lastLoginBy.from && <div className="col-lg ">
                                                                    <small className="d-block tittle-text">Last login by</small>
                                                                    <small className="d-block data-info">
                                                                        {myProfile.lastLoginBy.from} - {myProfile.lastLoginBy.from.toLowerCase() === "email" ? showEmail(myProfile.lastLoginBy.val) : myProfile.lastLoginBy.val}
                                                                    </small>
                                                                </div>}
                                                                <div className="col-lg ">
                                                                    <small className="d-block tittle-text">Last login time</small>
                                                                    <small className="d-block data-info">
                                                                        {dateFormat(myProfile && myProfile?.lastLoginTime)}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } else {
                                            return (
                                                <p>{""}</p>
                                            )
                                        }

                                    })()}
                                </div>
                                <WalletListV2
                                    tabName={"spot"}
                                    balShow={balShow}
                                    walletData={spotWalletData}
                                    loading={loading}
                                    search={search} setSearch={setSearch}
                                    coindcxDecimal={coindcxDecimal}
                                    pendingOrderAmount={pendingOrderAmount}
                                    setEstimatedValue={setEstimatedValue}
                                    estimatedValue={estimatedValue}
                                    balShowHideCall={balShowHideCall}
                                    toNavigateFirstCurrency={toNavigateFirstCurrency}
                                />
                            </>
                                : tab == 1 ?
                                    <>
                                        <WalletListFutures
                                            tabName={"futures"}
                                            futuresBalance={futuresBalance}
                                            balShow={balShow}
                                            walletData={spotWalletData}
                                            loading={loading}
                                            coindcxDecimal={coindcxDecimal}
                                            pendingOrderAmount={pendingOrderAmount}
                                            setEstimatedValue={setEstimatedValue}
                                            estimatedValue={estimatedValue}
                                            balShowHideCall={balShowHideCall}
                                            fundMove={fundMove}
                                            fundLoading={fundLoading}
                                            toNavigateFirstCurrency={toNavigateFirstCurrency} />
                                    </>
                                    : tab == 2 ? toNavigateFirstCurrency("deposit") : tab == 3 ? toNavigateFirstCurrency("withdraw") : <></>
                        }

                        <Modal show={nicknameformOpen} onHide={() => setnicknameformOpen(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Set Username</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    initialValues={initialValues()}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        onSubmit(values)
                                    }}
                                >
                                    {(formikProps) => {
                                        const { values, touched, errors, handleChange, handleBlur, handleSubmit } = formikProps;

                                        return (
                                            <Form >
                                                <div className="row  ">
                                                    <div className="col-lg-12 ">
                                                        <div className='mt-4'>
                                                            <input
                                                                class="form-control w-100 mt-1"
                                                                type="text"
                                                                label="Username"
                                                                id="username"
                                                                name='username'
                                                                autoComplete='off'
                                                                value={values.username != null ? values.username : myProfile?.username}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={touched.username && Boolean(errors.username)}
                                                                helperText={touched.username && errors.username}
                                                            />
                                                            {errors.username ? <small className="invalid-username error">{errors.username}</small> : null}
                                                            {myProfile && myProfile.username && <p className='color-white f-14 mt-4 mb-0'>Last edit time was {dateFormat(myProfile?.updatedOn)}.</p>}
                                                        </div>
                                                    </div>
                                                    <div className='row mt-5'>
                                                        <div className='col'>
                                                            <button
                                                                type="button"
                                                                className="btn text-white btn-col w-100 mt-4"
                                                                onClick={() => handleSubmit(values)}
                                                                disabled={isLoading}
                                                            >
                                                                Ok
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                        {openOrderDetails && <OrderDetails open={openOrderDetails} handleClickOpen={handleClickOpenOrderDetails} handleClose={handleCloseOrderDetails} />}
                    </Box>
                </Box>
            </ThemeProvider>
        </section>
    );
}

export default PortfolioV2;
