import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '1px solid rgba(200, 200, 200, 0.32)'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function OrderConfirmation({ open, handleClickOpen, handleClose, tradeParams, formatSymbolName, submitTrade, setAmount,amount }) {
    const [checked, setChecked] = React.useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    React.useEffect(() => {
        if (checked === true) {
            sessionStorage.setItem('confirm', true);
        }
        else {
            sessionStorage.setItem('confirm', false);
        }
    }, [checked])
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Order Confirmation
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div className='d-flex justify-content-start align-items-center' style={{ gap: '10px' }}>
                        {/* <div><img src='/assets/bitcoin.png' alt='' className='img-fluid' width={30} /></div> */}
                        <div><p>{formatSymbolName(tradeParams?.baseCurrency, tradeParams?.quoteCurrency)} <span className={`${tradeParams?.side == 'BUY' ? 'color-green-futures' : 'color-red-futures'}`}>{tradeParams?.leverage}x</span></p></div>
                    </div>
                    <div className='my-3'>
                        <div className='row handlePropsColorV2' style={{ display: tradeParams?.type == 'market' ? 'none' : '' }}>
                            <div className='col-6'>
                                <p>Price</p>
                            </div>
                            <div className='col-6'>
                                <p>{tradeParams?.price} {tradeParams?.quoteCurrency}</p>
                            </div>
                        </div>
                        <div className='row handlePropsColorV2 pt-1'>
                            <div className='col-6'>
                                <p>Amount</p>
                            </div>
                            <div className='col-6'>
                                <p>{amount} {tradeParams?.baseCurrency}</p>
                            </div>
                        </div>
                        <hr />
                        <div className='row handlePropsColorV2'>
                            <div className='col-6'>
                                <p>Order Value</p>
                            </div>
                            <div className='col-6'>
                                <p>{tradeParams?.quantity} {tradeParams?.quoteCurrency}</p>
                            </div>
                        </div>
                        {/* <div className='row handlePropsColorV2 pt-1'>
                            <div className='col-6'>
                                <p>Cost</p>
                            </div>
                            <div className='col-6'>
                                <p className={`${tradeParams?.cost < 0 ? 'color-red-futures' : ''}`}>{tradeParams?.cost} {tradeParams?.quoteCurrency}</p>
                            </div>
                        </div> */}
                    </div>
                    <div>
                        <FormControlLabel control={<Checkbox checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }} size="small" />} label="Don’t Show Again" sx={{
                                '& .MuiTypography-root': {
                                    color: '#C8C8C8',
                                    fontFamily: "Open Sans",
                                    fontSize: '0.725rem',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                },
                                '& .MuiCheckbox-root': {
                                    color: '#D9D9D9'
                                }
                            }} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn-cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='btn-confirm' onClick={async () => {
                        const finalSub = await submitTrade(tradeParams);
                        if (finalSub) {
                            setAmount(() => '')
                        }
                        handleClose()
                    }}>

                        Confirm
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}