import React from 'react';
import './contact.css';
import NavbarOne from '../../Pages/separate/NavbarOne';
import Footer from '../../Pages/separate/Footer';

const ThankYou = (props) => {
    return (
        <section className='bg-default text-white bg-particular'>
            <div>
                <NavbarOne setTheme={props.setTheme} theme={props.theme} />
            </div>
            <div className='container pb-5 pt-3' style={{ minHeight: '90vh' }}>
                <div className='text-center'>
                    <img src='/assets/thank.svg' alt='' className='img-fluid w-custom' />
                </div>
                <div className='pt-3'>
                    <h5 className='text-center'>Your Response Is Recorded, Will Get Back To You Shortly.</h5>
                </div>
            </div>

            <div>
                <Footer />
            </div>
        </section>
    );
};

export default ThankYou;