import React from 'react';
import TradingViewFutures from './separate/futuresTrading/tradeChartFutures/trading-view-futures';
import { useParams } from 'react-router-dom';

const FuturesTradeChart = (props) => {
    const { pairName } = useParams()
    return (
        <div className='bg-default text-white pb-0 overflow-hidden min-vh-100'>
            <div className=''>
                <div className='container-fluid p-0 overflow-hidden'>
                    <div className='col-lg-12 mx-auto trading-page-box'>
                        <div className='row p-0'>
                            <div className='col-lg-9 trading-page-box-left-inner-section pe-lg-0'>
                                <div className=''>
                                    <div className='trading-inner-right-section'>
                                        {pairName &&
                                            <div id="tradingviewwidgetFutures">
                                                {pairName && <TradingViewFutures
                                                    symbol={pairName}
                                                    theme={props.theme}
                                                />
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FuturesTradeChart;