import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toast } from '../../../../core/lib/toastAlert';
import EditIcon from '@mui/icons-material/Edit';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '1px solid rgba(200, 200, 200, 0.32)'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function EditLeverage({ elem, getBaseAndTargetCurrency }) {
    const { symbol = '', realLeverage = 0, currentQty = 0, currentCost = 0, avgEntryPrice = 0, markPrice = 0, liquidationPrice = 0, posInit = 0, unrealisedPnl = 0, realisedPnl = 0, } = elem
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [value, setValue] = React.useState(realLeverage);
    const [marks, setMarks] = React.useState([]);
    const [isLimit, setIsLimit] = useState(false);
    const [lever, setLever] = useState(0);

    useEffect(() => {
        const getPair = getBaseAndTargetCurrency(symbol)
        setLever(() => getPair?.pair?.maxLeverage || 0)
    }, [symbol])
    useEffect(() => {
        const increment = 25;
        const marksValue = [];

        for (let i = 0; i <= lever; i += increment) {
            const label = i === lever ? `${i}x` : `${i}`;
            const labelx = i === lever ? `${i}x` : '';
            marksValue.push({ value: i, label: value >= i ? label : labelx });
        }
        setMarks(() => marksValue);
    }, [value, lever])

    // useEffect(() => {
    //     if (value >= lever) {
    //         setIsLimit(() => true);
    //     }
    // }, [])

    const handleSliderChange = (event, newValue) => {
        if (newValue <= 0) return
        if (newValue > lever) {
            return setIsLimit(() => true);
        }
        setIsLimit(() => false);
        setValue(newValue);
    };
    function valuetext(value) {
        return `${value}x`;
    }

    function valueLabelFormat(value) {
        return value + 'x';
    }
    return (
        <React.Fragment>
            <EditIcon fontSize="small" className='cursor-pointer' onClick={() => handleClickOpen()} />
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'sm'}

            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Adjust Leverage
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className='overflow-hidden'>
                    <div className='d-flex justify-content-start align-items-center' style={{ gap: '10px' }}>
                        {/* <div><img src='/assets/bitcoin.png' alt='' className='img-fluid' width={30} /></div> */}
                        <div><p>{getBaseAndTargetCurrency(symbol)?.currency}</p></div>
                    </div>
                    <div className='my-3'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Leverage</Form.Label>
                            <Form.Control type="text" value={`${value + 'x'}`} className='inputOfModify' />
                        </Form.Group>
                    </div>

                    <Box className='p-2 mt-2'>
                        <Slider
                            aria-label="Default"
                            value={value}
                            onChange={handleSliderChange}
                            valueLabelFormat={valueLabelFormat}
                            getAriaValueText={valuetext}
                            step={1}
                            valueLabelDisplay="auto"
                            marks={marks}
                            max={lever ? lever : 100}
                            sx={{
                                '&.MuiSlider-root': {
                                    color: '#ffff',
                                    height: '2px',
                                    padding: '0.5rem 0'
                                },
                                '&.MuiSlider-root .MuiSlider-markLabel': {
                                    // color: '#ffff',
                                    color: '#ffff',
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    top: '25px'
                                },
                                '&.MuiSlider-root .MuiSlider-markLabelActive': {
                                    color: '#ffff',
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                },
                                '&.MuiSlider-root .MuiSlider-thumb': {
                                    width: '16px',
                                    height: '16px',
                                    flexShrink: 0,
                                    background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                    strokeWidth: '1px',
                                    border: '1px solid #FFFF'
                                },
                                '&.MuiSlider-root .MuiSlider-rail': {
                                    color: '#ffff',
                                    backgroundColor: '#ffff',
                                    opacity: 1
                                },
                                '&.MuiSlider-root .MuiSlider-track': {
                                    color: '#ffff',
                                    background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                    opacity: 1,
                                    border: 'none'
                                },
                                '&.MuiSlider-root .MuiSlider-mark': {
                                    color: '#ffff',
                                    backgroundColor: '#2E2C2C',
                                    width: '16px',
                                    height: '16px',
                                    flexShrink: 0,
                                    strokeWidth: '1px',
                                    border: '1px solid #FFFF',
                                    borderRadius: '50%',
                                    transform: 'translate(-7px, -50%)',
                                    webkitTransform: 'translate(-7px, -50%)',
                                    opacity: 1
                                },
                                '&.MuiSlider-root .MuiSlider-markActive': {
                                    width: '16px',
                                    height: '16px',
                                    flexShrink: 0,
                                    background: 'linear-gradient(180deg, #10EDB6 0%, #427AEC 100%)',
                                    strokeWidth: '1px',
                                    border: '1px solid #FFFF'
                                },
                                '&.MuiSlider-root .MuiSlider-valueLabel ': {

                                }
                            }}
                        />
                    </Box>
                    {isLimit ? <p className='text-danger fw-bold' style={{ fontSize: '0.75rem' }}>Maximum Leverage Reached</p> : null}
                    <div className='py-2'>
                        <p className='mb-1' style={{ color: '#C8C8C8', fontSize: '0.75rem' }}>Max Leverage <span style={{ color: '#FFF' }}>{lever + 'x'}</span></p>
                        {/* <p className='mb-1' style={{color: '#C8C8C8', fontSize: '0.75rem'}}>Max Position  Size at Adjusted Leverage <span style={{color: '#FFF'}}>868.13 USDT</span></p>
                        <p className='mb-1' style={{color: '#C8C8C8', fontSize: '0.75rem'}}>Margin Required <span style={{color: '#FFF'}}>0.0 USDT</span></p>
                        <p className='mb-1' style={{color: '#C8C8C8', fontSize: '0.75rem'}}>Estimated liquidation Price <span style={{color: '#FFF'}}>0.0</span></p> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn-cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='btn-confirm' onClick={() => { handleClose() }}>

                        Confirm
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}