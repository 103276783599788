import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config/";
import { dateFormat } from '../../core/helper/date-format';
import { toast } from "../../core/lib/toastAlert";
import { RiFileCopyFill } from "react-icons/ri";
import $ from "jquery";
import Loader from "../../Component/Loader/Loader";
export default function TransactionhistoryCrypto(props) {

  const [transactionList, settransactionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    tab: 'Crypto',
    type: 'Deposit'
  });
  const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "500",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center',
    backgroundColor: 'transparent'

  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "#C8C8C8",
    textAlign: 'center',
    backgroundColor: 'transparent'

  }));

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center',
    backgroundColor: 'transparent'
  }));
  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      // const getStatusText = $(this).text();
      // $(this)
      //   .closest(".status_dropdown")
      //   .find(".status__btn")
      //   .text(getStatusText);
      const generateStatusClass = `${$(this).attr("data-class")}-status`;
      $(this)
        .closest(".status_dropdown")
        .attr("data-color", `${generateStatusClass}`);
    });
  }, []);

  useEffect(() => {
    getHistory();
  }, [filter]);

  async function filterChange(data) {
    const {
      target = "",
      value = ""
    } = data;
    let filterCopy = Object.assign({}, filter);
    if (target && value) {
      if (target == 'type') {
        filterCopy.type = value;
        setFilter(filterCopy);
      }
    }
  }

  async function getHistory() {
    setLoading(true);
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistoryWithFilter`,
        method: 'POST',
        data: { filter }
      }
      const response = (await makeRequest(params));
      if (response.status) {
        settransactionList(response.data)
      }
    } catch (err) { }
    setLoading(false);
  }

  function copyText(data = {}) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    let message = "";
    if (data.type == "TxnId") {
      message = "Txn id copied successfully!";
    }
    toast({ type: 'success', message });
  }

  function bonusAmount(trxn) {
    try {
      if (typeof trxn.bonusData == 'object' && trxn.bonusData.bonusGiven > 0) {
        return trxn.bonusData.bonusGiven.toFixed(trxn.currencyId.siteDecimal);
      } else {
        if (trxn.depositType == 'Pre Booking') {
          return '-';
        } else {
          return 'NA';
        }
      }
    } catch (err) { }
  }

  return (<>
    <div className="transaction-history-second-section pb-3 ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div class="d-flex flex-row mt-3 transaction-history-select-section flex-wrap gap-3">
              <div class="me-3">
                <span className="transaction-text-2">Type</span>
                <div className="custom-dropdown-trans-section mt-1">
                  <div
                    class="dropdown custom-dropdown status_dropdown"
                    data-color="created-status"
                  >
                    <button
                      class="select-dropdown-btn dropdown-toggle w-100 d-flex align-items-center justify-content-between status__btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-offset="0,12"
                    >
                      {filter.type}
                    </button>
                    <ul
                      class="dropdown-menu status_change"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          data-class="stoplimit"
                          href="#"
                          onClick={() => filterChange({
                            target: 'type',
                            value: "Deposit"
                          })}
                        >
                          Deposit
                        </a>
                      </li>
                      <li >
                        <a
                          class="dropdown-item"
                          data-class="trailingstop"
                          href="#"
                          onClick={() => filterChange({
                            target: 'type',
                            value: "Withdraw"
                          })}
                        >
                          Withdraw
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container ">
      <div className="row align-items-center">
        <div className="col-lg-12">
          {loading ? <Loader /> :
            <TableContainer className="mt-4">
              <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                <StyledTableHead>
                  <TableRow>
                    <StyledTableHeadRow>Date</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Type</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Asset</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Amount</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Bonus</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Txn ID</StyledTableHeadRow>
                    <StyledTableHeadRow align="right">Status</StyledTableHeadRow>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {(transactionList && transactionList.length > 0) ? transactionList.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {dateFormat(item.createdDate)}
                      </StyledTableCell>
                      <StyledTableCell align="right">{item.type}</StyledTableCell>
                      <StyledTableCell align="right">{item.currencyDet && item.currencyDet.currencySymbol}</StyledTableCell>
                      <StyledTableCell align="right">{item.amount}</StyledTableCell>
                      <StyledTableCell align="right">{bonusAmount(item)}</StyledTableCell>
                      <StyledTableCell align="right">{item.txnId != "" ? <p onClick={() => copyText({
                        type: "TxnId",
                        text: item.txnId
                      })}><RiFileCopyFill className="dest-addres-link"

                        /><label className="dest-addres-ellipsis">
                          {item.txnId}
                        </label></p> : "-"}</StyledTableCell>
                      <StyledTableCell align="right">{item.status == 0 ? 'Pending' : item.status == 1 ? "Approved" : item.status == 2 ? 'Rejected' : 'Processing'}</StyledTableCell>
                    </TableRow>


                  )) : <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell> <img
                      className="no-record-image"
                      src="/assets/no-re.png"
                      alt="no-record"
                    />
                      <br />
                      <span className="text-center">No Records Found</span></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
      </div>
    </div>
  </>
  );
}