import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Roboto",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '1px solid rgba(200, 200, 200, 0.32)'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Roboto",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
        justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Roboto",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function MarginMode({ open, handleClickOpen, handleClose,crossMenu, setCrossMenu }) {
const [prepCross,setPrepCross]=React.useState(crossMenu)
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    BTCUSDT Perpetual Margin Mode
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div className='w-100 dialog-top-button mb-1' style={{ gap: '5px' }}>
                        <div className='w-100'> <button style={{border:prepCross==='cross'? '1px solid #10EDB6': '', transition: 'border-color 0.3s ease-in-out'}} onClick={()=>setPrepCross('cross')}>
                            Cross
                        </button></div>
                        <div className='w-100'> <button style={{border:prepCross==='isolated'? '1px solid #10EDB6': '', transition: 'border-color 0.3s ease-in-out'}} onClick={()=>setPrepCross('isolated')}>

                            Isolated
                        </button></div>
                    </div>

                    <div className='text-center py-3'>
                        <h6>Switching the Margin mode will only Apply to the Selected Contract</h6>
                    </div>

                    <div>
                        <p className='mb-3' style={{
                            color: '#C8C8C8',
                            fontFamily: 'Roboto',
                            fontSize: '0.975rem',
                            fontStyle: 'normal',
                            fontWeight: '400',
                        }}><span style={{  fontWeight: '600',}}>Cross Margin Mode :</span> All Cross Position under the same margin asset the share same asset cross margin balance .In the event of liquidation your assets full margin balance along with any remaining open position under the asset may be forfeited</p>
                        <p className='mb-3' style={{
                            color: '#C8C8C8',
                            fontFamily: 'Roboto',
                            fontSize: '0.975rem',
                            fontStyle: 'normal',
                            fontWeight: '400',
                        }}><span style={{  fontWeight: '600',}}>Isolated Margin Mode :</span> All Cross Position under the same margin asset the share same asset cross margin balance .In the event of liquidation your assets full margin balance along with any remaining open position under the asset may be forfeited</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn-confirm' onClick={()=> {setCrossMenu(()=> prepCross); handleClose()}}>

                        Confirm
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}