import React, { useState } from 'react';
import CoinInfo from './CoinInfo';
import TradingRules from './TradingRules';
import LeverageMargin from './LeverageMargin';
import FundingHistory from './FundingHistory';

const Info = () => {
    const [tabValue, setTabValue] = useState(0);

    return (
        <div className=''>
            <div className='handleCoinInfoTop'>
                <div>
                    <p onClick={() => setTabValue(0)} style={{ color: tabValue === 0 ? '#ffff' : '' }}>Coin Info</p>
                    <p onClick={() => setTabValue(1)} style={{ color: tabValue === 1 ? '#ffff' : '' }}>Trading Rules</p>
                    <p onClick={() => setTabValue(2)} style={{ color: tabValue === 2 ? '#ffff' : '' }}>leverageMargin</p>
                    <p onClick={() => setTabValue(3)} style={{ color: tabValue === 3 ? '#ffff' : '' }}>Funding History</p>

                </div>
                <div>
                    <p onClick={() => setTabValue(4)} style={{ color: tabValue === 4 ? '#ffff' : '' }}>Original</p>
                    <p onClick={() => setTabValue(5)} style={{ color: tabValue === 5 ? '#ffff' : '' }}>Trading View</p>
                    <p onClick={() => setTabValue(6)} style={{ color: tabValue === 6 ? '#ffff' : '' }}>Depth</p>

                </div>
            </div>

            <div className='pt-2'>
                {
                    tabValue === 0 ? <CoinInfo /> : tabValue === 1 ? <TradingRules /> : tabValue === 2 ? <LeverageMargin /> : tabValue === 3 ? <FundingHistory /> : tabValue === 4 ? <p>Original</p> : tabValue === 5 ? <p>Trading View</p> : tabValue === 6 ? <p>Depth</p> : null
                }
            </div>

        </div>
    );
};

export default Info;