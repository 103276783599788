import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useParams,
    useNavigate,
} from "react-router-dom";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoFile } from 'react-icons/go';
import { BsPersonBoundingBox } from 'react-icons/bs';
import { HiCurrencyDollar } from 'react-icons/hi';
import { TbReceipt } from 'react-icons/tb';
import { IoMdArrowDropdown } from 'react-icons/io';
import { RiAddCircleFill } from 'react-icons/ri';

const P2PHeaderComponent = () => {
    const navigate = useNavigate();

    return (
        <section class="p2p-trade-hero-section-two-nav-bg">
            <div className="container col-lg-6 col-12">
                <div className="d-flex flex-row justify-content-end p2p-trade-hero-second-nav py-3">
                    <div className=" align-items-center px-lg-4 px-2">
                        <div className="">
                            <a href="javascript:void(0)" onClick={() => navigate("/P2P")} className="p2p-second-nav-content-title">P2P</a>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-lg-4 px-2">
                        <div className="">
                            <GoFile className="p2p-second-nav-icons" />
                        </div>
                        <div className="ps-2">
                            <a href="javascript:void(0)" onClick={() => navigate("/p2p-orders-list")} className="p2p-second-nav-content-title">Orders</a>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-lg-4 px-2">
                        <div className="">
                            <BsPersonBoundingBox className="p2p-second-nav-icons" />
                        </div>
                        <div className="ps-2">
                            <a href="javascript:void(0)" onClick={() => navigate("/p2p-user-center")} className="p2p-second-nav-content-title">P2P User Center</a>
                        </div>
                    </div>
                    <div className="align-items-center px-lg-4 px-2">

                        <div className="ps-1">
                            <div class="dropdown">
                                <span class=" p2p-second-nav-content-title dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <BsThreeDotsVertical className="p2p-second-nav-icons" /> More
                                </span>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a class="dropdown-item align-items-center" href="javascript:void(0)" onClick={() => navigate("/p2p-user-center")}>
                                            <span className="align-items-center "><HiCurrencyDollar className="p2p-second-nav-icons-more-button" /></span>
                                            <span className="ps-1 align-items-center">Payment Methods</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="javascript:void(0)" onClick={() => navigate("/postAd")}>
                                            <span className="align-items-center "><RiAddCircleFill className="p2p-second-nav-icons-more-button" /></span>
                                            <span className="ps-1 align-items-center">Post new Ad</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="javascript:void(0)" onClick={() => navigate("/myads")}>
                                            <span className="align-items-center "><TbReceipt className="p2p-second-nav-icons-more-button" /></span>
                                            <span className="ps-1 align-items-center">My ads</span>
                                        </a>
                                    </li>

                                </ul>
                                <IoMdArrowDropdown className="p2p-second-nav-icons" />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default P2PHeaderComponent;