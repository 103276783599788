import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { getCookie, deleteCookie } from "../helper/cookie";
import { getMyProfile, getSiteSettings, getP2PSettings, getMyFuturesBalance } from "./helper";
import authReducer from "./reducer";
import Config from "../config";
import { makeRequest } from "../services/v1/request";

const initialState = {
  siteSettings: null,
  myProfile: null,
  p2pSettings: null,
  futuresBalance: null
};

const AuthContext = createContext();

const useContextData = () => {
  const context = useContext(AuthContext);
  // console.log('context----', context)
  if (context === undefined) {
    throw new Error(`Context missing provider`);
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [coindcxDecimal, setCoindcxDecimal] = useState([]);
  const [profileLoading, setProfileLoading] = useState(true)
  const [spotPnl, setSpotPnl] = useState([]);
  const getMarketDetails = async () => {
    const params = {
      url: `${Config.V1_API_URL}trade/getMarketDetails`,
      method: "GET",
    };
    const response = await makeRequest(params);
    if (response.status) {
      const dataTable = {};
      response.message.forEach(item => {
        dataTable[item.coindcx_name] = item;
      });
      setCoindcxDecimal(() => dataTable)

    }
  }
  const getPnlSpot = async () => {
    const params = {
      url: `${Config.V1_API_URL}spot-signal/get-pnl-spot-signal`,
      method: "GET",
    };

    const response = await makeRequest(params);
    if (response.status) {
      setSpotPnl(() => response?.data)
    }
  }
  useEffect(() => {
    async function apiForContext() {
      const settings = await getSiteSettings();
      if (settings.status) {
        dispatch({
          type: 'SET_SITE_SETTINGS',
          payload: settings.message
        });
      }
      const p2psettings = await getP2PSettings();
      if (p2psettings.status) {
        dispatch({
          type: 'SET_P2P_SETTINGS',
          payload: p2psettings.data
        });
      }
      if (getCookie('userToken')) {
        let userData = await setUserProfile();
        setFuturesBalance()
        if (!userData) {
          await deleteCookie('userToken');
          window.location.reload();
        }
      }
    }
    apiForContext();
    getMarketDetails();
    getPnlSpot();
  }, []);

  const setFuturesBalance = async () => {
    const balance = await getMyFuturesBalance();
    if (balance.status) {
      dispatch({
        type: 'SET_FUTURES_BALANCE',
        payload: balance.data
      });
      return true;
    } else {
      return false;
    }
  }
  const setUserProfile = async () => {
    setProfileLoading(() => true)
    const profile = await getMyProfile();
    if (profile.status) {
      dispatch({
        type: 'SET_MY_PROFILE',
        payload: profile.data
      });
      setFuturesBalance()
      setProfileLoading(() => false)
      return true;
    } else {
      setProfileLoading(() => false)
      return false;
    }
  }


  const stateInfo = {
    ...state,
    setUserProfile,
    dispatch,
    coindcxDecimal,
    spotPnl,
    setFuturesBalance,
    profileLoading
  };
  return (
    <AuthContext.Provider value={stateInfo}>{children}</AuthContext.Provider>
  );
}
export { useContextData, AuthProvider };
