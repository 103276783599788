import React from 'react'
import { Box, Typography } from '@mui/material'

const FuturesTrading = () => {
  return (
    <Box
      sx={{
        background: '#EAFAEF',
        padding: '20px 10px',
        // overflow: 'auto'
      }}
    >
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontWeight: 'bold',
          fontSize: {xs:24,sm:28, md:30, xl:32},
            cursor:'default'
        }}
        variant='h3'
        onClick={()=> window.open('https://utistaking.com/', '_blank', 'noopener,noreferrer')}
      >
        Futures Trading
      </Typography>
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontSize: 14,
          marginBottom: '30px',
           paddingBottom: '10px'
        }}
      >
       Spot vs Futures comparision
      </Typography>
<Box data-aos='fade-down'
  data-aos-duration='1500' sx={{ 
  
  bgcolor: '#282525',
  overflow: 'auto',
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#21CA50',
},
  
}}>
      <Box
        sx={{
          minWidth: '800px',
          // overflow:'auto',
          padding: {
            xs: '20px',
            sm: '15px 50px',
          },
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
         
        }}
      >
        <Box>
          {[
            'Profit Opportunities',
            'Owner ship of Crypto',
            'Capital Requirement',
            'Liquidity',
            'Price',
          ].map((i) => {
            return (
              <Box
                sx={{
                  borderRadius: '9px',
                  border: '1px solid #000',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  background:
                    'linear-gradient(90deg, #25CA21 -33.2%, #FFF 100%)',
                  textAlign: 'center',
                  padding: '0px 20px',
                  marginBottom: 2,
                  fontWeight: 600,
                  fontSize: {xs:14,xl:15},
                  maxWidth: { md: '200px' },
                  height: {xs:55,xl:60},
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {i}
              </Box>
            )
          })}
        </Box>

        <Box>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'white',
              fontWeight: 'bold',
              fontSize: {xs:22,xl:24},
              marginBottom: '20px',
            }}
          >
            Spot
          </Typography>
          {[
            'Yes',
            'Make Profits in Bull Market',
            'Pay Full value the Assest',
            'Good Liquidity',
            'Spot Price',
          ].map((i) => {
            return (
              <Box
                sx={{
                  bgcolor: '#ffc107f0',
                  borderRadius: '9px',
                  border: '1px solid #000',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  textAlign: 'center',
                  padding: '0px 20px',
                  marginBottom: 2,
                  color: '#fff',
                  fontWeight: 600,
                  fontSize: {xs:14,xl:15},
                  maxWidth: { md: '200px' },
                  height: {xs:55,xl:60},
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {i}
              </Box>
            )
          })}
        </Box>

        <Box>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'white',
              fontWeight: 'bold',
              fontSize: {xs:22,xl:24},
              marginBottom: '20px',
            }}
          >
            Futures
          </Typography>
          {[
            'No',
            'Make Profits in Bull and Bear Market',
            'Pay as Low 08% of Assets Value',
            'Better Liquidity',
            'Spot Price Permium',
          ].map((i) => {
            return (
              <Box
                sx={{
                  bgcolor: '#21CA50',
                  borderRadius: '9px',
                  border: '1px solid #000',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  textAlign: 'center',
                  padding: '0px 20px',
                  marginBottom: 2,
                  color: '#fff',
                  fontWeight: 600,
                  fontSize: {xs:14,xl:15},
                  maxWidth: { md: '200px' },
                  height: {xs:55,xl:60},
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {i}
              </Box>
            )
          })}
        </Box>
      </Box>
</Box>

      <Box
        data-aos='fade-down'
        data-aos-duration='1500'
        sx={{
          fontSize: '16px',
          marginTop: 5,
        }}
      >
        <ul>
          {[
            'A crypto futures contract is an agreement to buy or sell an asset at a specific time in the future.',
            'Futures trading mainly serves three purposes: hedging, speculation, and arbitrage.',
            'Futures are available on major exchanges for various asset classes, including stocks, indices, interest rates, commodities, and cryptocurrencies. ',
            'Every crypto futures contract has a minimum price fluctuation, also commonly known as a tick',
            'The profit and loss of one contract is obtained by multiplying the dollar value of a one-tick move by the number of ticks the futures contract has moved since purchasing the contract. ',
            'The multiplying effect from the contract size and tick movement can have a large impact on the profit and loss of a futures contract.',
            'Every futures contract expires on a certain date, and a futures trader needs to manage contract expiration',
          ].map((i,index) => {
            return <li key={index} className='mt-3' style={{listStyleType: 'disc'}}>{i}</li>;
          })}
        </ul>
      </Box>
    </Box>
  )
}

export default FuturesTrading
