import React, { useState } from 'react';
import NavbarOne from '../../Pages/separate/NavbarOne';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Pages/separate/Footer';
import './contact.css';
import { Button, Spinner } from 'react-bootstrap';
import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";
import { Helmet } from 'react-helmet-async';

const Contact = (props) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [inputValues, setInputValues] = useState({
        name: "",
        subject: "",
        email: "",
        phone: "",
        message: ""
    });

    const [inputFocus, setInputFocus] = useState({
        name: false,
        subject: false,
        email: false,
        phone: false,
        message: false
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };

    const handleInputFocus = (event) => {
        const { name } = event.target;
        setInputFocus((prevInputFocus) => ({
            ...prevInputFocus,
            [name]: true,
        }));
    };

    const handleInputBlur = (event) => {
        const { name } = event.target;
        setInputFocus((prevInputFocus) => ({
            ...prevInputFocus,
            [name]: inputValues[name] !== "",
        }));
    };

    const onSubContact = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = {
            name: inputValues?.name,
            subject: inputValues?.subject,
            email: inputValues?.email,
            phone: inputValues?.phone,
            message: inputValues?.message

        }
        const params2 = {
            url: `${Config.V1_API_URL}contact/send-mail`,
            method: 'POST',
            body: payload
        };
        const response = await makeRequest(params2);
        if (response.status) {
            navigate('/thank_you')
            // let type = 'success';

            // toast({ type, message: "Support Team Will Get Back Shortly" });
        }
        setLoading(false);
    }

    return (
        <section className='bg-default text-white bg-particular'>
            <Helmet>
                <link rel="canonical" href="https://www.uniticexchange.com/help-center" />
            </Helmet>
            <div>
                <NavbarOne setTheme={props.setTheme} theme={props.theme} />
            </div>
            <div className='container pb-5 pt-5' style={{ minHeight: '90vh' }}>
                <div>
                    <h4><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Contact Us</strong></h4>
                    {/* <p className='pt-2 fs-5'>Two factor Authentication (2FA)</p> */}
                </div>
                <div className='box-shadow'>
                    <div className="form-contact mb-5 mt-4">
                        <div className="contact-info">
                            <h3 className="title">Contact - Information</h3>
                            <p className="text-contact pb-3">
                                Fill up the form, and our team will get back to you within 24
                                hours.
                            </p>

                            <div className="info">
                                <div className="d-flex justify-content-start align-items-center " style={{ gap: '10px' }}>
                                    <div className='info-icon  d-flex align-items-center justify-content-center'>
                                        <img src="/assets/Phone.png" alt="" className="img-fluid " width={20} />
                                    </div>
                                    <a href="tel:+918015956193" target="_blank" rel="noreferrer" className="contact-text">+91 8015956193</a>
                                </div>
                                <div className="d-flex justify-content-start align-items-center " style={{ gap: '10px' }}>
                                    <div className='info-icon  d-flex align-items-center justify-content-center'>
                                        <img src="/assets/Phone.png" alt="" className="img-fluid " width={20} />
                                    </div>
                                    <a href="tel:+918610138826" target="_blank" rel="noreferrer" className="contact-text">+91 8610138826</a>
                                </div>
                                <div className="d-flex justify-content-start align-items-center" style={{ gap: '10px' }}>
                                    <div className='info-icon  d-flex align-items-center justify-content-center'>
                                        <img src="/assets/mail.png" alt="" className="img-fluid " width={20} />
                                    </div>
                                    <a href="mailto:support@zecurechain.com" target="_blank" rel="noreferrer" className="contact-text" >supports@uniticexchange.com</a>
                                </div>
                            </div>
                        </div>

                        <div className="contact-form">
                            <form
                                onSubmit={onSubContact}
                                autoComplete="off"
                                className="form-contactV2"
                            >
                                <div
                                    className={`input-container ${inputFocus.name ? "focus" : ""
                                        }`}
                                >
                                    <div className="input-subcontainer">
                                        <input
                                            type="text"
                                            name="name"
                                            className="input-contact"
                                            value={inputValues.name}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            required
                                        />
                                        <label>Name</label>
                                        <span>Name</span>
                                    </div>
                                </div>

                                <div
                                    className={`input-container ${inputFocus.subject ? "focus" : ""
                                        }`}
                                >
                                    <div className="input-subcontainer">
                                        <input
                                            type="text"
                                            name="subject"
                                            className="input-contact"
                                            value={inputValues.subject}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            required
                                        />
                                        <label>Subject</label>
                                        <span>Subject</span>
                                    </div>
                                </div>

                                <div
                                    className={`input-container mt-1 ${inputFocus.email ? "focus" : ""}`}
                                >
                                    <input
                                        type="email"
                                        name="email"
                                        className="input-contact"
                                        value={inputValues.email}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        required
                                    />
                                    <label>Email</label>
                                    <span>Email</span>
                                </div>

                                <div
                                    className={`input-container mt-1 ${inputFocus.phone ? "focus" : ""}`}
                                >
                                    <input
                                        type="tel"
                                        name="phone"
                                        className="input-contact"
                                        value={inputValues.phone}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        required
                                    />
                                    <label>Phone</label>
                                    <span>Phone</span>
                                </div>

                                <div
                                    className={`input-container textarea mt-1 ${inputFocus.message ? "focus" : ""
                                        }`}
                                >
                                    <textarea
                                        name="message"
                                        className="input-contact"
                                        value={inputValues.message}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                        required
                                    ></textarea>
                                    <label>Message</label>
                                    <span>Message</span>
                                </div>
                                <Button
                                    variant="warning"
                                    className="p-20 rounded-pill btn-contact"
                                    style={{ fontSize: '16px' }}
                                    type="submit"
                                    disabled={loading}
                                >
                                    {" "}
                                    {loading ? <><Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                        <span className='ps-2'>Loading...</span></> : 'Submit'}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Footer />
            </div>
        </section>
    );
};

export default Contact;